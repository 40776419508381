import React, { FormEvent, useState } from "react";
import Swal from "sweetalert2";

import { Produto } from "../../../../typings";
import Input from "../../../../components/Input";
import { solicitaOrcamentoPdf } from "../../../../services/api";

interface ItemPedido {
  produto: Produto;
  valorUnitario: string;
  notaFiscal: string;
  dataEmissaoNotaFiscal: string;
  status: string;
  valorTotal: string;
  quantidade: string;
}

interface Pedido {
  numero: string;
  notaserie: string; //JL-HF_2022-06
  condicaoPagamento: string;
  descricaoCondicaoPagamento: string;
  dataEmissaoPedido: string;
  itens: ItemPedido[];
  transportadora: {
    nome: string;
    cnpj: string;
  };
  pedidob2b: {
    dataCriacao: string;
    usuario: {
      cnpj: string;
      nome: string;
    };
  };
}

const SolicitarEnvioEmail: React.FC<{ pedido: any }> = ({ pedido }) => {
  const qtdPrefixo = "000";
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(""); //josuel.lopes@hfbr.com.br');
  const emailRegex = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;
  /*/^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/ || /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/;*/
  // /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+\.([a-z]+)?$/i;

  async function handleFormSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      setLoading(true);
      const replaceEmails = email.replaceAll(";", ",");
      const emails = replaceEmails.split(",");

      let valid = true;
      let setEmail: string = "";

      emails.map((item: string) => {
        if (!emailRegex.test(item.trim())) {
          console.log(item);
          setEmail = item;
          valid = false;
        }
        return valid;
      });

      if (!valid) {
        Swal.fire({
          icon: "warning",
          title: "Alert",
          html: `<p>Email está em formato inválido, favor corrigir!</p><br/><p>
        <b>E-mail invalido:</b> ${setEmail}</p>`,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
          onClose: () => {},
        });
        return;
      }

      await solicitaOrcamentoPdf({
        email: emails.toString().trim(),
        orcamento: pedido.id.toString(),
      });

      setLoading(false);

      Swal.fire({
        icon: "success",
        title: "Sucesso",
        html: `<p><b>Orçamento:</b> ${pedido.id}<p><p>Solicitação foi enviada, em breve receberá email!</p>`,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Erro",
        html: `<p><b>Orçamento:</b> ${pedido.id}<p><p>Falha ao solicitar envio de email, tente mais tarde!</p>`,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });
    } finally {
    }
  }
  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(255, 255, 255, 0.75)",
            zIndex: 1000,
          }}
        >
          <div className="spinner-border text-info"></div>
          <div className="text-primary mt-4">Solicitando envio de e-mail.</div>
        </div>
      )}
      <hr />
      <table
        className="mb-4 text-left table table-borderless"
        style={{
          fontSize: "14px",
        }}
      >
        <tbody key={pedido.id}>
          <tr>
            <td>
              <b>Número Orçamento:</b>
            </td>
            <td>{pedido.id}</td>
          </tr>
          <tr>
            <td>
              <b>Data orçamento:</b>
            </td>
            <td>{pedido.dataCriacao}</td>
          </tr>
          <tr>
            <td
              style={{
                width: "25%",
              }}
            >
              <b>Qtd Itens:</b>
            </td>
            <td
              style={{
                width: "45%",
              }}
            >
              {String(qtdPrefixo.slice(String(pedido.quantidadeItens).length)) +
                pedido.quantidadeItens}
            </td>
          </tr>
          <tr>
            <td>
              <b>Valor orçamento:</b>
            </td>
            <td>
              {parseFloat(pedido.valorTotalComImpostos).toLocaleString(
                "pt-BR",
                { style: "currency", currency: "BRL" }
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <form
        onSubmit={handleFormSubmit}
        style={{
          border: "1px solid #eee",
          fontSize: ".9rem",
        }}
        className="p-4"
      >
        <div className="alert alert-info">
          <div className="row align-items-center">
            <div className="col-md">
              <strong>Para adicionar mais de um email, separar por ; </strong>
            </div>
          </div>
        </div>

        <Input
          label="E-mail"
          name="email"
          placeholder="seuemail@domini.com.br"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <button
          type="submit"
          className="btn btn-block btn-primary btn-lg mt-4"
          disabled={email.length === 0 ? true : false}
        >
          SOLICITAR ENVIO
        </button>
      </form>
    </>
  );
};

export default SolicitarEnvioEmail;
