import React, { useEffect, useState } from "react";
import { FaCertificate, FaMoneyBillAlt, FaShoppingCart } from "react-icons/fa";
import { FiLogOut, FiMenu, FiUser, FiUsers, FiX } from "react-icons/fi";
import { NavLink, Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//import GraphicMetaClient from '../../components/HeaderCliente/GraphicMeta';
import { useAppContext } from "../../contexts/app";
import version from "../../data/version";
import { IdTipoUsuario } from "../../entities/TipoUsuario";
import HeaderCliente from "../HeaderCliente";
import ModalSaida from "../ModalSaida";
import HeaderMenuDropdowDesktopHanburg from "../HeaderMenuDropdowDesktopHanburg";

import styles from "./Header.module.css";

const HeaderHanburg = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { user, logged, logout } = useAppContext();
  const [isCliente, setIsCliente] = useState(false);

  const location = useLocation();

  useEffect(() => {
    //JL-HF_2021-06-29 - ADD CONDICIONAL
    // setIsCliente(false);
    if (
      !location.pathname.startsWith("/clientes") &&
      !location.pathname.startsWith("/prospect") &&
      !location.pathname.startsWith("/prospect/cadastro") &&
      user?.revendedor !== undefined
    ) {
      setIsCliente(
        user?.tipo.nome === "CLIENTE" ||
          (!!user?.isRepresentante && user?.revendedor !== undefined)
      );
    }

    //setIsCliente(user?.revendedor !== undefined || user?.tipo.nome === 'CLIENTE');
    setShowMobileMenu(false);
  }, [location, user]);

  function handleLogout() {
    const ReactSwal = withReactContent(Swal);

    function handleStay() {
      ReactSwal.close();
    }

    function handleLeave() {
      ReactSwal.close();
      //setIsCliente(false);
      logout({ ask: false });
    }

    if (location.pathname.startsWith("/pedido/")) {
      ReactSwal.fire({
        title: "Você já está indo embora?",
        html: (
          <ModalSaida
            onStay={() => handleStay()}
            onLeave={() => handleLeave()}
          />
        ),
        width: "600px",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
      });
    } else {
      logout({ ask: true });
    }
  }

  return (
    <>
      <header
        className={[styles.header, "d-flex align-items-center"].join(" ")}
      >
        <div className="container-fluid d-flex align-items-center justify-content-between">
          <div
            className="col-sm-2 p-0 m-0"
            style={{ borderRight: "2px solid #eee" }}
          >
            <Link to="/" className="m-0 mr-4">
              <img
                src={require("../../assets/logo.png")}
                alt="ferrari"
                height="25px"
              />
            </Link>
            <div className="m-0 mt-1 p-0">
              <span
                className="text-version align-bottom"
                title="Versão atual da aplicação"
              >
                <FaCertificate
                  style={{
                    marginTop: "-.3em",
                    marginRight: ".1em",
                  }}
                  color="#204d74"
                />
                B2B - {version}
              </span>
            </div>
          </div>
          {logged && (
            <>
              <div
                className={[
                  "d-none d-md-flex p-0 m-0 align-items-center",
                  `${
                    user?.isRepresentante
                      ? "col-md-4 offset-md-8"
                      : "col-md-4 offset-md-8"
                  }`,
                ].join(" ")}
              >
                <div
                  className={[
                    "p-0 m-0 col row d-flex justify-content-start",
                    `${
                      user?.isRepresentante
                        ? "col-md-10 pr-4"
                        : "col-md-10 pr-4"
                    }`,
                  ].join(" ")}
                  style={{
                    borderRight: `${
                      user?.isRepresentante
                        ? "2px solid #eee"
                        : "2px solid #eee"
                    }`,
                  }}
                >
                  <span
                    className={[
                      `m-0 p-0  pl-2 col col-md-12 pl-2 text-left ${
                        user!.nome.length > 30 && "text-user-name-header"
                      }`,
                    ].join(" ")}
                    title={user?.nome}
                  >
                    {user?.nome.trimStart().trimEnd()}
                  </span>
                  <div className="col col-md-12 row p-0 m-0 d-flex justify-content-start">
                    <span className="col col-md-9 text-secondary pl-2">
                      {user?.tipo.id === IdTipoUsuario.SUPERVISOR ? (
                        <small>PERFIL: GESTOR</small>
                      ) : (
                        <small>PERFIL: {user?.tipo?.nome.trim()}</small>
                      )}
                    </span>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-danger col col-md-3 align-items-center"
                      onClick={() => handleLogout()}
                    >
                      <FiLogOut className="mr-2" />
                      SAIR
                    </button>
                  </div>
                </div>

                <div className="m-0 p-0 d-none d-md-flex col-sm-1 align-items-center d-flex flex-row-reverse ">
                  {user?.tipo.id !== IdTipoUsuario.SUPERVISOR && //JL-HF_2022-06
                    user?.isRepresentante && (
                      <HeaderMenuDropdowDesktopHanburg
                        setIsCliente={setIsCliente}
                      />
                    )}

                  {user?.tipo.id !== IdTipoUsuario.SUPERVISOR && //JL-HF_2022-06
                    !user?.isRepresentante &&
                    isCliente && (
                      //  <NavLink
                      //   to="/minhaconta"
                      //   className="btn btn-outline-primary d-flex align-items-center mr-2"
                      // >
                      //   <FiUser className="mr-2" />
                      //   Minha Conta
                      // </NavLink>
                      <HeaderMenuDropdowDesktopHanburg
                        setIsCliente={setIsCliente}
                      />
                    )}
                </div>
              </div>

              <div className="d-flex d-md-none ml-2">
                {showMobileMenu ? (
                  <FiX size={24} onClick={() => setShowMobileMenu(false)} />
                ) : (
                  <FiMenu size={24} onClick={() => setShowMobileMenu(true)} />
                )}

                {showMobileMenu && (
                  <div
                    className="position-absolute"
                    style={{
                      top: 65,
                      left: 0,
                      right: 0,
                      padding: 20,
                      zIndex: 2000,
                      background: "#ffffff",
                      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <div className="mb-4">
                      {user?.nome}
                      <br />
                      <span className="pt-2 text-secondary">
                        <small>PERFIL: {user?.tipo?.nome}</small>
                      </span>
                    </div>

                    {user?.isRepresentante ? (
                      <>
                        <NavLink
                          to="/minhaconta/pedidos"
                          className="btn btn-block btn-outline-primary d-flex align-items-center mb-4"
                        >
                          <FaShoppingCart className="mr-2" />
                          Pedidos
                        </NavLink>
                        <NavLink
                          to="/minhaconta/faturas"
                          className="btn btn-block btn-outline-primary d-flex align-items-center mb-4"
                        >
                          <FaMoneyBillAlt className="mr-2" />
                          Faturamento
                        </NavLink>
                        <NavLink
                          to="/clientes"
                          className="btn btn-block btn-outline-primary d-flex align-items-center mb-4"
                        >
                          <FiUsers className="mr-2" />
                          Ver Meus Clientes
                        </NavLink>
                      </>
                    ) : (
                      <NavLink
                        to="/minhaconta"
                        className="btn btn-block btn-outline-primary d-flex align-items-center mb-4"
                      >
                        <FiUser className="mr-2" />
                        Minha Conta
                      </NavLink>
                    )}

                    <button
                      type="button"
                      className="btn btn-block btn-outline-danger d-flex align-items-center"
                      onClick={() => handleLogout()}
                    >
                      <FiLogOut className="mr-2" />
                      SAIR
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </header>
      {logged && (
        <div
          style={{
            background: "transparent",
            marginTop: "-2vh",
            zIndex: 1000,
          }}
          className="container-fluid text-right"
        ></div>
      )}
      {(logged && isCliente && <HeaderCliente />) ||
        (user?.isRepresentante && isCliente && <HeaderCliente />)}
      {/*logged && userCliente && <GraphicMetaClient />*/}
      {/* {isCliente && <GraphicMetaClient />} */}
    </>
  );
};

export default HeaderHanburg;
