import React from 'react';

import { useAppContext } from '../../contexts/app';

import Orcamentos from './Orcamentos';

const Supervisor = () => {
  const { user } = useAppContext();

  return (
    <div className="container-fluid my-4">
      <div className="d-flex flex-fill flex-column">
        <div className="d-block d-md-flex align-items-center justify-content-between p-4 border border-primary mb-4">
          <h5 className="mb-0 text-primary mb-4 mb-md-0">
            Bem vindo, {user?.nome}!
            {/*<br />
            <span className="pt-2 text-secondary">
              <small>PERFIL: {user?.tipo?.nome}</small> 
              <small>PERFIL: GESTOR</small>
            </span>*/}
          </h5>
          {/* <p className="mb-0 text-primary mb-4 mb-md-0">{user?.tipo.nome}</p> */}
        </div>

        <Orcamentos />
      </div>
    </div>
  );
};

export default Supervisor;
