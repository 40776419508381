import React from 'react';

import Input from '../../../components/Input';
import SideBar from '../SideBar';
import { useAppContext } from '../../../contexts/app';

const Enderecos = () => {
  const { user } = useAppContext();

  return (
    <div className="container-fluid my-4">
      <div className="row">
        <div className="col-md-3">
          <SideBar />
        </div>
        <div className="col-md-9 mt-4 mt-md-0">
          <h3>Meus Endereços</h3>

          <hr />

          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <strong className="text-secondary">Endereço de Entrega</strong>
                </div>
                <div className="card-body">
                  <Input disabled label="CEP" name="enderecoEntregaCep" value={user?.revendedor?.enderecoEntregaCep} />
                  <Input
                    disabled
                    label="Logradouro"
                    name="enderecoEntregaLogradouro"
                    value={user?.revendedor?.enderecoEntregaLogradouro}
                  />
                  {/* <Input disabled label="Número" name="enderecoEntregaNumero" value={''} /> JL-HF_2022-06_Retirado solicitado por Douglas Chagas*/}
                  <Input disabled label="Complemento" name="enderecoEntregaComplemento" value={''} />
                  <Input disabled label="Bairro" name="enderecoEntregaBairro" value={''} />
                  <Input
                    disabled
                    label="Cidade"
                    name="enderecoEntregaMunicipio"
                    value={user?.revendedor?.enderecoEntregaMunicipio}
                  />
                  <Input disabled label="UF" name="enderecoEntregaUf" value={user?.revendedor?.enderecoEntregaUf} />
                  {/* JL-HF 2023-06 v2.0.1| Agrupando e-mails em MEU CADASTRO*/}
                  {/* <Input
                    disabled
                    label="E-mail"
                    name="enderecoEntregaEmail"
                    value={user?.revendedor?.email_vendas || user?.revendedor?.email}
                  /> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-4 mt-md-0">
              <div className="card">
                <div className="card-header">
                  <strong className="text-secondary">Endereço de Cobrança</strong>
                </div>
                <div className="card-body">
                  <Input
                    disabled
                    label="CEP"
                    name="enderecoCobrancaCep"
                    value={user?.revendedor?.enderecoCobrancaCep}
                  />
                  <Input
                    disabled
                    label="Logradouro"
                    name="enderecoCobrancaLogradouro"
                    value={user?.revendedor?.enderecoCobrancaLogradouro}
                  />
                  {/* <Input disabled label="Número" name="enderecoCobrancaNumero" value={''} /> JL-HF_2022-06_Retirado solicitado por Douglas Chagas**/}
                  <Input disabled label="Complemento" name="enderecoCobrancaComplemento" value={''} />
                  <Input disabled label="Bairro" name="enderecoCobrancaBairro" value={''} />
                  <Input
                    disabled
                    label="Cidade"
                    name="enderecoCobrancaMunicipio"
                    value={user?.revendedor?.enderecoCobrancaMunicipio}
                  />
                  <Input disabled label="UF" name="enderecoCobrancaUf" value={user?.revendedor?.enderecoCobrancaUf} />
                  {/* JL-HF 2023-06 v2.0.1| Agrupando e-mails em MEU CADASTRO*/}
                  {/* <Input
                    disabled
                    label="E-mail"
                    name="enderecoCobrancaEmail"
                    value={user?.revendedor?.email_cobranca || user?.revendedor?.email}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enderecos;
