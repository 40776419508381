import React from "react";

import { Produto } from "../../../../typings";

interface ItemPedido {
  produto: Produto;
  valorUnitario: string;
  notaFiscal: string;
  dataEmissaoNotaFiscal: string;
  status: string;
  valorTotal: string;
  quantidade: string;
}

interface Pedido {
  numero: string;
  notaserie: string; //JL-HF_2022-06
  condicaoPagamento: string;
  descricaoCondicaoPagamento: string;
  dataEmissaoPedido: string;
  itens: ItemPedido[];
  transportadora: {
    nome: string;
    cnpj: string;
  };
  pedidob2b: {
    dataCriacao: string;
    usuario: {
      cnpj: string;
      nome: string;
    };
  };
}

const ExcluirOrcamento: React.FC<{ orcamento: any }> = ({ orcamento }) => {
  const qtdPrefixo = "000";

  return (
    <>
      <hr />
      <table
        className="mb-4 text-left table table-borderless"
        style={{
          fontSize: "14px",
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                width: "25%",
              }}
            >
              <b>Número orcamento:</b>
            </td>
            <td
              style={{
                width: "45%",
              }}
            >
              {orcamento.id}
            </td>
          </tr>
          <tr>
            <td>
              <b>Data orçamento:</b>
            </td>
            <td>{orcamento.dataCriacao}</td>
          </tr>
          <tr>
            <td>
              <b>Qtd. Itens:</b>
            </td>
            <td>
              {String(
                qtdPrefixo.slice(String(orcamento.quantidadeItens).length)
              ) + orcamento.quantidadeItens}
            </td>
          </tr>
          <tr>
            <td>
              <b>Valor orçamento:</b>
            </td>
            <td>
              {parseFloat(orcamento.valorTotalComImpostos).toLocaleString(
                "pt-BR",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )}
            </td>
          </tr>
          {/* <tr>
            <td>
              <b>Total desconto:</b>
            </td>
            <td>{'RS 200,00'}</td>
            <td
              style={{
                width: '20%',
              }}>
              Desconto %
            </td>
            <td>{'5.00'}</td>
          </tr> */}
        </tbody>
      </table>
      <div className="alert alert-danger">
        <div className="row align-items-center">
          <div className="col-md">
            <strong>Deseja confirma e excluir orçamento? </strong>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExcluirOrcamento;
