import React, { FormEvent, useState } from 'react';

import { ItemPedido } from '../../typings';
import Input from '../Input';

interface ItemPedidoDescontoProps {
  itemPedido: ItemPedido;
  onConfirm({ percentualDesconto: desconto }: { percentualDesconto: number }): void;
  onCancel(): void;
}

const ItemPedidoDesconto: React.FC<ItemPedidoDescontoProps> = ({
  itemPedido,
  onConfirm = () => {},
  onCancel = () => {},
}) => {
  const [percentualDesconto, setPercentualDesconto] = useState(String(itemPedido.percentualDesconto) || '');
  const [errors, setErrors] = useState({
    percentualDesconto: '',
  });

  function validate() {
    let isValid = true;

    setErrors(() => ({
      percentualDesconto: '',
    }));

    if (percentualDesconto === '') {
      isValid = false;

      setErrors((values) => ({ ...values, percentualDesconto: 'Preenchimento obrigatório' }));
    }

    return isValid;
  }

  function handlePercentualDescontoChange(value: string) {
    if (Number(value) <= 100) {
      setPercentualDesconto(value);
    }
  }

  function handleSubmit(e: FormEvent) {
    e.preventDefault();

    if (validate() === false) {
      return false;
    }

    onConfirm({ percentualDesconto: Number(percentualDesconto) });
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)} onReset={() => onCancel()} style={{ position: 'relative' }}>
      <strong>Alteração de preço do item:</strong>

      <h5 className="my-4">{itemPedido.produto.titulo}</h5>

      <Input
        type="number"
        label="% de desconto"
        name="desconto"
        value={percentualDesconto}
        onChange={(e) => handlePercentualDescontoChange(e.target.value)}
        max={90}
        step={0.01}
        autoFocus={true}
        formNoValidate={true}
        errorMessage={errors.percentualDesconto}
        onBlur={() => validate()}
      />

      <div className="row">
        <div className="col">
          <button
            type="reset"
            className="btn btn-block btn-lg btn-outline-secondary d-flex align-items-center justify-content-center mt-3">
            Cancelar
          </button>
        </div>
        <div className="col">
          <button
            type="submit"
            className="btn btn-block btn-lg btn-primary d-flex align-items-center justify-content-center mt-3">
            Confirmar
          </button>
        </div>
      </div>
    </form>
  );
};

export default ItemPedidoDesconto;
