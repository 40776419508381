// JL-HF_2022-06
export enum IdTipoUsuario {
  CLIENTE = 1,
  REPRESENTANTE = 2,
  AGENTE = 3,
  SUPERVISOR = 4, //GESTOR
}

export const TipoUsuario: any = {
  cliente: {
    id: IdTipoUsuario.CLIENTE,
    nome: IdTipoUsuario[IdTipoUsuario.CLIENTE],
    perfil: "C",
  },
  representante: {
    id: IdTipoUsuario.REPRESENTANTE,
    nome: IdTipoUsuario[IdTipoUsuario.REPRESENTANTE],
    perfil: "R",
  },
  agente: {
    id: IdTipoUsuario.AGENTE,
    nome: IdTipoUsuario[IdTipoUsuario.AGENTE],
    perfil: "A",
  },
  supervisor: {
    id: IdTipoUsuario.SUPERVISOR,
    nome: "GESTOR",
    perfil: "S",
  },
};

export const PROSPECT_BLOQUEADO = 1; //"1" é ID bloqueado no protheus

export const TIPOPESSOA: any = {
  J: "Jurídica",
  F: "Física",
};
