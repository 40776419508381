import React, { useState, useEffect, useRef, MouseEvent } from "react";
import { FiPlusCircle, FiSend, FiTrash, FiEdit } from "react-icons/fi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { NavLink, Prompt, useHistory, useLocation } from "react-router-dom";
import { FaAngleDown, FaAngleUp, FaHome } from "react-icons/fa";

import { ItemPedido, Produto, ProdutosAtualizarMpp } from "../../typings";
import ProdutoSearch from "../../components/ProdutoSearch";
import {
  fetchMPP,
  updateItems,
  fetchPromocoes,
  fetchCondicoesPagamento,
  fetchGruposProdutosPorCNPJ,
  fetchHistoricoCompraProduto,
  updateProdutoSugeridosAtualizaMPPCodigoCliente,
} from "../../services/api";
import { useAppContext } from "../../contexts/app";
import ProdutoHistory from "../../components/ProdutoHistory";
import ProdutoDetail from "../../components/ProdutoDetail";
import ItemPedidoDetail from "../../components/ItemPedidoDetail";
import ItemPedidoDesconto from "../../components/ItemPedidoDesconto";
import { IdTipoUsuario } from "../../entities/TipoUsuario";

import RowPedidoMPP from "./RowPedidoMPP";
import { format } from "date-fns";

interface ItemsPlanilha {
  ultimaCondicaoPagamento: string;
  produtos: ItemPedido[];
}

interface ItemsCarregaQtd {
  carregaQtd: boolean;
}
interface IsOrcamentoProps {
  isOrcamento: boolean;
}

interface GrupoProdutos {
  produtos: ItemPedido[];
  codigogrupo: string;
  descrigrupo: string;
}

//JL-HF_2022-11
interface GrupoProdutosPorCNPJ {
  codigo: string;
  descri_grupo: string;
  grupo: string;
  loja: string;
  nome: string;
}

const PedidoMPP: React.FC<{ somenteOfertas?: boolean }> = ({
  somenteOfertas = false,
}) => {
  const [condicoesPagamento, setCondicoesPagamento] = useState<
    { codigo: string; descricao: string }[]
  >([]);
  const [condicaoPagamento, setCondicaoPagamento] = useState("");
  const [items, setItems] = useState<ItemPedido[]>([]);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const { user, showLoader, hideLoader } = useAppContext(); //setLoadSaldoMeta
  const confirmLeave = useRef(false);
  const [grupoProdutos, setGrupoProdutos] = useState<GrupoProdutos[]>([]);

  const history = useHistory();

  const { state } = useLocation<{
    itemsPlanilha?: ItemsPlanilha;
    carregaQtd: ItemsCarregaQtd;
    orcamentoPedido?: any;
    isOrcamento?: IsOrcamentoProps;
  }>();
  const [carregarQtd] = useState<boolean>(state?.carregaQtd ? true : false); //JL_2021-09-23
  let group = "0";

  const [isExpand, setIsExpand] = useState(
    (state?.isOrcamento && state?.orcamentoPedido?.produtos[0].codigogrupo) ||
      (state?.itemsPlanilha && state?.itemsPlanilha.produtos[0].codigogrupo) ||
      "0"
  );
  const [isOpen, setIsOpen] = useState(
    state?.isOrcamento || state?.itemsPlanilha ? true : false
  );
  const [isDisabledConfirmaPedido, setIsDisabledConfirmaPedido] = useState(
    false
  );
  const [isPercentualDesconto, setIsPercentualDesconto] = useState(false);

  useEffect(() => {
    async function fetchInitialData() {
      try {
        const loader = showLoader(
          "Por favor aguarde. Esta tarefa pode demorar vários segundos. Carregando sugestão de pedido."
        );

        const promCondicoesPagamento = fetchCondicoesPagamento();

        let promItems: any =
          somenteOfertas && !state
            ? fetchPromocoes({
                calcularImpostos: "false",
                calcularEntregas: "true",
                calcularQuantidadeMes: "true",
              })
            : fetchMPP({ calcularImpostos: "false", calcularEntregas: "true" });

        if (state?.itemsPlanilha && !state.isOrcamento) {
          if (
            state?.itemsPlanilha.ultimaCondicaoPagamento &&
            state?.itemsPlanilha.ultimaCondicaoPagamento !== condicaoPagamento
          ) {
            setCondicaoPagamento(state?.itemsPlanilha.ultimaCondicaoPagamento);
          }

          const merged = transformItems(state.itemsPlanilha.produtos);

          /*console.log('>>PLANILHA');
          console.log(merged);*/
          setItems(merged);

          promItems = null;
        }

        if (state?.orcamentoPedido && state?.isOrcamento) {
          if (
            state?.orcamentoPedido?.ultimaCondicaoPagamento &&
            state?.orcamentoPedido.ultimaCondicaoPagamento !== condicaoPagamento
          ) {
            setCondicaoPagamento(
              state?.orcamentoPedido.ultimaCondicaoPagamento
            );
          }

          const merged = await transformItems(state?.orcamentoPedido.produtos);

          setItems(merged);
          /*console.log('>>ORCAMENTO');
          console.log(merged);*/

          setIsDisabledConfirmaPedido(
            isPercentualDesconto &&
              state?.isOrcamento &&
              state.orcamentoPedido?.status !== 4
          ); //STATUS = 4 (APROVADO)

          promItems = null;
        }

        const [resCondicoesPagamento, resItems] = await Promise.all([
          promCondicoesPagamento,
          promItems,
        ]);

        if (resCondicoesPagamento && resCondicoesPagamento.data) {
          setCondicoesPagamento(resCondicoesPagamento.data);

          if (condicaoPagamento === "") {
            setCondicaoPagamento(resCondicoesPagamento.data[0].codigo);
          }
        }

        if (resItems && resItems.data) {
          if (
            resItems.data.ultimaCondicaoPagamento &&
            resItems.data.ultimaCondicaoPagamento !== condicaoPagamento
          ) {
            setCondicaoPagamento(resItems.data.ultimaCondicaoPagamento);
          }

          const merged = transformItems(resItems.data.produtos);

          /*console.log('>>RESITENS');
          console.log(merged);*/

          setItems(merged);
        }

        hideLoader(loader);
      } catch (error) {
        Swal.fire({
          icon: "warning",
          title: "Erro",
          text:
            "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });

        console.log(error);
      }
    }

    if (items.length === 0 && condicoesPagamento.length === 0) {
      fetchInitialData();
    }
  }, [
    condicaoPagamento,
    condicoesPagamento.length,
    grupoProdutos,
    hideLoader,
    isDisabledConfirmaPedido,
    isOpen,
    isPercentualDesconto,
    items,
    items.length,
    showLoader,
    somenteOfertas,
    state,
    transformItems,
    user,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function transformItems(itens: ItemPedido[]) {
    let quantidadeComprar = 0;

    const merged = itens.map((item) => {
      const percentualDesconto = item.percentualDesconto || 0;
      const desconto = (item.precoPor / 100) * percentualDesconto;

      quantidadeComprar =
        carregarQtd && item.quantidadeComprar > 0 ? item.quantidadeComprar : 0;

      if (item._keepQuantidadeComprar) {
        quantidadeComprar = item.quantidadeComprar;
      }

      let precoItemComDesconto = item.precoPor - desconto;
      let precoTotalSemImpostos = precoItemComDesconto * quantidadeComprar;
      let precoTotalComImpostos = precoItemComDesconto * quantidadeComprar;

      if (item.impostoCalculado && quantidadeComprar > 0) {
        precoTotalComImpostos =
          precoItemComDesconto * quantidadeComprar + item.ipi + item.st;
      }

      //JL-HF_2022-10
      if (!isPercentualDesconto && percentualDesconto > 0) {
        setIsPercentualDesconto(true);
      }

      return {
        ...item,
        quantidadeComprar,
        precoPorFormatado: precoItemComDesconto.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
        precoTotalSemImpostos,
        precoTotalComImpostos,
      };
    });

    const mergedOrderCod = merged.sort((a, b) => {
      return a["codigogrupo"] > b["codigogrupo"] ? 1 : -1;
    });

    gerarGroupProdutos(mergedOrderCod);

    return mergedOrderCod;
  }

  async function gerarGroupProdutos(getProdutos: any) {
    let isInTheGroup = false;
    const formatGroup: GrupoProdutos[] = [] as GrupoProdutos[];

    getProdutos.map((item: GrupoProdutos) => {
      isInTheGroup =
        formatGroup.filter((i) => i.codigogrupo === item.codigogrupo).length >
        0;

      if (!isInTheGroup) {
        formatGroup.push({
          produtos: getProdutos.filter(
            (i: ItemPedido) => i.codigogrupo === item.codigogrupo
          ),
          codigogrupo: item.codigogrupo,
          descrigrupo: item.descrigrupo,
        });
      }
      return null;
    });

    if (!state?.itemsPlanilha && !state?.isOrcamento) {
      const gruposProdutosPorCNPJ: GrupoProdutosPorCNPJ[] = await fetchGruposProdutosPorCNPJ(
        {
          cnpj: user!.revendedor.cnpj,
        }
      );

      gruposProdutosPorCNPJ.map((g: any) => {
        isInTheGroup =
          formatGroup.filter((i) => i.codigogrupo === g.grupo).length > 0;
        if (!isInTheGroup) {
          formatGroup.push({
            produtos: [] as ItemPedido[],
            codigogrupo: g.grupo,
            descrigrupo: g.descri_grupo,
          });
        }
        return null;
      });
    }

    const formatGroupSort = formatGroup.sort((a, b) => {
      return a["codigogrupo"] > b["codigogrupo"] ? 1 : -1;
    });
    setGrupoProdutos(formatGroupSort);
  }

  function resetImpostoCalculado(itens: ItemPedido[]) {
    return itens.map((item) => {
      return {
        ...item,
        impostoCalculado: false,
      };
    });
  }

  function handleShowDetalheProduto(itemPedido: ItemPedido) {
    const ReactSwal = withReactContent(Swal);

    ReactSwal.fire({
      title: "Detalhe do produto",
      html: <ProdutoDetail produto={itemPedido.produto} />,
      width: "800px",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  async function handleShowHistoricoItem(itemPedido: ItemPedido) {
    try {
      const loader = showLoader("Carregando histórico de compra do produto.");
      const response = await fetchHistoricoCompraProduto(
        itemPedido.produto.codigo
      );

      hideLoader(loader);

      if (response.data) {
        const ReactSwal = withReactContent(Swal);

        ReactSwal.fire({
          title: "Histórico de compra do produto",
          html: (
            <ProdutoHistory
              entries={response.data}
              quantidadeSugerida={itemPedido.quantidadeSugerida}
            />
          ),
          width: "800px",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Erro",
        text:
          "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });

      console.log(error);
    }
  }

  function handleShowDetalheItem(itemPedido: ItemPedido) {
    const ReactSwal = withReactContent(Swal);

    itemPedido.condicaoPagamento = `${condicaoPagamento} - ${
      condicoesPagamento.find((cp) => cp.codigo === condicaoPagamento)
        ?.descricao
    }`;

    ReactSwal.fire({
      title: "Detalhes do item do pedido",
      html: <ItemPedidoDetail itemPedido={itemPedido} />,
      width: "800px",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  async function handleAddDescontoItem(itemPedido: ItemPedido) {
    const ReactSwal = withReactContent(Swal);

    function handleConfirm({
      percentualDesconto,
    }: {
      percentualDesconto: number;
    }) {
      itemPedido = Object.assign({}, itemPedido, { percentualDesconto });

      const _items = resetImpostoCalculado(items);
      const index = items.findIndex(
        (item) => item.produto.codigo === itemPedido.produto.codigo
      );

      _items[index] = itemPedido;

      const merged = transformItems(_items);

      setItems(merged);

      const checkDesconto =
        percentualDesconto > 0 &&
        user?.tipo.id === IdTipoUsuario.CLIENTE &&
        state?.orcamentoPedido.status !== 4;

      setIsDisabledConfirmaPedido(checkDesconto);

      ReactSwal.close();
    }

    ReactSwal.fire({
      title: "Atenção",
      html: (
        <ItemPedidoDesconto
          itemPedido={itemPedido}
          onConfirm={({ percentualDesconto }) =>
            handleConfirm({ percentualDesconto })
          }
          onCancel={() => ReactSwal.close()}
        />
      ),
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  async function removeCheckedItems() {
    const response = await Swal.fire({
      title: "Atenção!",
      text: `Deseja remover ${
        checkedItems.length > 1 ? "estes itens" : "este item"
      } do pedido?`,
      icon: "warning",
      showCancelButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    });

    if (response.isConfirmed) {
      const filtered = items.filter(
        (v) => !checkedItems.includes(v.produto.codigo)
      );

      let merged = resetImpostoCalculado(filtered);

      merged = transformItems(merged);

      setItems(merged);
      setCheckedItems([]);
    }
  }

  async function handleRemoveRow(item: ItemPedido) {
    const response = await Swal.fire({
      title: "Atenção!",
      text: "Deseja remover este item do pedido?",
      icon: "warning",
      showCancelButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    });

    if (response.isConfirmed) {
      const filtered = items.filter(
        (v) => v.produto.codigo !== item.produto.codigo
      );
      let merged = resetImpostoCalculado(filtered);

      merged = transformItems(merged);

      setItems(merged);
    }
  }

  function handleShowBuscaProduto(getProduto?: string, getGrupo?: string) {
    const ReactSwal = withReactContent(Swal);

    async function handleSelect(produtos: Produto[]) {
      ReactSwal.close();

      let exists;
      let produtoExists: any;

      try {
        produtos.map((produto: Produto) => {
          exists = items.find((item) => item.produto.codigo === produto.codigo);
          if (exists) {
            produtoExists = produto;
          }
          return false;
        });

        if (produtoExists) {
          Swal.fire({
            icon: "warning",
            title: "Produto duplicado",
            text: `O produto "${produtoExists?.titulo}" selecionado já está na lista de pedidos!`,
            allowEscapeKey: false,
            allowOutsideClick: false,
            confirmButtonText: "OK",
          });

          return;
        }

        let contProdutos = items.length + produtos.length;
        let produtoItens = items;

        produtos.map(async (produto: Produto) => {
          const loader = showLoader("Adicionando item ao pedido.");
          if (produto.codigo) {
            const response = await updateItems({
              condicaoPagamento,
              itens: [
                {
                  codigo: produto.codigo,
                  quantidade: 1,
                  percentualDesconto: 0,
                },
              ],
              calcularImpostos: "false",
              calcularEntregas: "true",
              calcularQuantidadeMes: "true",
            });

            if (response.data) {
              const itemPedido = response.data[0];
              const precoTotal = itemPedido.precoPor * 1;
              const precoTotalFormatado = precoTotal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });

              itemPedido.precoTotal = precoTotal;
              itemPedido._keepQuantidadeComprar = true;
              itemPedido.precoTotalFormatado = precoTotalFormatado;

              let merged = resetImpostoCalculado([
                ...produtoItens,
                { ...itemPedido },
              ]);
              merged = transformItems(merged);
              produtoItens = merged;

              if (contProdutos === produtoItens.length) {
                setItems(merged);
              }
            }
          }
          hideLoader(loader);
        });
      } catch (error) {
        Swal.fire({
          icon: "warning",
          title: "Erro",
          text:
            "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });

        console.log(error);
      }
    }

    ReactSwal.fire({
      title: "Busca de produtos",
      html: (
        <ProdutoSearch
          onSelect={(produto) => handleSelect(produto)}
          searchText={getProduto || ""}
          grupo={getGrupo || ""}
        />
      ),
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
      width: "960px",
    });
  }

  function showModalConfirmacao() {
    const ReactSwal = withReactContent(Swal);

    ReactSwal.fire({
      title: "Atualização MPP",
      icon: "success",
      html: (
        <>
          <p>Realizado com sucesso...</p>
          <p>Aguarde alguns instantes que lista de MPP será atualizada.</p>
        </>
      ),
      width: "600px",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: true,
      confirmButtonText: "OK",
      preConfirm: () => {
        ReactSwal.close();

        confirmLeave.current = true;

        history.push("/");
      },
    });

    return false;
  }

  async function handleConfirmarPedido() {
    try {
      const loader = showLoader(
        "Por favor aguarde. Esta tarefa pode demorar vários segundos. Preparando confirmação do pedido."
      );

      const itens = items.map((item) => ({
        codigoProduto: item.produto.codigo,
      }));

      await updateProdutoSugeridosAtualizaMPPCodigoCliente({
        codigoCliente: user?.revendedor.codigo,
        lojaCliente: user?.revendedor.loja,
        codigoRepresentante: user?.codigo,
        dataAtualizacao: format(new Date(), "yyyyMMdd"),
        itens,
      } as ProdutosAtualizarMpp);

      hideLoader(loader);

      showModalConfirmacao();
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Erro",
        text:
          "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });

      console.log(error);
    }
  }

  function showModalSaida(pathname: string) {
    const ReactSwal = withReactContent(Swal);

    if (confirmLeave.current) {
      return true;
    }

    function handleStay() {
      ReactSwal.close();
    }

    function handleLeave() {
      ReactSwal.close();

      confirmLeave.current = true;

      history.push({ pathname });
    }

    ReactSwal.fire({
      title: "Sair e retornar ao início",
      icon: "question",
      html: <p>Deseja sair e retornar ao início?</p>,
      width: "600px",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCloseButton: true,
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "OK",
      cancelButtonColor: `red`,
      cancelButtonText: "CANCEL",
      preConfirm: () => {
        handleLeave();
      },
      onClose: () => {
        handleStay();
      },
    });

    //handleLeave() : handleStay()

    return false;
  }

  /**Display para expandir lista de produtos */
  function handleIsExpandIcon(e: MouseEvent<HTMLButtonElement>, grup: string) {
    //e.preventDefault();
    setIsExpand(grup);

    if (isExpand === grup) {
      setIsOpen(!isOpen);

      return;
    }
    if (!isOpen && isExpand !== grup) {
      setIsOpen(!isOpen);
    }
    //onExpands(codGrupo);
  }

  /* JL-HF_09-03-2023 Ordenar Itens produtos do grupo (Solicitante: Douglas Chagas / Otiniel)*/
  function sortItensProdutos(itens: ItemPedido[]) {
    const ordenar = itens.sort((a, b) => {
      return a.produto["titulo"] > b.produto["titulo"] ? 1 : -1;
    });

    return ordenar;
  }

  return (
    <div className="container-fluid my-4">
      <Prompt message={({ pathname }) => showModalSaida(pathname)} />

      <div className="mb-4">
        <div className="d-block d-md-flex align-items-center justify-content-between">
          <div className="flex-fill">
            <h4 className="text-center text-center text-secondary">
              <FiEdit size={24} className="mr-2" />
              EDITAR LISTA DE SUGESTÕES MPP
            </h4>
          </div>
        </div>
      </div>

      {checkedItems.length > 0 && (
        <button
          type="button"
          className="btn btn-danger d-inline-flex align-items-center mb-3"
          onClick={() => removeCheckedItems()}
        >
          <FiTrash style={{ marginRight: "5px" }} />
          Remover items selecionados
        </button>
      )}

      <div className="d-flex flex-column table-responsive">
        {items.length > 0 ? (
          <>
            {grupoProdutos.map((item) => (
              <div key={item.codigogrupo} className="">
                <div className="d-flex flex-fill bg-light border border-primary b justify-content-between align-items-center border-right border-primary p-2">
                  <p className="col-md-11 d-flex flex-column mt-2 mb-0">
                    <strong>
                      GRUPO: {item.codigogrupo} -{" "}
                      {item.descrigrupo.toUpperCase()}
                    </strong>
                  </p>
                  {item.produtos.length > 0 ? (
                    <button
                      type="button"
                      style={{ minWidth: 30, maxWidth: 30 }}
                      className="toggle btn btn-sm btn-link d-flex flex-fill align-items-center justify-content-center p-0 m-0 mt-1 mr-2"
                      data-tip={`${
                        isOpen
                          ? "Ocutar grupo produtos"
                          : "Expandir grupo produtos"
                      }`}
                      onClick={(e) => handleIsExpandIcon(e, item.codigogrupo)}
                    >
                      {isOpen && isExpand === item.codigogrupo ? (
                        <FaAngleUp size={24} color={"#dc3545"} />
                      ) : (
                        <FaAngleDown size={24} color={"#28a745"} />
                      )}
                    </button>
                  ) : (
                    <button
                      type="button"
                      style={{ minWidth: 30, maxWidth: 30 }}
                      className="toggle btn btn-sm btn-link d-flex flex-fill align-items-center justify-content-center p-0 m-0 mt-1 mr-2"
                      data-tip={"Adicionar produtos a esse grupo"}
                      onClick={() =>
                        handleShowBuscaProduto(
                          item.descrigrupo,
                          item.codigogrupo
                        )
                      }
                    >
                      <FiPlusCircle size={24} className="btn-outline-primary" />
                    </button>
                  )}
                </div>

                <div
                  style={{
                    display: `${
                      isOpen && isExpand === item.codigogrupo ? "block" : "none"
                      // (isOpen && isExpand === item.codigogrupo) || group !== item.codigogrupo ? 'block' : 'none'
                    }`,
                    transitionTimingFunction: "ease-in",
                    transitionProperty: "all",
                    transitionDuration: `${isOpen ?? "0.5s"}`,
                    transitionDelay: `${isOpen ?? "0.5s ease-in"}`,
                  }}
                >
                  {/* {items.map((item, index) => ( */}
                  {sortItensProdutos(item.produtos).map((item, index) => (
                    <div key={index} className="p-0 m-0">
                      <RowPedidoMPP
                        key={`${item.produto.codigo}-${item.quantidadeComprar}`}
                        itemPedido={item}
                        onCheck={() =>
                          setCheckedItems([
                            ...checkedItems,
                            item.produto.codigo,
                          ])
                        }
                        onUncheck={() =>
                          setCheckedItems(
                            checkedItems.filter(
                              (ci) => ci !== item.produto.codigo
                            )
                          )
                        }
                        onShowDetalheProduto={() =>
                          handleShowDetalheProduto(item)
                        }
                        onShowHistoricoItem={() =>
                          handleShowHistoricoItem(item)
                        }
                        onShowDetalheItem={() => handleShowDetalheItem(item)}
                        /*onChangeQuantidade={(novaQuantidade) =>
                          handleItemChangeQuantidade(item, novaQuantidade)
                        }*/
                        onChangeQuantidade={(novaQuantidade) => () => {}}
                        onChangeDesconto={() => handleAddDescontoItem(item)}
                        onRemove={() => handleRemoveRow(item)}
                        //onExpands={() => handleIsExpandIcon(item.codigogrupo)}
                        codGrupo={item.codigogrupo}
                        setGrupo={isExpand}
                        isOpen={isOpen}
                        isVisible={
                          group !== item.codigogrupo &&
                          items.filter(
                            (cg) => cg.codigogrupo === item.codigogrupo
                          ).length > 1
                        }
                      />
                      {/* {handleGroup(item.codigogrupo)} */}
                    </div>
                  ))}
                </div>
                {/* {handleGroup(item.codigogrupo)} */}
              </div>
            ))}
          </>
        ) : (
          <div className="alert alert-warning text-center">
            {somenteOfertas
              ? "Não há itens disponíveis em promoção."
              : "Não há itens disponíveis para o pedido."}
          </div>
        )}
      </div>

      {checkedItems.length > 0 && (
        <button
          type="button"
          className="btn btn-danger d-inline-flex align-items-center mt-3"
          onClick={() => removeCheckedItems()}
        >
          <FiTrash style={{ marginRight: "5px" }} />
          Remover items selecionados
        </button>
      )}

      <div className="d-block d-md-flex flex-fill justify-content-end my-4 offset-md-0"></div>
      <div className="d-block d-md-flex flex-fill mb-md-4">
        <div className="d-block d-md-flex flex-fill mr-md-2 mb-4 mb-md-0">
          <NavLink
            exact
            to="/"
            className="d-flex align-items-center justify-content-center btn btn-block btn-lg btn-outline-primary mt-0"
          >
            <FaHome className="mr-4" />
            Retornar ao início
          </NavLink>
        </div>

        <div className="d-block d-md-flex flex-fill mr-md-2 ml-md-2 mb-4 mb-md-0">
          <button
            type="button"
            className="d-flex align-items-center justify-content-center btn btn-block btn-lg btn-outline-info mt-0"
            onClick={() => handleShowBuscaProduto()}
          >
            <FiPlusCircle className="mr-2" />
            <span>Adicionar produto à lista</span>
          </button>
        </div>

        <div className="d-block d-md-flex flex-fill ml-md-2 mb-4 mb-md-0">
          <button
            type="button"
            disabled={isDisabledConfirmaPedido}
            title={
              isDisabledConfirmaPedido
                ? "Nenhum produto foi removido ou adicionado a lista"
                : ""
            }
            className="d-flex align-items-center justify-content-center btn btn-block btn-lg btn-outline-success mt-0"
            onClick={() => handleConfirmarPedido()}
          >
            <FiSend className="mr-2" />
            <span>Salvar produtos na lista MPP</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PedidoMPP;
