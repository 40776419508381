import React from 'react';

import { ItemPedido } from '../../typings';

interface ItemPedidoDetailProps {
  itemPedido: ItemPedido;
}

const ItemPedidoDetail: React.FC<ItemPedidoDetailProps> = ({ itemPedido }) => {
  return (
    <table className="table table-striped table-bordered">
      <tbody>
        <tr>
          <td className="text-left">Código do produto/SKU</td>
          <td className="text-left">{itemPedido.produto.codigo}</td>
        </tr>
        <tr>
          <td className="text-left">Descrição do produto</td>
          <td className="text-left">{itemPedido.produto.titulo}</td>
        </tr>
        <tr>
          <td className="text-left">Data da última compra</td>
          <td className="text-left">
            {itemPedido.dataUltimaCompra && new Date(itemPedido.dataUltimaCompra).toLocaleDateString('pt-BR')}
          </td>
        </tr>
        <tr>
          <td className="text-left">Condição de pagamento</td>
          <td className="text-left">{itemPedido.condicaoPagamento}</td>
        </tr>
        <tr>
          <td className="text-left">Entrega parcial em</td>
          <td className="text-left">
            {itemPedido.entrega1Quantidade} em {new Date(itemPedido.entrega1Data).toLocaleDateString('pt-BR')}
          </td>
        </tr>
        <tr>
          <td className="text-left">Nova(s) entrega(s) em</td>
          <td className="text-left">
            {itemPedido.entrega2Quantidade} em {new Date(itemPedido.entrega2Data).toLocaleDateString('pt-BR')}
          </td>
        </tr>
        <tr>
          <td className="text-left">Preço base (sem desconto)</td>
          <td className="text-left">
            {itemPedido.precoBase.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </td>
        </tr>
        <tr>
          <td className="text-left">% de IPI do item</td>
          <td className="text-left">{itemPedido.percentualIpiFormatado}</td>
        </tr>
        <tr>
          <td className="text-left">% de ST do item</td>
          <td className="text-left">{itemPedido.percentualStFormatado}</td>
        </tr>
        <tr>
          <td className="text-left">Preço com impostos</td>
          <td className="text-left">
            {itemPedido.precoTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </td>
        </tr>
        <tr>
          <td className="text-left">Quantidade comprada no mês atual</td>
          <td className="text-left">{itemPedido.quantidadeMesFormatado}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default ItemPedidoDetail;
