import React, { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";

import { useAppContext } from "../../../contexts/app";
import { fetchFaturas } from "../../../services/api";
import SideBar from "../SideBar";

interface Fatura {
  numero: string;
  emissao: string;
  vencimento: string;
  valor: number;
  tipo: string;
}

const Faturas: React.FC = () => {
  const [items, setItems] = useState<Fatura[]>([]);
  const { user, showLoader, hideLoader } = useAppContext();

  const limite = useMemo(() => {
    const limiteCredito = Number(user?.revendedor?.limiteCredito || 0);

    return limiteCredito.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }, [user]);

  const saldo = useMemo(() => {
    const limiteCredito = Number(user?.revendedor?.limiteCredito || 0);
    const saldoDuplicata = Number(user?.revendedor?.saldoDuplicata || 0);

    return (limiteCredito - saldoDuplicata).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }, [user]);

  useEffect(() => {
    async function fetchItems() {
      try {
        const loader = showLoader(
          "Por favor aguarde. Carregando faturas em aberto."
        );
        const response = await fetchFaturas();

        if (response.data) {
          const ordered = [...response.data].sort((itemA, itemB) => {
            return new Date(itemA.vencimento) < new Date(itemB.vencimento)
              ? 1
              : -1;
          });

          setItems(ordered);
        }

        hideLoader(loader);
      } catch (error) {
        Swal.fire({
          icon: "warning",
          title: "Erro",
          text:
            "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });

        console.log(error);
      }
    }

    fetchItems();
  }, [hideLoader, showLoader]);

  return (
    <div className="container-fluid my-4">
      <div className="row">
        <div className="col-md-3">
          <SideBar />
        </div>
        <div className="col-md-9 mt-4 mt-md-0">
          {/* <h3>Minhas faturas em aberto</h3> */}
          <h3>Títulos/Faturas em aberto</h3>
          <hr />

          <div className="alert alert-secondary">
            <span>
              LIMITE DE CRÉDITO: <strong>{limite}</strong>
            </span>{" "}
            /{" "}
            <span>
              SALDO DISPONÍVEL: <strong>{saldo}</strong>
            </span>
          </div>

          {items.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-striped table-borderless">
                <thead className="thead-dark">
                  <tr>
                    <th>NF / Fatura</th>
                    <th>Emissão</th>
                    <th>Vencimento</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((fatura) => (
                    <tr key={fatura.numero}>
                      <td>
                        {fatura.tipo} {fatura.numero}
                      </td>
                      <td>
                        {new Date(fatura.emissao).toLocaleDateString("pt-BR")}
                      </td>
                      <td>
                        {new Date(fatura.vencimento).toLocaleDateString(
                          "pt-BR"
                        )}
                      </td>
                      <td>
                        {fatura.valor.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="alert alert-info">Nenhuma fatura em aberto.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Faturas;
