import React, { useState, FormEvent, useRef, useEffect, useCallback } from 'react';
import { FiSearch, FiSlash } from 'react-icons/fi';

import { fetchProdutos, fetchProdutosCampanha } from '../../services/api';
import { Produto } from '../../typings';

interface ProdutoSearchProps {
  onSelect(produto: any): void;
  params: {
    cnpj: string;
  };
}

interface ProdutoSearchState {
  searchText: string;
  produtos: Produto[];
  loading: boolean;
  message: string;
}
// -----------------------------------------------------------------------------
// Search  - Campanha Compre Ganhe - JL_2021-04
const ProdutoSearchCampanha: React.FC<ProdutoSearchProps> = ({ onSelect = () => {}, params }) => {
  const [state, setState] = useState<ProdutoSearchState>({
    searchText: '',
    produtos: [],
    loading: false,
    message: '',
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearch = useCallback(
    async (e: FormEvent | null) => {
      if (e) {
        e.preventDefault();
      }
      try {
        setState((v) => ({ ...v, loading: true }));

        const responseCampanha = await fetchProdutosCampanha(params);
        const responseProdutos = await fetchProdutos({ searchText: state.searchText, grupo: '' });

        let searchProdutos: any = [];

        await responseCampanha?.data.map((itemCampanha: any) => {
          responseProdutos?.data?.items.map((itemProduto: any) => {
            if (itemCampanha?.cod === itemProduto?.codigo) {
              searchProdutos.push(itemProduto);
            }
          });
        });

        if (searchProdutos.length === 0) {
          setState((v) => ({
            ...v,
            message: `O termo <strong>${state.searchText}</strong> não retornou nenhum resultado.`,
          }));

          setTimeout(() => {
            setState((v) => ({ ...v, message: '' }));
          }, 30000);
        }

        setState((v) => ({ ...v, produtos: searchProdutos }));
      } catch (error) {
        console.log(error);
      } finally {
        setState((v) => ({ ...v, loading: false }));
      }
    },
    [params, state.searchText]
  );

  function handleReset() {
    setState({ searchText: '', produtos: [], loading: false, message: '' });

    inputRef.current?.focus();
  }

  function handleSelect(produto: any) {
    onSelect(produto);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (state.searchText.length >= 3) {
        handleSearch(null);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [state.searchText, handleSearch]);

  return (
    <div>
      <form onSubmit={handleSearch} onReset={() => handleReset()}>
        <div className="input-group mb-3">
          <input
            type="text"
            ref={inputRef}
            autoFocus
            className="form-control form-control-lg"
            placeholder="Digite o código ou nome do produto"
            value={state.searchText}
            onChange={(e) => setState({ ...state, searchText: e.target.value })}
          />
          <div className="input-group-append">
            <button type="submit" className="btn btn-lg btn-primary">
              <FiSearch />
            </button>
            {state.searchText && (
              <button type="reset" className="btn btn-lg btn-outline-primary">
                <FiSlash />
              </button>
            )}
          </div>
        </div>
      </form>

      {state.message && (
        <div className="alert alert-warning text-center" dangerouslySetInnerHTML={{ __html: state.message }}></div>
      )}

      {state.produtos.map((produto) => (
        <button
          key={produto.id}
          type="button"
          className="btn btn-light btn-block d-flex flex-row align-items-center justify-content-between mb-3"
          onClick={() => handleSelect(produto)}>
          <div className="d-flex align-items-center">
            <img src={`https://b2b.ferrarinet.com.br/assets/${produto.codigo}.jpg`} width="80" alt="" />
          </div>
          <div className="flex-grow-1 p-2 text-left">
            <h6 className="card-title mb-1 text-primary">{produto.titulo}</h6>
            <div>
              <small className="text-muted">Modelo: {produto.modelo}</small>
            </div>
            <div>
              <small className="text-muted">SKU: {produto.codigo}</small>
            </div>
          </div>
        </button>
      ))}
    </div>
  );
};
// -----------------------------------------------------------------------------

export default ProdutoSearchCampanha;
