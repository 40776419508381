import React, { FormEvent } from "react";
import { FiThumbsUp, FiX } from "react-icons/fi";
import { FaRegFilePdf } from "react-icons/fa";
import Swal from "sweetalert2";

import OrcamentoDetail from "../../../../components/OrcamentoDetail";
import { Produto } from "../../../../typings";
import { solicitaAprovacaoOrcamento } from "../../../../services/api";
import ENV from "../../../../ENV";

interface ItemPedido {
  produto: Produto;
  valorUnitario: string;
  notaFiscal: string;
  dataEmissaoNotaFiscal: string;
  status: string;
  valorTotal: string;
  quantidade: string;
}

interface Pedido {
  numero: string;
  condicaoPagamento: string;
  descricaoCondicaoPagamento: string;
  dataEmissaoPedido: string;
  itens: ItemPedido[];
  transportadora: {
    nome: string;
    cnpj: string;
  };
  pedidob2b: {
    dataCriacao: string;
    usuario: {
      cnpj: string;
      nome: string;
    };
  };
}

const DetalheOrcamento: React.FC<{
  pedido: any;
  itens: any;
  action?: any;
  setStatus(): void;
}> = ({ pedido, itens, action, setStatus }) => {
  // function formatCNPJ(cnpj: string) {
  //   const rejexCnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

  //   return rejexCnpj;
  // }

  //useEffect(() => {}, []);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    const qtdPrefixo = "000";
    //const ReactSwal = withReactContent(Swal);

    try {
      const response = await solicitaAprovacaoOrcamento({
        id: pedido.id,
        status: 2, //STATUS 2 = AGUARDANDO
        justificativa: pedido.justificativaDesconto,
      });

      if (response) {
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          html: `<div>
          <p>Solicitação para analise de aprovação, foi enviada!</p>
          <p><b>Orçamento:</b> ${
            String(qtdPrefixo.slice(String(pedido.id).length)) + pedido.id
          }</p>
          <h3>OBRIGADO!</h3>
          </div>`,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
          onClose: () => {
            //history.push('/minhaconta/orcamentos');
            setStatus();
            onCancel();
          },
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Erro",
        text:
          "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });

      console.log(error);
    }
  }

  function onCancel() {
    action.close();
  }

  return (
    <>
      <OrcamentoDetail pedido={pedido} itens={itens} />
      <form
        onSubmit={(e) => handleSubmit(e)}
        onReset={() => onCancel()}
        style={{ position: "relative" }}
      >
        <div className="row">
          <div className="col">
            <button
              type="reset"
              onClick={() => onCancel()}
              className="btn btn-block btn-lg btn-outline-danger d-flex align-items-center justify-content-center mt-3"
            >
              <FiX size={24} className="mr-3" />
              Fechar
            </button>
          </div>
          <div className="col">
            {/*<button
              type="button"
              onClick={() => handlerImprimirPDF()}
              className="btn btn-block btn-lg btn-outline-primary d-flex align-items-center justify-content-center mt-3">
              <FaRegFilePdf size={24} className="mr-3" />
              Imprimir PDF
            </button>*/}
            <a
              // eslint-disable-next-line react/jsx-no-target-blank
              target={"_blank"}
              download
              href={`${ENV.API_URL}/orcamento/pdf/${pedido.id}`}
              className="btn btn-block btn-lg btn-outline-primary d-flex align-items-center justify-content-center mt-3"
            >
              <FaRegFilePdf size={24} className="mr-3" />
              Imprimir PDF
            </a>
          </div>
          <div className="col">
            <button
              type="submit"
              title={
                pedido.status === 1
                  ? "Enviar para analise de aprovação"
                  : "Já foi enviado para analise de aprovação"
              }
              disabled={pedido.status !== 1}
              className="btn btn-block btn-lg btn-outline-success d-flex align-items-center justify-content-center mt-3"
            >
              <FiThumbsUp size={24} className="mr-3" />
              Enviar Solicitação
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default DetalheOrcamento;
