import React, { FormEvent, useEffect, useState } from 'react';
import ptBR from 'date-fns/locale/pt-BR';
import { parseISO, format } from 'date-fns';
import Swal from 'sweetalert2';
import { FiSend } from 'react-icons/fi';

import Input from '../../components/Input';
import OrcamentoStatus from '../OrcamentoStatus';
import { solicitaAprovacaoOrcamento } from '../../services/api';
import { IdTipoUsuario } from '../../entities/TipoUsuario';

const OrcamentoInteracoes: React.FC<{ data: any[]; id: number; setOrcamento(data: any): void; user?: any }> = ({
  data,
  id,
  setOrcamento,
  user,
}) => {
  const [interacoes, setInteracoes] = useState<any>([]);
  const [isInteracao, setIsInteracao] = useState(false);
  const [motivo, setMotivo] = useState('');
  const qtdCaracter = 5;

  async function handleFormSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      const response = await solicitaAprovacaoOrcamento({
        id, //: data[0].orcamento.id,
        status: 2, //STATUS: 2 = AGUARDANDO,
        justificativa: motivo,
      });

      if (response) {
        setOrcamento([]);

        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Solicitação para anlise do orçamento foi enviada!',
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Falha ao enviar solcitação de analise do orçamento, tente mais tarde!',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'OK',
      });
    }
  }

  useEffect(() => {
    function getStatusOrcamento() {
      const status = data.pop().orcamento.status;

      if (
        (status === 2 || status === 3) &&
        user?.tipo.nome !== IdTipoUsuario.SUPERVISOR &&
        user?.tipo.nome !== undefined
      ) {
        setIsInteracao(true);
      }
    }

    async function fetchInteracoes() {
      try {
        if (data.length > 0) {
          const qtdPrefixo = '00000';

          const formatData = await data.map((item: any) => {
            item.id = String(qtdPrefixo.slice(String(item.id).length)) + item.id;
            item.data = String(format(parseISO(item.data), 'dd/MM/yyyy HH:mm:ss', { locale: ptBR }));

            return {
              ...item,
              strStatus:
                (item.status === 1 && 'SALVO') ||
                (item.status === 2 && 'AGUARDANDO') ||
                (item.status === 3 && 'REJEITADO') ||
                (item.status === 4 && 'APROVADO') ||
                (item.status === 5 && 'EFETIVADO'),
            };
          });

          setInteracoes(formatData);
          getStatusOrcamento();
        }
      } catch (error) {
        Swal.fire({
          icon: 'warning',
          title: 'Erro',
          text: 'Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.',
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: 'OK',
        });

        console.log(error);
      }
    }
    if (interacoes.length === 0) {
      fetchInteracoes();
    }
  }, [data, interacoes.length, user]);

  return (
    <>
      {interacoes.length > 0 ? (
        <div className="table-responsive">
          <table className="table table-striped table-borderless">
            <thead className="thead-dark">
              <tr>
                <th style={{ maxWidth: '10%', width: '10%', textAlign: 'center' }}>Data Interação</th>
                <th style={{ maxWidth: '20%', width: '20%', textAlign: 'center' }}>Nome</th>
                <th style={{ maxWidth: '10%', width: '10%', textAlign: 'center' }}>Tipo</th>
                <th style={{ maxWidth: '50%', width: '50%', textAlign: 'center' }}>Descrição</th>
                <th style={{ maxWidth: '10%', width: '10%', textAlign: 'center' }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {interacoes.map((item: any, index: number) => (
                <tr key={index}>
                  <td style={{ fontSize: '14px' }}>{item.data}</td>
                  <td style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>{item.usuario.nome}</td>
                  <td style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>{item.tipo.nome}</td>
                  <td
                    style={{
                      fontSize: '14px',
                      textAlign: 'left',
                      wordWrap: 'break-word',
                    }}>
                    {item.justificativa}
                  </td>
                  <td
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <OrcamentoStatus id={item.status} status={item.strStatus} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {isInteracao && (
            <form
              onSubmit={handleFormSubmit}
              style={{
                border: '1px solid #eee',
                fontSize: '.9rem',
              }}
              className="p-4">
              <div className="alert alert-info">
                <div className="row align-items-center">
                  <div className="col-md">
                    <strong>Adicionar uma nova interação e enviar novamente para analise de aprovação</strong>
                  </div>
                </div>
              </div>
              <Input
                className="mb-4"
                label="Interação"
                name="email"
                value={motivo}
                placeholder="Descreva uma breve justificativa para desconto solcitado no orçamento."
                onChange={(e) => {
                  setMotivo(e.target.value);
                }}
              />
              <button
                type="submit"
                disabled={isInteracao && motivo.length < qtdCaracter}
                className="btn btn-block btn-lg btn-outline-primary mt-4 d-flex align-items-center justify-content-center mt-3">
                <FiSend size={24} className="mr-3" />
                Enviar
              </button>
            </form>
          )}
        </div>
      ) : (
        <div className="alert alert-info">Nenhum interação cadastrada para esse orçamento.</div>
      )}
    </>
  );
};

export default OrcamentoInteracoes;
