/*JL-HF_2023-09 */
export default function cnpjCpfMackCustom(doc: string) {
  let rejexDoc = doc;

  if (doc.length === 14) {
    rejexDoc = doc.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );

    return rejexDoc;
  }

  rejexDoc = doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

  return rejexDoc;
}
