import React from "react";

import ButtonContato from "../../../components/ButtonContato";
import { TipoUsuario } from "../../../entities/TipoUsuario";
import { sendAgendaRegistroContatoProsppect } from "../../../services/api";
import { ProspectProps } from "../../../typings";

const ButtonContatoProspect: React.FC<{
  props: any;
  origin: string;
  grupo: string;
  nameButton: string;
  loja: string | undefined;
  nome: string | undefined;
  cnpj: string | undefined;
  codigo?: string | undefined;
  userTipo?: string | undefined;
  userCodigo?: string | undefined;
  setProspects?: React.Dispatch<React.SetStateAction<ProspectProps[]>>;
  setIsShowDropdownOpcoes?: React.Dispatch<React.SetStateAction<number>> | null;
}> = ({
  setIsShowDropdownOpcoes,
  setProspects,
  nameButton,
  userCodigo,
  userTipo,
  origin,
  codigo,
  grupo,
  loja,
  nome,
  cnpj,
  props,
}: any) => {
  async function handleFormSubmitProps({ data, files }: any) {
    await sendAgendaRegistroContatoProsppect({
      data: {
        ...data,
        loja,
        codigo,
        codigoRepresentante: `${
          TipoUsuario[userTipo.toLowerCase()].perfil
        }${userCodigo}`,
      },
      files,
    });
    setProspects([]);
  }

  /*async function fetchAgendaRegistroContatosProps() {
    return await fetchAgendaRegistrosDeContatosPorCodigoProspect({
      codigo,
      loja: "",
    });
  }*/

  return (
    <ButtonContato
      loja={loja}
      cnpj={cnpj}
      nome={nome}
      props={props}
      grupo={grupo}
      codigo={codigo}
      origin={origin}
      nameButton={nameButton}
      //action={fetchAgendaRegistroContatosProps}
      setIsShowDropdownOpcoes={setIsShowDropdownOpcoes}
      handleCreateRegistroContato={handleFormSubmitProps}
      //fetchAgendaRegistroContatosProps={fetchAgendaRegistroContatosProps}
    />
  );
};

export default ButtonContatoProspect;
