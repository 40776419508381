import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./assets/main.scss";

import Routes from "./Routes";
import { AppProvider } from "./contexts/app";
//import Header from './components/Header';
import HeaderHanburg from "./components/HeaderHanburg";

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <BrowserRouter>
        <div className="d-flex flex-column h-100">
          {/* <Header /> */}
          <HeaderHanburg />
          <Routes />
        </div>
      </BrowserRouter>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
