import React from "react";
import Swal from "sweetalert2";
import { sendDeleteProspect } from "../../../services/api";
import { FiTrash2 } from "react-icons/fi";
import { useAppContext } from "../../../contexts/app";

const ButtonExcluirProspect = ({
  data,
  action,
  props,
  setIsShowDropdownOpcoes,
  ...rest
}: any) => {
  const { showLoader, hideLoader } = useAppContext();

  async function handleDelete() {
    try {
      const loader = showLoader(
        "Por favor aguarde. Excluindo cadastro prospect."
      );

      await sendDeleteProspect({ codigo: data.codigo, loja: data.loja });

      setTimeout(() => {
        hideLoader(loader);

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Cadastro prospect excluído!",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
          onClose: () => {
            action();
          },
        });
      }, 1200);
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: `Falha ao excluir cadastro do prospect, tente mais tarde!`,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });
    }
  }

  async function onShowDelete() {
    //e.preventDefault();

    !setIsShowDropdownOpcoes === false ?? setIsShowDropdownOpcoes(0);

    const response = await Swal.fire({
      icon: "question",
      title: "Atenção",
      html: `<p>Deseja Excluir Cadastro Prospect?</p>
                <hr class="mt-4"/>
                <div>
                  <small>
                    <p class="text-left pl-4"><strong>código:</strong> ${data.codigo}<p/>
                    <p class="text-left pl-4"><strong>nome:</strong> ${data.nome}</p>
                  </small>
                </div>
                <hr/>
                `,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: true,
      showConfirmButton: true,
      showCloseButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      onClose: () => {},
    });

    if (response.isConfirmed) {
      handleDelete();
    }
  }

  return (
    <button
      type="button"
      //disabled={isDisabledConfirmaPedido}
      {...rest}
      className={props}
      onClick={() => onShowDelete()}
      title={"Excluir cadastro do prospect."}
    >
      <FiTrash2 className="mr-2" />
      <span>Excluir</span>
    </button>
  );
};

export default ButtonExcluirProspect;
