import React from "react";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { FaRegFilePdf } from "react-icons/fa";
import Swal from "sweetalert2";

import OrcamentoDetail from "../../../../components/OrcamentoDetail";
import { Produto } from "../../../../typings";

interface ItemPedido {
  produto: Produto;
  valorUnitario: string;
  notaFiscal: string;
  dataEmissaoNotaFiscal: string;
  status: string;
  valorTotal: string;
  quantidade: string;
}

interface Pedido {
  numero: string;
  condicaoPagamento: string;
  descricaoCondicaoPagamento: string;
  dataEmissaoPedido: string;
  itens: ItemPedido[];
  transportadora: {
    nome: string;
    cnpj: string;
  };
  pedidob2b: {
    dataCriacao: string;
    usuario: {
      cnpj: string;
      nome: string;
    };
  };
}

const Detalhes: React.FC<{
  orcamento: any;
  itens: any;
  action: any;
  aprovarOrcamento(orcamento: any): void;
  reprovarOrcamento(orcamento: any): void;
}> = ({ orcamento, itens, action, aprovarOrcamento, reprovarOrcamento }) => {
  function onCancel() {
    action.close();
  }
  console.log();
  function handlerImprimirPDF() {
    try {
      let doc: any = document.getElementById("OrcamentoDetalhe");
      let win = window.open("", "print", "height=auto,width=auto");
      win!.document.write(doc?.innerHTML);
      win!.document.close();
      win!.focus();
      win!.print();
      //win?.close();
    } catch (err) {
      Swal.fire({
        icon: "warning",
        title: "Erro",
        text:
          "Ocorreu um erro no gerar PDF. Por favor, tente novamente mais tarde.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });
      console.log(err);
    }
  }

  return (
    <>
      <OrcamentoDetail pedido={orcamento} itens={itens} />
      <form onReset={() => onCancel()} style={{ position: "relative" }}>
        <div className="row">
          <div className="col">
            <button
              type="button"
              onClick={() => reprovarOrcamento(orcamento)}
              className="btn btn-block btn-lg btn-outline-danger d-flex align-items-center justify-content-center mt-3"
            >
              <FiThumbsDown size={24} className="mr-3" />
              Reprovar Orçamento
            </button>
          </div>
          <div className="col">
            <button
              type="button"
              onClick={() => handlerImprimirPDF()}
              className="btn btn-block btn-lg btn-outline-primary d-flex align-items-center justify-content-center mt-3"
            >
              <FaRegFilePdf size={24} className="mr-3" />
              Imprimir PDF
            </button>
          </div>
          <div className="col">
            <button
              type="button"
              onClick={() => aprovarOrcamento(orcamento)}
              className="btn btn-block btn-lg btn-outline-success d-flex align-items-center justify-content-center mt-3"
            >
              <FiThumbsUp size={24} className="mr-3" />
              Aprovar Orçamento
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Detalhes;
