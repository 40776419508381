import React, { FormEvent, useState } from 'react';
import Swal from 'sweetalert2';
import { FiThumbsDown } from 'react-icons/fi';

import { Produto } from '../../../../typings';
import Input from '../../../../components/Input';
import { supervisorRejeitarOrcamento } from '../../../../services/api';

interface ItemPedido {
  produto: Produto;
  valorUnitario: string;
  notaFiscal: string;
  dataEmissaoNotaFiscal: string;
  status: string;
  valorTotal: string;
  quantidade: string;
}

interface Pedido {
  numero: string;
  notaserie: string; //JL-HF_2022-06
  condicaoPagamento: string;
  descricaoCondicaoPagamento: string;
  dataEmissaoPedido: string;
  itens: ItemPedido[];
  transportadora: {
    nome: string;
    cnpj: string;
  };
  pedidob2b: {
    dataCriacao: string;
    usuario: {
      cnpj: string;
      nome: string;
    };
  };
}

const Reprovar: React.FC<{ orcamento?: any; setOrcamento(data: any): void }> = ({ orcamento, setOrcamento }) => {
  const [motivo, setMotivo] = useState(''); //'Desconto solicitado acima das regras comecialmente estabeleciadas.'
  const qtdPrefixo = '000';
  const qtdCaracter = 5;

  async function handleFormSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      const response = await supervisorRejeitarOrcamento({
        id: orcamento.id,
        status: 3, //STATUS: 3 = REJEITAR
        justificativa: motivo,
      });
      if (response) {
        setOrcamento([]);

        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Solicitação do orçamento foi rejeitada!',
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Falha salva, tente mais tarde!',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'OK',
      });
    }
  }

  return (
    <>
      <hr />
      <table
        className="mb-4 text-left table table-borderless"
        style={{
          fontSize: '14px',
          borderTop: '2px solid #eee ',
        }}>
        <tbody>
          <tr>
            <td
              style={{
                width: '25%',
              }}>
              <b>Número orçamento:</b>
            </td>
            <td
              style={{
                width: '45%',
              }}>
              {orcamento.id}
            </td>
          </tr>
          <tr>
            <td>
              <b>Data orçamento:</b>
            </td>
            <td>{orcamento.dataCriacao}</td>
          </tr>
          <tr>
            <td>
              <b>Qtd. Itens:</b>
            </td>
            <td>{String(qtdPrefixo.slice(String(orcamento.quantidadeItens).length)) + orcamento.quantidadeItens}</td>
          </tr>
          <tr>
            <td>
              <b>Valor orçamento:</b>
            </td>
            <td>
              {parseFloat(orcamento.valorTotalComImpostos).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </td>
          </tr>
          {/* <tr>
            <td>
              <b>Total desconto:</b>
            </td>
            <td>{'RS 200,00'}</td>
            <td
              style={{
                width: '20%',
              }}>
              Desconto %
            </td>
            <td>{'5.00'}</td>
          </tr> */}
        </tbody>
      </table>
      <form
        onSubmit={handleFormSubmit}
        style={{
          border: '1px solid #eee',
          fontSize: '.9rem',
        }}
        className="p-4">
        <Input
          className="mb-4"
          label="Motivo"
          name="email"
          value={motivo}
          placeholder="Descreva uma breve justificativa para rejeitar orçamento. "
          onChange={(e) => {
            setMotivo(e.target.value);
          }}
        />
        <button
          type="submit"
          disabled={motivo.length < qtdCaracter}
          className="btn btn-block btn-lg btn-outline-danger mt-4 d-flex align-items-center justify-content-center mt-3">
          <FiThumbsDown size={24} className="mr-3" />
          Reprovar
        </button>
      </form>
    </>
  );
};

export default Reprovar;
