import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { FaBox, FaPercentage, FaGlobe } from "react-icons/fa";
import { RiFileExcel2Line } from "react-icons/ri";
import Swal from "sweetalert2";

import { useAppContext } from "../../contexts/app";
import { FiEdit } from "react-icons/fi";

const Home = () => {
  const { user, userCliente } = useAppContext();

  const history = useHistory();

  useEffect(() => {
    if (user?.isRepresentante && !user.revendedor) {
      Swal.fire({
        text:
          "Você não tem nenhum cliente associado. Você será redirecionado para a tela de seleção de clientes.",
        showCancelButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
        onClose: () => {
          history.push("/clientes");
        },
      });
    }
  }, [history, user, userCliente]);

  return (
    <div className="d-block d-md-flex flex-fill align-items-center">
      <div className="container-fluid my-4">
        <div className="border border-blue p-4">
          <h3 className="text-center">Clique no que deseja fazer</h3>

          <div className="d-block d-md-flex align-items-center justify-content-between mt-4">
            <Link
              to="/pedido/mpp"
              className="flex-fill btn btn-block btn-lg btn-outline-primary mr-md-3"
            >
              <FaBox />
              <div>COLOCAR PEDIDO</div>
              <small>Melhor Pedido Possível / Reposição</small>
            </Link>

            <Link
              to="/pedido/upload"
              className="flex-fill btn btn-block btn-lg btn-outline-primary ml-md-3 mt-md-0 mt-4"
            >
              <RiFileExcel2Line />
              <div>PEDIDO POR EXCEL</div>
              <small>Upload de planilha Excel</small>
            </Link>

            {/* <Link
              to="/component/orcamentodetail"
              className="flex-fill btn btn-block btn-lg btn-outline-primary ml-md-3 mt-md-0 mt-4">
              <RiFileExcel2Line />
              <div>Orcamento Detail</div>
              <small>Upload de planilha Excel</small>
            </Link> */}
          </div>

          <div className="d-block d-md-flex align-items-center justify-content-between mt-4">
            {user?.isRepresentante && (
              <Link
                to="/pedido/edita/mpp"
                className="flex-fill btn btn-block btn-lg btn-outline-primary mr-md-3"
              >
                <FiEdit />
                <div>EDITAR MPP</div>
                <small>Adicionar ou Remover sugestões de MPP</small>
              </Link>
            )}
            <Link
              to="/pedido/ofertas"
              className="flex-fill btn btn-block btn-lg btn-outline-primary ml-md-3 mt-md-0 mt-4"
            >
              <FaPercentage />
              <div>APROVEITE OFERTAS ESPECIAIS</div>
              <small>Itens em oferta / Promoções</small>
            </Link>

            {/*Campanha Compre Ganhe - JL_HF_04-2021*/}
            {/*<Link
              to="/pedido/resgate"
              className={`flex-fill btn btn-block btn-lg btn-outline-primary ml-md-3 mt-md-0 mt-4 ${
                userCliente!.meta!.dtultped!.length === 0 && 'disabled'
              }`}>
              <FaSyncAlt />
              <div>TROCA DE CRÉDITOS</div>
              <small>Itens para resgate / Campanha</small>
            </Link>*/}
          </div>

          <div className="text-center mt-4">
            <a
              href="//ferrarinet.com.br"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-lg btn-outline-primary mt-0 d-block d-md-inline-block"
            >
              <FaGlobe />
              <div>IR PARA O SITE FERRARI</div>
              <small>Catálogo, Especificações Técnicas, etc.</small>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
