import React, { useEffect, useState } from "react";
import { FaExpandArrowsAlt } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { useAppContext } from "../../../contexts/app";
import { fetchPedidos } from "../../../services/api";
import { Produto } from "../../../typings";
import SideBar from "../SideBar";

import DetalhePedido from "./DetalhePedido";
import SolicitarEnvioEmail from "./SolicitarEnvioEmail"; //JL-HF_2022-06

import cnpjCpfMaskCustom from "../../../utils/formatarCnpjCpf"; //JL-HF_2023-06

interface ItemPedido {
  produto: Produto;
  valorUnitario: string;
  notaFiscal: string;
  dataEmissaoNotaFiscal: string;
  status: string;
  valorTotal: string;
  quantidade: string;
}

interface Pedido {
  numero: string;
  notaserie: string; //JL-HF_2022-06
  condicaoPagamento: string;
  descricaoCondicaoPagamento: string;
  dataEmissaoPedido: string;
  itens: ItemPedido[];
  transportadora: {
    nome: string;
    cnpj: string;
  };
  pedidob2b: {
    dataCriacao: string;
    usuario: {
      cnpj: string;
      nome: string;
    };
  };
}

const Pedidos = () => {
  const [items, setItems] = useState<Pedido[]>([]);
  const { showLoader, hideLoader } = useAppContext();

  useEffect(() => {
    async function fetchItems() {
      try {
        const loader = showLoader("Por favor aguarde. Carregando pedidos.");
        const response = await fetchPedidos();
        //console.log(response.data);

        if (response.data) {
          const _pedidos = [...response.data];
          const filtered = _pedidos.filter(
            (item: Pedido) => item.itens.length > 0
          );
          const sorted = filtered.sort((a, b) => {
            return new Date(a.dataEmissaoPedido) < new Date(b.dataEmissaoPedido)
              ? 1
              : -1;
          });

          setItems(sorted);
        }

        hideLoader(loader);
      } catch (error) {
        Swal.fire({
          icon: "warning",
          title: "Erro",
          text:
            "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });

        console.log(error);
      }
    }

    if (items.length === 0) {
      fetchItems();
    }
  }, [hideLoader, items.length, showLoader]);

  function filterPedidoItens(itens: ItemPedido[]) {
    if (itens.length > 0) {
      return itens.filter((item) => Number(item.quantidade) > 0);
    }

    return [];
  }

  function getValorTotal(itens: ItemPedido[]) {
    const filtered = filterPedidoItens(itens);
    let valor = 0;

    if (filtered.length > 0) {
      filtered.forEach((item) => {
        valor += Number(item.valorTotal);
      });
    }

    return valor;
  }

  function getNumeroNF(itens: ItemPedido[]) {
    const filtered = filterPedidoItens(itens);

    if (filtered.length > 0 && filtered[0].notaFiscal) {
      return filtered[0].notaFiscal;
    }

    return "";
  }

  function getDataNF(itens: ItemPedido[]) {
    const filtered = filterPedidoItens(itens);

    if (filtered.length > 0 && filtered[0].dataEmissaoNotaFiscal) {
      return new Date(filtered[0].dataEmissaoNotaFiscal).toLocaleDateString(
        "pt-BR"
      );
    }

    return "";
  }

  function getStatus(itens: ItemPedido[]) {
    // Se todos os itens = 10 <=> Status do pedido = "Faturado";
    // se tem algum item = 10 <=> "Fat. Parcial";
    // se todos os itens < 06 <=> "Em análise";
    // se algum item = 06 <=> "Liberado para faturar.
    const filtered = filterPedidoItens(itens);
    let statuses: number[] = [];

    if (filtered.length > 0) {
      filtered.forEach((item) => {
        const [cod] = item.status.split("-");

        statuses.push(Number(cod));
      });

      if (statuses.every((item) => item === 10)) {
        return "Faturado";
      }

      if (statuses.some((item) => item === 10)) {
        return "Fat. Parcial";
      }

      if (statuses.every((item) => item < 6)) {
        return "Em Análise";
      }

      if (statuses.some((item) => item === 6)) {
        return "Liberado para Faturar";
      }
    }

    return "";
  }

  function showDetalhePedido(pedido: Pedido) {
    const ReactSwal = withReactContent(Swal);

    pedido.itens = filterPedidoItens(pedido.itens);

    ReactSwal.fire({
      title: "Detalhes/itens do pedido",
      html: <DetalhePedido pedido={pedido} />,
      width: "80%",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  //JL-HF_2022-06
  function showSolcitarEnvioEmail(pedido: Pedido) {
    const ReactSwal = withReactContent(Swal);

    pedido.itens = filterPedidoItens(pedido.itens);

    ReactSwal.fire({
      title: "Solicitar Envio de e-mail",
      html: <SolicitarEnvioEmail pedido={pedido} />,
      width: "40%",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  /*function formatCNPJ(cnpj: string) {
    const rejexCnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

    return rejexCnpj;
  }*/

  return (
    <div className="container-fluid my-4">
      <div className="row">
        <div className="col-md-3">
          <SideBar />
        </div>
        <div className="col-md-9 mt-4 mt-md-0">
          <h3>Meus pedidos</h3>

          <hr />

          {items.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-striped table-borderless">
                <thead className="thead-dark">
                  <tr>
                    <th
                      style={{
                        maxWidth: "3%",
                        width: "3%",
                        textAlign: "center",
                      }}
                    >
                      Número
                    </th>
                    <th
                      style={{
                        maxWidth: "9%",
                        width: "9%",
                        textAlign: "center",
                      }}
                    >
                      Data
                    </th>
                    <th
                      style={{
                        maxWidth: "13%",
                        width: "13%",
                        textAlign: "center",
                      }}
                    >
                      Cond. Pagto.
                    </th>
                    <th
                      style={{
                        maxWidth: "9%",
                        width: "9%",
                        textAlign: "center",
                      }}
                    >
                      Status
                    </th>
                    <th
                      style={{
                        maxWidth: "9%",
                        width: "9%",
                        textAlign: "center",
                      }}
                    >
                      Valor
                    </th>
                    <th
                      style={{
                        maxWidth: "3%",
                        width: "3%",
                        textAlign: "center",
                      }}
                    >
                      Número da NF
                    </th>
                    <th
                      style={{
                        maxWidth: "3%",
                        width: "3%",
                        textAlign: "center",
                      }}
                    >
                      Data da NF
                    </th>
                    <th
                      style={{
                        maxWidth: "15%",
                        width: "15%",
                        textAlign: "center",
                      }}
                    >
                      Transportadora
                    </th>
                    {/* <th
                      style={{ maxWidth: '5%', width: '5%'5px', textAlign: 'center' }}
                      title="CNPJ da Transportadora">
                      CNPJ
                    </th> */}
                    <th
                      style={{
                        maxWidth: "5%",
                        width: "5%",
                        textAlign: "center",
                      }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((pedido, index) => (
                    <tr
                      key={index}
                      style={{
                        display:
                          getValorTotal(pedido.itens) === 0
                            ? "none"
                            : "table-row",
                        maxWidth: "1px",
                        width: "1px",
                      }}
                    >
                      <td
                        className="text-uppercase"
                        style={{
                          maxWidth: "2.1px",
                          width: "2.1px",
                          textAlign: "center",
                        }}
                      >
                        {pedido.numero}
                      </td>
                      <td
                        className="text-uppercase"
                        style={{
                          maxWidth: "2.4px",
                          width: "2.4px",
                          textAlign: "center",
                        }}
                      >
                        {new Date(pedido.dataEmissaoPedido).toLocaleDateString(
                          "pt-BR"
                        )}
                      </td>
                      <td
                        className="text-uppercase"
                        style={{
                          maxWidth: "4px",
                          width: "4px",
                          textAlign: "center",
                          wordWrap: "break-word",
                        }}
                      >
                        {pedido.condicaoPagamento}
                        {pedido.descricaoCondicaoPagamento &&
                          ` - ${pedido.descricaoCondicaoPagamento}`}
                      </td>
                      <td
                        className="text-uppercase"
                        style={{
                          maxWidth: "2px",
                          width: "2px",
                          textAlign: "center",
                        }}
                      >
                        {getStatus(pedido.itens)}
                      </td>
                      <td
                        className="text-uppercase"
                        style={{ maxWidth: "2.5px", width: "2.5px" }}
                      >
                        {getValorTotal(pedido.itens).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </td>
                      <td
                        className="text-uppercase"
                        style={{
                          maxWidth: "3px",
                          width: "3px",
                          textAlign: "center",
                        }}
                      >
                        {getNumeroNF(pedido.itens)}
                      </td>
                      <td
                        className="text-uppercase"
                        style={{
                          maxWidth: "2px",
                          width: "2px",
                          textAlign: "center",
                        }}
                      >
                        {getDataNF(pedido.itens)}
                      </td>
                      <td
                        className="text-uppercase"
                        style={{
                          maxWidth: "5.5px",
                          width: "5.5px",
                          textAlign: "left",
                        }}
                      >
                        {pedido.transportadora.cnpj.length &&
                        pedido.transportadora.cnpj !== undefined ? (
                          <>
                            <p
                              style={{
                                fontSize: ".6rem",
                              }}
                            >
                              <b>Nome: </b>
                              {pedido.transportadora.nome}
                            </p>
                            <p
                              style={{
                                fontSize: ".6rem",
                              }}
                            >
                              <b>CNPJ: </b>
                              {cnpjCpfMaskCustom(pedido.transportadora.cnpj)}
                            </p>
                          </>
                        ) : (
                          <p className="text-center m-1">
                            {pedido.transportadora.nome}
                          </p>
                        )}
                      </td>
                      <td
                        className="text-uppercase"
                        style={{ maxWidth: "1px", width: "1px" }}
                      >
                        {pedido.itens.length > 0 && (
                          <div
                            style={{
                              display: "flex",
                              margin: 0,
                              padding: 0,
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "15px",
                            }}
                          >
                            <button
                              type="button"
                              title="Exibir detalhes do pedido"
                              className="btn btn-sm btn-outline-primary d-flex align-items-center"
                              onClick={() => showDetalhePedido(pedido)}
                            >
                              <FaExpandArrowsAlt />
                            </button>

                            <button
                              type="button"
                              title={`Solicitar o envio de pedido ${
                                parseInt(pedido.notaserie) >= 0
                                  ? "e nota fiscal"
                                  : ""
                              } por email`}
                              className="btn btn-sm btn-outline-primary d-flex align-items-center"
                              onClick={() => showSolcitarEnvioEmail(pedido)}
                            >
                              <FiMail />
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="alert alert-info">Nenhum pedido cadastrado.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pedidos;
