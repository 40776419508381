import React from "react";
import Swal from "sweetalert2";
import { FiPaperclip } from "react-icons/fi";

import withReactContent from "sweetalert2-react-content";

import ModalAnexosContato from "../ModalAnexosContato";

const ButtonContatoAnexos = ({
  setIsShowDropdownOpcoes,
  handleCreateRegistroContato,
  fetchAgendaRegistroContatosProps,
  codigoLogin,
  nameButton,
  origin,
  action,
  codigo,
  tipo,
  props,
  ...rest
}: any) => {
  async function onShowForm() {
    //e.preventDefault();

    //recolhe menu dropdown
    setIsShowDropdownOpcoes && setIsShowDropdownOpcoes(0);

    const ReactSwal = withReactContent(Swal);

    ReactSwal.fire({
      //icon: "info",
      title: "Anexos de Contato",
      html: (
        <ModalAnexosContato
          codigo={codigo}
          tipo={tipo} //cliente
          props=""
        />
      ),
      width: "60%",
      allowEscapeKey: false,
      allowOutsideClick: false,
      //showCancelButton: true,
      showConfirmButton: false,
      showCloseButton: true,
      confirmButtonText: "Fechar",
      //denyButtonText: "Historico",
      // customClass: {
      //   actions: "my-actions",
      //   cancelButton: "order-1 right-gap",
      //   confirmButton: "order-2",
      //   denyButton: "order-3",
      //},
      //onClose: () => {},
    });

    /*if (response.isConfirmed) {
      handleEfetivar();
    }*/
  }
  return (
    <button
      type="button"
      //disabled={isDisabledConfirmaPedido}
      {...rest}
      className={props}
      onClick={() => onShowForm()}
      title="Anexos do contato"
    >
      <FiPaperclip className="mr-2" />
      Anexos
    </button>
  );
};

export default ButtonContatoAnexos;
