import React from "react";
import Swal from "sweetalert2";
import { FiEye } from "react-icons/fi";

import withReactContent from "sweetalert2-react-content";

import {
  fetchAgendaRegistrosDeContatosPorCodigoCliente,
  fetchAgendaRegistrosDeContatosPorCodigoProspect,
} from "../../services/api";
import ModalHistoricoContato from "../ModalHistoricoContato";

const ButtonContatoHistorico = ({
  setIsShowDropdownOpcoes,
  //handleCreateRegistroContato,
  //fetchAgendaRegistroContatosProps,
  codigoLogin,
  nameButton,
  origin,
  action,
  codigo,
  loja,
  tipo,
  nome,
  ...rest
}: any) => {
  //e.preventDefault();
  const ReactSwal = withReactContent(Swal);

  //recolhe menu dropdown

  function onShowAgendaHistorico() {
    setIsShowDropdownOpcoes && setIsShowDropdownOpcoes(0);

    const fetchAgendaRegistroContatosProps = () => {
      const action: any = {
        cliente: async () => {
          return await fetchAgendaRegistrosDeContatosPorCodigoCliente({
            codigo,
            loja,
          });
        },
        prospect: async () => {
          return await fetchAgendaRegistrosDeContatosPorCodigoProspect({
            codigo,
            loja: "",
          });
        },
      };

      const response = action[origin];

      return response();
    };
    const props = {
      origin,
      codigo,
      loja,
      nome,
    };

    ReactSwal.fire({
      //icon: "info",
      title: "Histórico de Contato",
      html: (
        <ModalHistoricoContato
          fetchAgendaRegistroContatosProps={fetchAgendaRegistroContatosProps}
          props={props}
          userTipo
        />
      ),
      width: "60%",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: true,
      showConfirmButton: false,
      showCloseButton: true,
      cancelButtonText: "Fechar",
    });
  }

  return (
    <button
      {...rest}
      type="button"
      onClick={() => onShowAgendaHistorico()}
      title="Histórico dos contatos"
    >
      <FiEye className="mr-2" />
      Histórico
    </button>
  );
};

export default ButtonContatoHistorico;
