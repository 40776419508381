import React from 'react';

interface StatusProps {
  id: number;
  status: 'EXCLUIDO' | 'SALVO' | 'AGUARDANDO' | 'REJEITADO' | 'APROVADO';
}

interface GetStatusProps {
  id: number;
  nome: string;
  color: string;
  title: string;
}

const OrcamentoStatus: React.FC<StatusProps> = ({ id, status }: StatusProps) => {
  const getStatus = {
    EXCLUIDO: {
      id: 0,
      nome: 'Excluído',
      color: '#eee',
      title: 'Orçamento excluido no B2B, NÃO é listado para aprovação!',
    },
    SALVO: {
      id: 1,
      nome: 'Salvo',
      color: '#0d6efd',
      title: 'Orçamento salvo no B2B, NÃO foi enviado para aprovação!',
    },
    AGUARDANDO: {
      id: 2,
      nome: 'Aguardando',
      color: '#ffc107',
      title: 'Orçamento salvo no B2B, enviado para aprovação!',
    },
    REJEITADO: {
      id: 3,
      nome: 'Rejeitado',
      color: '#dc3545',
      title: 'Orçamento salvo no B2B, aprovação rejeitada, NÃO pode ser realizado pedido!',
    },
    APROVADO: {
      id: 4,
      nome: 'Aprovado',
      color: '#20c997',
      title: 'Orçamento salvo no B2B,  aprovação aceita, pode ser realizado pedido!',
    },
    EFETIVADO: {
      id: 5,
      nome: 'Efetivado',
      color: '#28a745',
      title: 'Orçamento salvo no B2B, aprovado e orçamento já foi enviado para pedido!',
    },
  };
  const statusActive: GetStatusProps = getStatus[status];

  return (
    <>
      {statusActive && (
        <div
          style={{
            background: `${statusActive.color}`,
            width: '5.1rem',
            minWidth: '5.1rem',
            textAlign: 'center',
            height: '2rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFF',
          }}>
          <span title={statusActive.title}>{statusActive.nome}</span>
        </div>
      )}
    </>
  );
};

export default OrcamentoStatus;
