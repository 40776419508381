import React from "react";

import { solicitaRelatorioFaturamento } from "../../../../services/api";
import ModalSolicitarEnvioEmail from "../../ModalSolicitarEnvioEmail";
import {
  RelatorioEmailPedidosFaturamento,
  TipoUser,
} from "../../../../typings";

const SolicitarEnvioEmail: React.FC<{
  codigo?: string | undefined;
  tipo?: TipoUser | undefined;
}> = ({ codigo, tipo }) => {
  async function handleFormSubmitProps(data: RelatorioEmailPedidosFaturamento) {
    await solicitaRelatorioFaturamento(data);
  }

  return (
    <ModalSolicitarEnvioEmail
      title="Relatório Faturamento"
      origin="faturamento"
      codigo={codigo}
      tipo={tipo}
      handleFormSubmitProps={handleFormSubmitProps}
    />
  );
};

export default SolicitarEnvioEmail;
