import React from "react";

interface LoadingCustomProps {
  isLoading: boolean;
  texto: string;
}

const LoadingCustom = ({ isLoading = true, texto }: LoadingCustomProps) => {
  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(255, 255, 255, 0.75)",
            zIndex: 1000,
          }}
        >
          <div className="spinner-border text-info"></div>
          <div className="text-primary mt-4">{texto}</div>
        </div>
      )}
    </>
  );
};
export default LoadingCustom;
