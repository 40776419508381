//JL-HF_2022-06
import React, { useState } from "react";
import { parseISO, format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

/*interface OrcamentoResumoProps {
  quantidadeItens: number;
  condicaoPagamento: string;
  valorTotalSemImpostos: number;
  valorTotalComImpostos: number;
  valorTotalDescontos: number;
  ipiTotal: number;
  stTotal: number;
  itens: ItemPedido[];
  user: User | null;
  onConfirm(response: AxiosResponse): void;
  onCancel(): void;
}*/

const OrcamentoDetail: React.FC<{ pedido?: any; itens?: any }> = ({
  pedido,
  itens,
}) => {
  const qtdPrefixo = "000";
  const [loading] = useState(false);
  const [errorMessage] = useState("");

  return (
    <fieldset
      disabled={loading}
      style={{
        minWidth: "100%",
      }}
    >
      {loading && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(255, 255, 255, 0.75)",
            zIndex: 1000,
          }}
        >
          <div className="spinner-border text-info"></div>
          <div className="text-primary mt-4">
            Carregando detalhes do orçamento.
          </div>
        </div>
      )}

      {errorMessage && (
        <div className="alert alert-danger text-left">{errorMessage}</div>
      )}

      <div
        id="OrcamentoDetalhe"
        className="m-4 p-2 table-responsive"
        style={{
          width: "97.5%",
          minWidth: "1024px !important",
          display: "flex",
          flexDirection: "column",
          justifyContent: "left",
          textAlign: "left",
          alignItems: "left",
          border: "2px solid #ddd",
          fontSize: "12px",
        }}
      >
        <div className="mt-2">
          <img
            src={require("../../assets/logo.png")}
            alt="ferrari"
            height="25px"
          />
        </div>

        <table className="mt-4 mb-1">
          <tbody>
            <tr>
              <td style={{ width: "14%" }}>
                <b>Orçamento:</b>
              </td>
              <td>{pedido.id}</td>
            </tr>
            <tr>
              <td>
                <b>Data:</b>
              </td>
              <td>{String(pedido.dataCriacao)}</td>
            </tr>
            <tr>
              <td>
                <b>Empresa:</b>
              </td>
              <td colSpan={3}>
                {"CARACOL COMERCIO DE MAQUINAS E FERRAMENTAS LTDA"}
              </td>
            </tr>
            <tr>
              <td>
                <b>Representação:</b>
              </td>
              <td
                colSpan={3}
              >{`${pedido.usuario.codigo} - ${pedido.usuario.nome}`}</td>
            </tr>
            <tr>
              <td>
                <b>Quem tirou:</b>
              </td>
              <td
                colSpan={3}
              >{`${pedido.usuario.codigo} - ${pedido.usuario.nome}`}</td>
            </tr>
          </tbody>
        </table>
        <table className="mt-2 mb-2">
          <tbody>
            <tr>
              <td style={{ width: "10%" }}>
                <b>Cliente:</b>
              </td>
              <td
                style={{ width: "30%" }}
              >{`${pedido.revendedor.codigo} - ${pedido.revendedor.nome}`}</td>
            </tr>
            <tr>
              <td>
                <b>CNPJ:</b>
              </td>
              <td>{pedido.revendedor.cnpj}</td>
              <td style={{ width: "10%" }}>
                <b>I.E:</b>
              </td>
              <td style={{ width: "20%" }}>
                {pedido.revendedor.inscricaoEstadual}
              </td>
            </tr>
            <tr>
              <td>
                <b>Endereço:</b>
              </td>
              <td colSpan={4}>{pedido.revendedor.endereco}</td>
            </tr>
            <tr>
              <td>
                <b>Bairro:</b>
              </td>
              <td>{pedido.revendedor.bairro}</td>
              <td>
                <b>CEP:</b>
              </td>
              <td>{pedido.revendedor.cep} </td>
            </tr>
            <tr>
              <td>
                <b>Cidade:</b>
              </td>
              <td>{pedido.revendedor.municipio}</td>
              <td>
                <b>UF:</b>
              </td>
              <td>{pedido.revendedor.uf}</td>
            </tr>
            <tr>
              <td>
                <b>Telefone:</b>
              </td>
              <td>{`(${pedido.revendedor.ddd}) ${pedido.revendedor.tel}`}</td>
              {/* <td>
                <b>Celular:</b>
              </td>
              <td>{`(${pedido.revendedor.ddd}) ${pedido.revendedor.tel}`}</td> */}
              <td>
                <b>Tipo Frete:</b>
              </td>
              <td>{pedido.tipoFrete === "C" ? "CIF" : "FOB"}</td>
            </tr>
            <tr>
              <td>
                <b>Classe do Cliente:</b>
              </td>
              <td>{pedido.revendedor.classificacaoDescricao}</td>
              <td>
                <b>Cond. de Pagamento:</b>
              </td>
              <td>
                {`${pedido.condicaoPagamento} - ${pedido.descricaoCondicaoPagamento}`}{" "}
              </td>
            </tr>
          </tbody>
        </table>

        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th className="text-center" rowSpan={2}>
                ITEM
              </th>
              <th className="text-center" rowSpan={2}>
                QTDE
              </th>
              <th className="text-center" rowSpan={2}>
                CÓDIGO
              </th>
              <th className="text-center" rowSpan={2}>
                DESCRIÇÃO DO PRODUTO
              </th>
              <th className="text-center" rowSpan={1} colSpan={3}>
                DESCONTOS
              </th>
              <th className="text-center" rowSpan={2}>
                VLR. UNIT <br /> S/IMP.
              </th>
              <th className="text-center" rowSpan={2}>
                IPI
              </th>
              <th className="text-center" rowSpan={2}>
                ST
              </th>
              <th className="text-center">TOTAL</th>
              <th className="text-center" rowSpan={2}>
                DT.ESTOQUE
              </th>
              <th className="text-center">V.UNIT</th>
            </tr>
            <tr>
              <th className="text-center">PADRÃO</th>
              <th className="text-center">AD.(%)</th>
              <th className="text-center">AD.(R$)</th>
              <th className="text-center">C/IMP.</th>
              <th className="text-center">C/IMP.</th>
            </tr>
          </thead>
          <tbody>
            {itens.map((item: any, index: number) => (
              <tr key={index}>
                <td className="text-center">
                  {String(qtdPrefixo.slice(String(index + 1).length)) +
                    (index + 1)}
                </td>
                <td className="text-center">{item.quantidadeComprar}</td>
                <td className="text-center">{item.codigo}</td>
                <td className="text-left">{item.descricao}</td>
                <td className="text-center">{item.percentualPadrao}</td>
                <td className="text-center">{item.percentualDesconto}</td>
                <td className="text-center">
                  {(item.precoBase * item.percentualDesconto) / 100}
                </td>
                <td className="text-right">
                  {item.precoPorFormatado.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </td>

                <td className="text-right">{item.ipi}</td>
                <td className="text-right">{item.st}</td>
                <td className="text-right">
                  {item.precoTotal.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </td>
                <td className="text-center">
                  {String(
                    format(parseISO(item.entrega1Data), "dd/MM/yyyy", {
                      locale: ptBR,
                    })
                  )}
                </td>
                <td className="text-right">
                  {(item.precoTotal / item.quantidadeComprar).toLocaleString(
                    "pt-BR",
                    {
                      style: "currency",
                      currency: "BRL",
                    }
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td rowSpan={5} colSpan={7}>
                Totais:
              </td>
              {/* <td colSpan={3}>Total de SUFRAMA:</td>
              <td colSpan={3}>
                {parseFloat(pedido.valorTotalDescontos).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </td> */}
            </tr>
            <tr>
              <td colSpan={3}>Total de Mercadorias:</td>
              <td colSpan={3}>
                {parseFloat(pedido.valorTotalSemImpostos).toLocaleString(
                  "pt-BR",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={3}>Total de IPI:</td>
              <td colSpan={3}>
                {parseFloat(pedido.ipiTotal).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </td>
            </tr>
            <tr>
              <td colSpan={3}>Total ST:</td>
              <td colSpan={3}>
                {parseFloat(pedido.stTotal).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </td>
            </tr>
            <tr>
              <td colSpan={3}>Total do Pedido:</td>
              <td colSpan={3}>
                {parseFloat(pedido.valorTotalComImpostos).toLocaleString(
                  "pt-BR",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )}
              </td>
            </tr>
          </tfoot>
        </table>
        <div className="text-left mt-1 pl-1" style={{ fontSize: "11px" }}>
          <p style={{ marginBottom: "2px" }}>
            Pedidos abaixo do valor de R$ 2.000,00 será acrescido na nota
            fiscal, o valor de frete correspondente a região da entrega. Vide
            tabela abaixo:
          </p>
          <p style={{ marginBottom: "2px" }}>Regiões Sul e Sudeste R$ 50,00</p>
          <p style={{ marginBottom: "2px" }}>
            Regiões Centro Oeste, Norte e Nordeste R$ 80,00
          </p>
          <p style={{ marginBottom: "2px" }}>
            O valor de frete compõe a Base de Cálculo do ICMS e IPI.
          </p>
        </div>

        {/* <div className="row">
        <div className="col">
          <button
            type="reset"
            className="btn btn-block btn-lg btn-outline-danger d-flex align-items-center justify-content-center mt-3">
            <FiX size={24} className="mr-3" />
            Não
          </button>
        </div>
        <div className="col">
          <button
            type="submit"
            onClick={() => setIsEnviar(false)}
            className="btn btn-block btn-lg btn-outline-primary d-flex align-items-center justify-content-center mt-3">
            <FiSave size={24} className="mr-3" />
            Salvar
          </button>
        </div>
        <div className="col">
          <button
            type="submit"
            onClick={() => setIsEnviar(true)}
            className="btn btn-block btn-lg btn-outline-success d-flex align-items-center justify-content-center mt-3">
            <FiThumbsUp size={24} className="mr-3" />
            Sim
          </button>
        </div>
      </div> */}
      </div>
    </fieldset>
  );
};

export default OrcamentoDetail;
