import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Select from "react-select";
import Input from "../Input";
import {
  FiCheckCircle,
  FiEye,
  FiPaperclip,
  FiPlusSquare,
  FiSave,
  FiSearch,
  FiX,
  FiXCircle,
} from "react-icons/fi";
import Swal from "sweetalert2";
import {
  fetchAgendaCodigoInatividade,
  fetchAgendaConcorrentes,
  fetchGruposProdutosPorCNPJ,
  fetchProdutosPesquisa,
} from "../../services/api";

import withReactContent from "sweetalert2-react-content";

import {
  InatividadeProps,
  GrupoProdutosPorCNPJ,
  Produto,
  ConcorrenteProps,
} from "../../typings";
import LoadingCustom from "../LoadingCustom";
import ButtonContatoHistorico from "../ButtonContatoHistorico";

interface RegistroContatoFormProps {
  handleCreateRegistroContato: ({ data, file }: any) => void;
  //fetchAgendaRegistroContatosProps: () => void;
  origin: "cliente" | "pedido" | "prospect";
  codigo: string;
  loja: string;
  grupo: any;
  nome: string;
  cnpj: string | undefined;
}

const CODIGO_OPERACAO = {
  adicionar: "1",
  alterar: "2",
  excluir: "3",
};

interface TipoContatoProps {
  id: number;
  nome: string;
  sigla: string;
}

const TIPO_CONTATO = [
  {
    id: 1,
    nome: "Ativo",
    sigla: "A",
  },
  {
    id: 2,
    nome: "Receptivo",
    sigla: "R",
  },
] as TipoContatoProps[];

interface ProdutoSearchState {
  searchTextGrupo: string;
  searchTextProduto: string;
  grupo: string;
  grupos: any[];
  produtos: Produto[];
  loading: boolean;
  message: string;
}

const RegistroContatoForm = (props: RegistroContatoFormProps) => {
  const today = useMemo(() => {
    const d = new Date();
    const day = d.getDate(); // + 1;
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    const formatedDay = day < 10 ? `0${day}` : day;
    const formatedMonth = month < 10 ? `0${month}` : month;

    return `${year}-${formatedMonth}-${formatedDay}`;
  }, []);
  const loaderRef = useRef<number>();

  const [valor, setValor] = useState("");
  const [grupo, setGrupo] = useState<any>({});
  const [grupos, setGrupos] = useState<GrupoProdutosPorCNPJ[]>([]);
  const [arquivos, setArquivos] = useState<any>([]);
  //const [produto, setProduto] = useState<Produto>({} as Produto);
  const [produtos, setProdutos] = useState<any[]>([]);
  //const [isChecked, setIschecked] = useState(false);
  const [inatividades, setInatividades] = useState<InatividadeProps[]>([]);
  const [inatividade, setInatividade] = useState<InatividadeProps>(
    {} as InatividadeProps
  );

  const [concorrentes, setConcorrentes] = useState<ConcorrenteProps[]>([]);
  const [concorrente, setConcorrente] = useState<ConcorrenteProps>(
    {} as ConcorrenteProps
  );

  const [tipoContato, setTipoContato] = useState<TipoContatoProps>(
    {} as TipoContatoProps
  );

  const [observacao, setObservacao] = useState("");

  const [dataProximoContato, setDataProximoContato] = useState(today); //useState(today);

  const [inputProdutoIsDisabled, setInputProdutoIsDisabled] = useState(true);

  const [state, setState] = useState<ProdutoSearchState>({
    searchTextGrupo: "",
    searchTextProduto: "",
    grupos: [],
    grupo: grupo?.codigo || 0,
    produtos: [],
    loading: false,
    message: "",
  });

  const inputRefProduto = useRef<HTMLInputElement>(null);
  const inputUploadRef = useRef<HTMLInputElement | null>(null);

  const ReactSwal = withReactContent(Swal);

  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");

  let getValor = "";

  /*async function handleCreateAgenda(data: any) {
    try {
      //await createAgenda(data);

      setTimeout(() => {
        //hideLoader(loader);

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Próximo agendamento de contato com prospect salvo!",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
          onClose: () => {
            //action();
            //window.location.reload();
            //setData({});
          },
        });
      }, 60);
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: `Falha ao salvar agendamento do prospect, tente mais tarde!`,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });
    }
  }*/

  function formatValor(v: any) {
    getValor = v.replace(/[^0-9]/g, "");
    switch (getValor.length) {
      // case 1:
      //   v = v.replace(/(\d{1})/, "$1,00");
      //   break;
      // case 2:
      //   v = v.replace(/(\d{2})/, "$1,00");
      //   break;
      case 3:
        getValor = getValor.replace(/(\d{1})(\d{2})/, "$1,$2");
        break;
      case 4:
        getValor = getValor
          .replace(/[^0-9]/g, "")
          .replace(/(\d{2})(\d{2})/, "$1,$2");
        break;
      case 5:
        getValor = getValor.replace(/(\d{3})(\d{2})/, "$1,$2");
        break;
      case 6:
        getValor = getValor.replace(/(\d{1})(\d{3})(\d{2})/, "$1.$2,$3");
        break;
      case 7:
        getValor = getValor.replace(/(\d{2})(\d{3})(\d{2})/, "$1.$2,$3");
        break;
      case 8:
        getValor = getValor.replace(/(\d{3})(\d{3})(\d{2})/, "$1.$2,$3");
        break;
      case 9:
        getValor = getValor.replace(
          /(\d{1})(\d{3})(\d{3})(\d{2})/,
          "$1.$2.$3,$4"
        );
        break;
      case 10:
        getValor = getValor.replace(
          /(\d{2})(\d{3})(\d{3})(\d{2})/,
          "$1.$2.$3,$4"
        );
        break;
      case 11:
        getValor = getValor.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/,
          "$1.$2.$3,$4"
        );
        break;
      case 12:
        getValor = getValor.replace(
          /(\d{1})(\d{3})(\d{3})(\d{3})(\d{2})/,
          "$1.$2.$3.$4,$5"
        );
        break;
    }
    return getValor;
  }

  /*function onShowAgendaHistorico() {
    ReactSwal.fire({
      //icon: "info",
      title: "Histórico de Contato",
      html: (
        <ModalHistoricoContato
          fetchAgendaRegistroContatosProps={
            props.fetchAgendaRegistroContatosProps
          }
          props={props}
        />
      ),
      width: "60%",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: true,
      showConfirmButton: false,
      showCloseButton: true,
      //showDenyButton: true,
      //reverseButtons: true,
      cancelButtonText: "Fechar",
      //confirmButtonText: "Fechar",
      //denyButtonText: "Historico",
      // customClass: {
      //   actions: "my-actions",
      //   cancelButton: "order-1 right-gap",
      //   confirmButton: "order-2",
      //   denyButton: "order-3",
      //},
      //onClose: () => {},
    });

    /*if (response.isConfirmed) {
      handleEfetivar();
    }*/
  /*}*/

  const handleSetGrupo = (value: any) => {
    setGrupo(value);
    state.grupo = value.grupo;
    state.searchTextProduto = "";

    setInputProdutoIsDisabled(false);
    setProdutos([] as Produto[]);
    handleResetProdutos();
    //handleSearchProdutos(null);
  };

  function handleResetProdutos() {
    setState({
      searchTextGrupo: state.searchTextGrupo,
      searchTextProduto: state.searchTextProduto,
      grupo: state.grupo,
      grupos: state.grupos,
      produtos: [],
      loading: false,
      message: "",
    });

    inputRefProduto.current?.focus();
  }

  async function handleUploadAnexos(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files[0]) {
      try {
        /*const loader = showLoader(
          "Por favor aguarde. Esta tarefa pode demorar vários segundos. Carregando arquivos."
        );*/

        setArquivos(e.target.files);

        //hideLoader(loader);
      } catch {
        console.log(e);
        Swal.fire({
          icon: "warning",
          title: "Erro",
          text:
            "Ocorreu um erro ao carregar os arquivos. Por favor, tente novamente mais tarde.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });
      }
    }
  }

  function openFileDialog() {
    inputUploadRef.current?.click();
  }

  function handleSelect(getProduto: any) {
    //onSelect(produto);
    const isProduto = produtos.find((p) => p === getProduto);

    if (isProduto) {
      setProdutos(produtos.filter((f) => f !== getProduto));
      return;
    }

    setProdutos([...produtos, getProduto]);
  }

  function showLoader(message: string) {
    loaderRef.current = Date.now();

    Swal.fire({
      text: message,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onBeforeOpen: () => Swal.showLoading(),
    });

    return loaderRef.current;
  }

  function hideLoader(id: number) {
    if (loaderRef.current === id) {
      Swal.close();
    }
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    if (!inatividade?.codigo || !tipoContato?.sigla || observacao.length < 3) {
      setMessage(
        "Os campos Código Inativo, Tipo Contato e Observação são obrigatórios!"
      );

      setTimeout(() => {
        setMessage("");
      }, 6000);

      return;
    }

    const loader = showLoader(
      `Por favor aguarde. Estamos salvando o seu ${
        props.origin !== "pedido"
          ? "registro de contato."
          : "comentário ou justificativa."
      }`
    );

    try {
      let formatValor = "0.00";

      if (String(valor).length > 0) {
        formatValor = valor.replaceAll(".", "");
        formatValor = formatValor.replace(",", ".");
      }

      const data = {
        loja: props?.loja.length > 0 ? props.loja : "",
        codigoOperacao: CODIGO_OPERACAO.adicionar,
        dataProximoContato:
          props.origin !== "pedido"
            ? dataProximoContato.replaceAll("-", "")
            : "",
        dataInteracao: today.replaceAll("-", ""),
        codigoInatividade: inatividade.codigo,
        descricaoInatividade: inatividade.descricao,
        tipo: !tipoContato ? "" : tipoContato?.sigla,
        codigoConcorrente: concorrente?.codigo ? concorrente?.codigo : "",
        valor: formatValor,
        codigoGrupoProduto: !grupo ? "" : grupo?.grupo,
        codigoProduto: [] as any,
        tipoContato: props.origin === "cliente" ? "1" : "2",
        observacao,
      };

      if (produtos.length > 0) {
        data.codigoProduto = await produtos.map((p) => {
          return p.codigo;
        });
      }

      await props.handleCreateRegistroContato({ data, files: arquivos });

      hideLoader(loader);

      Swal.fire({
        icon: "success",
        title: "Sucesso",
        text: `Seu ${
          props.origin !== "pedido"
            ? "regitro de contato"
            : "comentário ou justificativa"
        } foi enviada com sucesso!`,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "warning",
        title: "Erro",
        text:
          "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });
    }
  }

  const handleSearchProdutos = useCallback(
    async (e: FormEvent | null) => {
      if (e) {
        e.preventDefault();
      }

      handleResetProdutos();

      state.grupo =
        props?.grupo && !state?.grupo ? props?.grupo.grupo : state.grupo;

      try {
        if (
          (state?.grupo?.length > 0 && state?.grupo !== undefined) ||
          state?.searchTextProduto?.length > 3
        ) {
          setState((v) => ({ ...v, loading: true }));
          ///state.grupo = ""; //grupo.codigo;

          //const response = await fetchProdutos({
          const response = await fetchProdutosPesquisa({
            searchText: state.searchTextProduto,
            grupo: parseInt(state?.grupo) > 0 ? state?.grupo : "",
            all: parseInt(state?.grupo) > 0 ? false : true,
          });

          //const items = response.data.items;
          let produtos = [] as any;

          if (parseInt(state?.grupo) > 0 && response.data.items.length > 0) {
            produtos = response.data.items.filter(
              (item: any) => item?.grupo?.codigo === state.grupo
            );

            setState((v) => ({ ...v, produtos })); //:  response.data.items }));
          }

          if (parseInt(state?.grupo) === 0 && response.data.items.length > 0) {
            produtos = response.data.items;

            setState((v) => ({ ...v, produtos })); //:  response.data.items }));
          }

          if (produtos.length === 0) {
            setState((v) => ({
              ...v,
              message: `O termo <strong>${state.searchTextProduto}</strong> não retornou nenhum resultado.`,
            }));

            setTimeout(() => {
              setState((v) => ({ ...v, message: "" }));
            }, 3000);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setState((v) => ({ ...v, loading: false }));
      }
    },
    [state.grupo, state.searchTextProduto]
  );

  const handleSearchGrupos = useCallback(
    async (e: FormEvent | null) => {
      if (e) {
        e.preventDefault();
      }

      try {
        setState((v) => ({ ...v, loading: true }));

        const cnpj: any =
          !props.cnpj ||
          props.origin === "cliente" ||
          parseInt(state.grupo) === 0
            ? "GRUPO"
            : props.cnpj;

        const response: any = await fetchGruposProdutosPorCNPJ({ cnpj });

        if (props.origin === "pedido") {
          state.grupo = props.grupo.grupo;
          setGrupos([props.grupo]);
          setGrupo(props.grupo);
          return;
        }
        /*const sorted = await response.sort((a: any, b: any) => {
          return a["grupo"] > b["grupo"] ? 1 : -1;
        });
        let getGrupo = "";*/

        /*const groupByGrupos = [] as GrupoProdutosPorCNPJ[];
        await sorted.map((item: GrupoProdutosPorCNPJ) => {
          if (item.grupo !== getGrupo) {
            groupByGrupos.push(item);
            getGrupo = item.grupo;
          }
        });*/

        await setGrupos([
          {
            codigo: "000",
            descri_grupo: "SELECIONE...",
            grupo: "000",
            loja: "00",
            nome: "",
          } as GrupoProdutosPorCNPJ,
          ...response,
        ]);
      } catch (error) {
        console.log(error);
      } finally {
        setState((v) => ({ ...v, grupos }));
        setState((v) => ({ ...v, loading: false }));
        setInputProdutoIsDisabled(grupos.length > 0);
      }
    },
    [grupo, state.grupo, state.searchTextGrupo]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (state.searchTextGrupo.length >= 3 && state.grupo === grupo.codigo) {
        handleSearchGrupos(null);
      }
    }, 60);

    return () => clearTimeout(timer);
  }, [state.searchTextGrupo, handleSearchGrupos, state.searchTextGrupo.length]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (state.searchTextProduto.length >= 3) {
        handleSearchProdutos(null);
      }
    }, 60);

    return () => clearTimeout(timer);
  }, [
    state.searchTextProduto,
    handleSearchProdutos,
    state.searchTextProduto.length,
  ]);

  useEffect(() => {
    async function carregarCodigoInatividade() {
      const response: any = await fetchAgendaCodigoInatividade();

      setInatividades(response?.filter((item: any) => item.uso === "S"));
    }

    async function carregaCodigoConcorrentes() {
      const response: ConcorrenteProps[] = await fetchAgendaConcorrentes();

      setConcorrentes([
        {
          codigo: "00",
          nome: "SELECIONE...",
          nomeAbreviado: "string",
        } as ConcorrenteProps,
        ...response,
      ] as ConcorrenteProps[]);
    }

    async function carregarDados() {
      await setIsLoading(true);
      await carregarCodigoInatividade();
      await carregaCodigoConcorrentes();
      await handleSearchGrupos(null);
      await setIsLoading(false);
    }
    if (
      inatividades.length === 0 &&
      grupos.length === 0 &&
      produtos.length === 0
    ) {
      carregarDados();
    }
  }, [grupo, concorrente]);

  return (
    <div>
      <div className="col-md-12 mt-12 mt-md-0" style={{ minHeight: "40vh" }}>
        <hr />
        <LoadingCustom
          isLoading={isLoading}
          texto="Aguarde, carregando campos do registro de contato!"
        />
        {message.length > 0 && (
          <div className="alert alert-info">
            <p>{message}</p>
          </div>
        )}
        {!isLoading && (
          <form
            onSubmit={(e) => handleSubmit(e)}
            //onReset={() => onCancel()}
            style={{ position: "relative" }}
          >
            <div className="row">
              <div className="col-md-12 mt-4 mt-md-2">
                <div className="">
                  <div className="">
                    <div className="row">
                      {props.origin !== "pedido" ? (
                        <>
                          <div className="col-md-6">
                            <Input
                              //disabled={isDisabled}
                              type="date"
                              min={today}
                              value={dataProximoContato}
                              className="col-md-4"
                              label="Data prox. contato"
                              name="dataContato"
                              onChange={(e) =>
                                setDataProximoContato(e.target.value)
                              }
                            />
                          </div>
                          <div className="col-md-3 pl-0 ml-0">
                            <input
                              multiple
                              type="file"
                              className="d-none"
                              accept="*.*"
                              ref={inputUploadRef}
                              onChange={(e) => handleUploadAnexos(e)}
                            />
                            <button
                              //disabled={isDisabled}
                              title="anexar arquivos"
                              type="button"
                              //value={""} //dataCadastro}
                              className="col-md-12 mt-3 btn btn-block btn-md btn-outline-warning"
                              //title="Histórico dos contatos"
                              name="anexo"
                              onClick={() => openFileDialog()}
                              //onChange={(e) => setDataCadastro(e.target.value)}
                            >
                              <FiPaperclip className="mr-2" />
                              {arquivos.length === 0
                                ? "Anexar arquivo"
                                : `Anexar ${arquivos.length} arquivo(s)`}
                            </button>
                          </div>
                          <div className="col-md-3">
                            {/* <button
                              //disabled={isDisabled}
                              type="button"
                              //value={""} //dataCadastro}
                              className="col-md-12 mt-3 btn btn-block btn-md btn-outline-secondary"
                              title="Histórico dos contatos"
                              name="historico"
                              onClick={() => onShowAgendaHistorico()}
                              //onChange={(e) => setDataCadastro(e.target.value)}
                            >
                              <FiEye size={24} className="mr-2" />
                              Histórico
                            </button> */}
                            <ButtonContatoHistorico
                              nome={props.nome}
                              loja={props.loja}
                              codigo={props.codigo}
                              origin={props.origin}
                              className="col-md-12 mt-3 btn btn-block btn-md btn-outline-secondary"
                            />
                          </div>
                        </>
                      ) : (
                        <div className="row col-md-6" />
                      )}
                      <div className="col-md-9 mb-4 pl-0 ml-0">
                        <label className="col-md-12 text-left">
                          Cód. Inativo
                        </label>
                        <Select
                          required
                          id="codigoInativo"
                          className="col-md-12"
                          label="Cód. Inativo"
                          name="codigoInativo"
                          options={inatividades}
                          getOptionLabel={(item: any) =>
                            !item?.codigo
                              ? "Selecione..."
                              : `${item.codigo}-${item.descricao}`
                          }
                          getOptionValue={(item) => `${item.codigo}`}
                          onChange={(value: any) => setInatividade(value)}
                          value={inatividade}
                          styles={{
                            option: (provided) => ({
                              ...provided,
                              fontSize: ".9rem !important",
                              textAlign: "left",
                              minWidth: "25px",
                              zIndex: 3,
                            }),
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 0,
                              borderColor: "#eee",
                              //showFormTransportadora === false && transportadoraErrors.nome ? '#dc3545' : '#204d74',
                            }),
                          }}
                        />
                      </div>

                      <div className="col-md-3 mb-4 pl-0 ml-0">
                        <label className="col-md-12 text-left">
                          Tipo contato
                        </label>
                        <Select
                          required
                          id="tipocontato"
                          className="col-md-12"
                          label="Tipo contato"
                          name="tipocontato"
                          options={TIPO_CONTATO}
                          getOptionLabel={(item: any) =>
                            !item?.nome ? "Selecione..." : item.nome
                          }
                          getOptionValue={(item) => `${item.sigla}`}
                          onChange={(value: any) => setTipoContato(value)}
                          value={tipoContato}
                          styles={{
                            option: (provided) => ({
                              ...provided,
                              fontSize: ".9rem !important",
                              textAlign: "left",
                              minWidth: "15px",
                            }),
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 0,
                              borderColor: "#eee",
                              //showFormTransportadora === false && transportadoraErrors.nome ? '#dc3545' : '#204d74',
                            }),
                          }}
                        />
                      </div>

                      {props.origin === "cliente" ||
                      props.origin === "pedido" ? (
                        <>
                          <div className="col-md-9 pl-0 ml-0">
                            <label className="col-md-12 text-left">
                              Concorrentes
                            </label>
                            <Select
                              id="concorrentes"
                              className="col-md-12"
                              label="Concorrentes"
                              name="concorrentes"
                              options={concorrentes}
                              getOptionLabel={(item: any) =>
                                !item?.codigo
                                  ? "00-SELECIONE..."
                                  : `${item.codigo}-${item.nome}`
                              }
                              getOptionValue={(item) => `${item.codigo}`}
                              onChange={(value: any) => setConcorrente(value)}
                              value={concorrente}
                              styles={{
                                option: (provided) => ({
                                  ...provided,
                                  fontSize: ".9rem !important",
                                  textAlign: "left",
                                  minWidth: "25px",
                                  zIndex: 3,
                                }),
                                control: (provided) => ({
                                  ...provided,
                                  borderRadius: 0,
                                  borderColor: "#eee",
                                  //showFormTransportadora === false && transportadoraErrors.nome ? '#dc3545' : '#204d74',
                                }),
                              }}
                            />
                          </div>

                          <div className="col-md-3 pl-0 ml-0">
                            <label className="col-md-12 text-left">Valor</label>
                            <Input
                              className="ml-3"
                              name="valor"
                              value={valor}
                              disabled={concorrente?.codigo === undefined}
                              //required={concorrente?.codigo !== undefined}
                              placeholder={"0.000,00"}
                              onChange={(e) =>
                                setValor(formatValor(e.target.value))
                              }
                            />
                          </div>

                          <div
                            className="col-md-6 mt-1 pl-0 ml-0"
                            style={{
                              maxHeight: "10vh",
                              height: "10vh",
                            }}
                          >
                            <label className="col-md-12 text-left">Grupo</label>
                            <Select
                              id="grupo"
                              className="col-md-12 mt-2"
                              label="Grupo"
                              name="Grupo"
                              options={grupos}
                              getOptionLabel={(item: any) =>
                                !item?.grupo && props.origin !== "pedido"
                                  ? "000-SELECIONE..."
                                  : `${item.grupo}-${item.descri_grupo}`
                              }
                              getOptionValue={(item) => `${item.grupo}`}
                              onChange={(value: any) => handleSetGrupo(value)}
                              value={grupo}
                              styles={{
                                option: (provided) => ({
                                  ...provided,
                                  fontSize: ".9rem !important",
                                  textAlign: "left",
                                  minWidth: "15px",
                                }),
                                control: (provided) => ({
                                  ...provided,
                                  borderRadius: 0,
                                  borderColor: "#eee",
                                  maxHeight: "5vh",
                                  height: "5vh",
                                  //showFormTransportadora === false && transportadoraErrors.nome ? '#dc3545' : '#204d74',
                                }),
                              }}
                            />
                          </div>

                          <div
                            className="col-md-6 m-0 p-0 mt-1"
                            style={{
                              maxHeight: "10vh",
                              height: "10vh",
                            }}
                          >
                            <label className="col-sm-12 text-left ml-0 p-0 m-0 row">
                              <span className="col-sx-6 col-sm-6 pl-0 ml-0">
                                Produto
                                {produtos.length > 0 && (
                                  <span className="ml-0">
                                    (s) <strong>{produtos.length}</strong>{" "}
                                  </span>
                                )}
                              </span>
                              <button
                                type="button"
                                disabled={
                                  state.produtos.length === 0 ||
                                  state.searchTextProduto.length < 3
                                }
                                className="col-sx-3 col-sm-3 btn btn-sm btn-primary text-center"
                                style={{ marginTop: ".6vh" }}
                                title="Marcar todos os produtos"
                                onClick={() => setProdutos(state.produtos)}
                              >
                                <FiCheckCircle size={18} className="pr-1" />
                                <small>Marcar</small>
                              </button>
                              <button
                                type="button"
                                disabled={
                                  produtos.length === 0 ||
                                  state.searchTextProduto.length < 3
                                }
                                className="col-sx-3 col-sm-3 btn btn-sm btn-danger mr-0 pr-1 text-center"
                                style={{ marginTop: ".6vh" }}
                                title="Desmarcar todos os produtos"
                                onClick={() => setProdutos([])}
                              >
                                <FiXCircle size={18} className="pr-1" />
                                <small>Desmarcar</small>
                              </button>
                            </label>

                            <form onReset={() => {}} key={"form-produtos"}>
                              <div
                                className="input-group mb-3"
                                style={{ paddingTop: ".1vh" }}
                              >
                                <input
                                  type="text"
                                  ref={inputRefProduto}
                                  disabled={inputProdutoIsDisabled}
                                  autoFocus
                                  className="form-control form-control-lg"
                                  placeholder="Digite o código ou nome do produto"
                                  value={state.searchTextProduto}
                                  onChange={(e) =>
                                    setState({
                                      ...state,
                                      searchTextProduto: e.target.value,
                                    })
                                  }
                                />
                                <div className="input-group-append">
                                  <button
                                    type="button"
                                    disabled={
                                      state.searchTextProduto.length < 4
                                    }
                                    title={`${
                                      state?.searchTextProduto?.length > 3
                                        ? "Click para realizar busca do produto"
                                        : "Digite mais de 3 caracteres"
                                    } `}
                                    onClick={handleSearchProdutos}
                                    className="btn btn-lg btn-primary"
                                    style={{ zIndex: 0 }}
                                  >
                                    <FiSearch />
                                  </button>
                                  <button
                                    type="button"
                                    disabled={produtos.length === 0}
                                    className="btn btn-lg btn-outline-primary"
                                    title="Adicionar produtos selecionados"
                                    onClick={handleResetProdutos}
                                  >
                                    <FiPlusSquare />
                                  </button>
                                </div>
                              </div>
                            </form>

                            <div
                              style={{
                                backgroundColor: "#fff",
                                position: "relative",
                                zIndex: 10000,
                                maxHeight: "23vh",
                                overflow: "scroll",
                              }}
                            >
                              {state.produtos.map((produto) => (
                                <div className="d-flex flex-fill align-items-center border-right border-primary p-2 pl-4">
                                  <input
                                    key={produto.id}
                                    type="checkbox"
                                    style={{
                                      minHeight: "20px",
                                      width: "20px",
                                      minWidth: "20px",
                                      height: "20px",
                                      /*marginTop: `${isVisible ? '50px' : '0'}`, //JL-HF_06-2022*/
                                    }}
                                    checked={produtos.find(
                                      (i) => i?.codigo === produto.codigo
                                    )}
                                    className="btn btn-light btn-block d-flex flex-row align-items-center justify-content-between mb-3"
                                    onClick={() =>
                                      //(state.searchTextProduto = produto.codigo),

                                      handleSelect(produto)
                                    }
                                  />
                                  <div className="d-flex align-items-center pl-4">
                                    <img
                                      src={`https://b2b.ferrarinet.com.br/assets/${produto.codigo}.jpg`}
                                      width="80"
                                      alt=""
                                    />
                                  </div>
                                  <div className="flex-grow-1 p-2 text-left">
                                    <h6 className="card-title mb-1 text-primary">
                                      {produto.titulo}
                                    </h6>
                                    <div>
                                      <small className="text-muted">
                                        Modelo: {produto.modelo}
                                      </small>
                                    </div>
                                    <div>
                                      <small className="text-muted">
                                        SKU: {produto.codigo}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>

                            {state.message && (
                              <div
                                style={{
                                  backgroundColor: "#fff",
                                  position: "absolute",
                                  maxWidth: "23vw",
                                  width: "23vw",
                                  zIndex: 1000,
                                }}
                                className="alert alert-warning text-center"
                                dangerouslySetInnerHTML={{
                                  __html: state.message,
                                }}
                              ></div>
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="col-md-12 mt-4 ">
                        <label className="col-md-12 text-left p-0">
                          Observação
                        </label>
                        <textarea
                          style={{ maxHeight: "10vh", minHeight: "10vh" }}
                          //disabled={isDisabled}
                          required
                          placeholder={
                            props.origin !== "pedido"
                              ? "Digite informações referente ao contato"
                              : "Digite informações, justificativas, comentários, sugestões refentes ao motivo de não compra grupo de produto ou produtos"
                          }
                          className="col-md-12 p-2"
                          name="observacao"
                          value={observacao}
                          onChange={(e) => setObservacao(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 d-flex align-items-center justify-content-end">
                <button
                  //disabled={isDisabled}
                  type="submit"
                  //value={""} //dataCadastro}
                  className="col-md-6 ml-6 mt-4 btn btn-block btn-md btn-outline-primary"
                  title="Confirmar agendamento"
                  name="agendamento"
                  //onChange={(e) => setDataCadastro(e.target.value)}
                >
                  <FiSave size={24} className="mr-2" />
                  Salvar
                </button>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-start">
                <button
                  //disabled={isDisabled}
                  type="button"
                  //value={""} //dataCadastro}
                  className="col-md-6 ml-6 mt-4 btn btn-block btn-md btn-outline-danger"
                  title="Confirmar agendamento"
                  name="agendamento"
                  onClick={() => ReactSwal.close()}
                  //onChange={(e) => setDataCadastro(e.target.value)}
                >
                  <FiX size={24} className="mr-2" />
                  Cancelar
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default RegistroContatoForm;
