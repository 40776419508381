import { StatusPedidoProps } from "../typings";

export const StatusPedidos: StatusPedidoProps[] = [
  {
    id: 1,
    status: "Ativo",
  },
  {
    id: 2,
    status: "Cancelado",
  },
];
