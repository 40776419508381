import React from "react";
import { FiUser } from "react-icons/fi";
import { NavLink } from "react-router-dom";

import { useAppContext } from "../../contexts/app";
import { IdTipoUsuario } from "../../entities/TipoUsuario";
//import GraphicMetaClient from '../../components/HeaderCliente/GraphicMeta';

const HeaderCliente = () => {
  const { user } = useAppContext();

  return (
    <>
      <div className="container-fluid my-4">
        <div className="alert alert-secondary m-0 p-4 d-block d-md-flex align-items-center justify-content-between">
          <h5 className="mb-4 mb-md-0">
            OLÁ, {user?.revendedor?.nome}
            {/* <br />
            <span className="pt-2 text-secondary">
              <small>PERFIL: {user?.tipo?.nome}</small>
            </span> */}
          </h5>
          {(IdTipoUsuario.AGENTE === user?.tipo?.id ||
            IdTipoUsuario.REPRESENTANTE === user?.tipo?.id) && (
            <NavLink
              to="/minhaconta"
              className="btn btn-primary d-block d-md-flex align-items-center"
            >
              <FiUser className="mr-2" />
              Acessar Conta do Cliente
            </NavLink>
          )}
        </div>
      </div>
      {/*
      <GraphicMetaClient />
      */}
    </>
  );
};

export default HeaderCliente;
