import React, { FormEvent, useEffect, useState } from 'react';

import { createMotivoSaida } from '../../services/api';
import Input from '../Input';

const motivos = [
  'Estou apenas pesquisando/navegando.',
  'O produto está muito caro.',
  'Informações do produto pouco detalhadas.',
  'Frete muito caro.',
  'Entrega/retirada não disponível para a minha região.',
  'Prazo de entrega muito demorado.',
  'Falha técnica do site.',
  'Outro motivo.',
];

interface ModalSaidaProps {
  onStay(): void;
  onLeave(): void;
}

const ModalSaida: React.FC<ModalSaidaProps> = ({ onStay = () => {}, onLeave = () => {} }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [descricaoMotivo, setDescricaoMotivo] = useState('');

  useEffect(() => {
    setDescricaoMotivo('');
  }, [selectedOption]);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      const motivo = descricaoMotivo || selectedOption || 'Não informado.';

      await createMotivoSaida({ motivo });

      onLeave();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="my-4 text-center">
          Você ainda não confirmou o pedido. Algumas ofertas/promoções são por tempo limitado!
        </div>

        <div className="alert alert-info">
          <div>
            <h5>Nos ajude a entender o motivo da sua saída:</h5>
          </div>

          {motivos.map((item) => (
            <div className="form-check text-left mb-2" key={item}>
              <label className="form-check-label d-flex align-items-center">
                <input
                  type="radio"
                  className="form-check-input mt-0"
                  name="motivo"
                  value={item}
                  checked={item === selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                />
                {item}
              </label>
            </div>
          ))}

          {selectedOption === 'Outro motivo.' && (
            <div className="mt-4">
              <Input
                label="Informe o motivo"
                name="motivo"
                autoFocus
                value={descricaoMotivo}
                onChange={(e) => setDescricaoMotivo(e.target.value)}
              />
            </div>
          )}
        </div>

        <div className="d-flex align-items-center justify-content-between mt-4">
          <button type="submit" className="btn btn-lg btn-outline-secondary mr-2">
            Quero sair
          </button>

          <button type="button" className="btn btn-lg btn-success ml-2" onClick={() => onStay()}>
            Quero aproveitar as ofertas!
          </button>
        </div>
      </form>
    </div>
  );
};

export default ModalSaida;
