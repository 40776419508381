import React from "react";
import { BsCircleFill } from "react-icons/bs";

//import { legendasAgenda } from "../../utils/legendasData";

const Legendas = ({ legendas }: any) => {
  //const legendas: any = legendasAgenda;
  const tipos = [];
  for (var item in legendas) {
    tipos.push(item);
  }

  return (
    <div className="col-md-12 text-left p-0 m-0 mt-4">
      {tipos.map((tipo: string) => (
        <li className="text-left" style={{ listStyleType: "none" }}>
          <ul>
            <BsCircleFill size={16} color={legendas[tipo].color} />{" "}
            <small className="ml-2">{legendas[tipo].legenda}</small>
          </ul>
        </li>
      ))}
      <br />
      <hr />
    </div>
  );
};

export default Legendas;
