import React from "react";

import Input from "../../../components/Input";
import SideBar from "../SideBar";
import { useAppContext } from "../../../contexts/app";
import { differenceInDays } from "date-fns";

const DadosCadastrais = () => {
  const { user } = useAppContext();

  console.log(">>DADOS CADASTRAIS");
  console.log(user);

  //JL-HF 2023-11
  function calculaQtdDiasInatividade(date: string) {
    const dataUltimoPedido = new Date(date);
    const qtdDias = differenceInDays(new Date(), dataUltimoPedido);
    return qtdDias;
  }

  return (
    <div className="container-fluid my-4 pb-4">
      <div className="row">
        <div className="col-md-3">
          <SideBar />
        </div>
        <div className="col-md-9 mt-4 mt-md-0">
          <h3>Meus dados cadastrais</h3>

          <hr />

          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <strong className="text-secondary">Dados Básicos</strong>
                </div>
                <div className="card-body">
                  <Input
                    disabled
                    label="Razão Social"
                    name="razaoSocial"
                    value={user?.revendedor?.nome}
                  />
                  <Input
                    disabled
                    label="Nome Fantasia"
                    name="nomeFantasia"
                    value={user?.revendedor?.nfantasia}
                  />
                  <Input
                    disabled
                    label="CNPJ"
                    name="cnpj"
                    value={user?.revendedor?.cnpj}
                  />
                  <Input
                    disabled
                    label="Inscrição Estadual"
                    name="inscricaoEstadual"
                    value={user?.revendedor?.inscricaoEstadual}
                  />
                  <Input
                    disabled
                    label="Inscrição Municipal"
                    name="inscricaoMunicial"
                    value={user?.revendedor?.inscricaoMunicipal}
                  />
                  <Input
                    disabled
                    label="Código SUFRAMA"
                    name="codigoSuframa"
                    value={user?.revendedor?.codigoSuframa}
                  />
                  {/* <Input
                    disabled
                    label="Telefone"
                    name="telefone"
                    value={`(${user?.revendedor?.ddd}) ${user?.revendedor?.tel}`}
                  /> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-4 mt-md-0">
              <div className="card">
                <div className="card-header">
                  <strong className="text-secondary">
                    Endereço da Empresa
                  </strong>
                </div>
                <div className="card-body">
                  <Input
                    disabled
                    label="CEP"
                    name="cep"
                    value={user?.revendedor?.cep}
                  />
                  <Input
                    disabled
                    label="Logradouro"
                    name="logradouro"
                    value={user?.revendedor?.endereco}
                  />
                  {/* <Input disabled label="Número" name="numero" value={''} />  JL-HF_2022-06_Retirado solicitado por Douglas Chagas*/}
                  <Input
                    disabled
                    label="Complemento"
                    name="complemento"
                    value={user?.revendedor?.complemento}
                  />
                  <Input
                    disabled
                    label="Bairro"
                    name="bairro"
                    value={user?.revendedor?.bairro}
                  />
                  <Input
                    disabled
                    label="Cidade"
                    name="cidade"
                    value={user?.revendedor?.municipio}
                  />
                  <Input
                    disabled
                    label="UF"
                    name="uf"
                    value={user?.revendedor?.uf}
                  />
                  {/* <Input disabled label="E-mail para NFe" name="email" value={user?.revendedor?.email} /> */}
                </div>
              </div>
            </div>
          </div>

          {/* JL-HF 2023-12 v2.0.1c| Informações complementares Representante/Agente*/}
          {user?.isRepresentante && (
            <>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <strong className="text-secondary">
                        Informações Complementares
                      </strong>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <Input
                        disabled
                        className="col-md-5"
                        label="Qtd. Dias Inatividade"
                        name="qtdDiasInatividade"
                        value={
                          user?.revendedor?.dataUltimoPedido
                            ? calculaQtdDiasInatividade(
                                user?.revendedor?.dataUltimoPedido
                              )
                            : "--"
                        }
                      />
                      <div
                        className="col-md-12 row"
                        style={{ margin: 0, padding: 0 }}
                      >
                        <div
                          className="col-md-5 mr-5 row"
                          style={{ margin: 0, padding: 0 }}
                        >
                          <Input
                            disabled
                            label="Código Representante"
                            name="codRepresentante"
                            value={user?.revendedor?.cod_representante}
                          />
                        </div>
                        <div
                          className="col-md-5 ml-3 row"
                          style={{ margin: 0, padding: 0 }}
                        >
                          <Input
                            disabled
                            label="Código Agente"
                            name="agente"
                            value={user?.revendedor?.cod_agente}
                          />
                        </div>
                      </div>
                      <Input
                        disabled
                        label="Gestor"
                        name="representante"
                        placeholder="Código e nome do gestor"
                        value={user?.revendedor?.codigoSupervisor}
                      />

                      <Input
                        disabled
                        label="Contato"
                        name="contato"
                        placeholder="Nome do contato"
                        value={user?.revendedor?.contato}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-4 mt-md-0">
                  <div className="card">
                    <div className="card-body">
                      <Input
                        disabled
                        label="Classificação"
                        name="classificacao"
                        value={`${user?.revendedor?.classificacao}-${user?.revendedor.classificacaoDescricao}`}
                      />
                      <Input
                        disabled
                        label="Grupo Venda"
                        name="grupoVenda"
                        value={user?.revendedor?.grupo_vendas}
                      />
                      <Input
                        disabled
                        label="Loja"
                        name="loja"
                        value={`${user?.revendedor?.loja}-${user?.revendedor?.tploja}`}
                      />

                      <Input
                        disabled
                        label="Site"
                        name="site"
                        placeholder="www.sitecliente.com.br"
                        value={user?.revendedor?.siteCliente}
                      />

                      {/* <Input disabled label="E-mail para NFe" name="email" value={user?.revendedor?.email} /> */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* JL-HF 2023-06 v2.0.1| Agrupando e-mails e telefones*/}
          <div className="row">
            <div className="col-md-12 mt-4 mt-md-0">
              <div className="card">
                <div className="card-header">
                  <strong className="text-secondary">Telefones</strong>
                </div>
                <div className="card-body">
                  <Input
                    disabled
                    label="Telefone"
                    name="telefone"
                    mask="(99) 9999-9999"
                    value={`${user?.revendedor?.ddd} ${user?.revendedor?.tel}`}
                  />
                </div>

                <div className="card-body">
                  <Input
                    disabled
                    placeholder="(99) 99999-9999"
                    label="Celular"
                    name="celular"
                    mask="(99) 99999-9999"
                    value={user?.revendedor?.celularCliente}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-4 mt-md-0">
              <div className="card">
                <div className="card-header">
                  <strong className="text-secondary">E-mails</strong>
                </div>
                <div className="card-body">
                  <Input
                    disabled
                    label="NFe"
                    name="email"
                    placeholder="email NFe"
                    value={user?.revendedor?.email}
                  />
                  <Input
                    disabled
                    label="Vendas"
                    name="enderecoEntregaEmail"
                    placeholder="email de vendas"
                    value={user?.revendedor?.email_vendas}
                  />
                  <Input
                    disabled
                    label="Financeiro"
                    placeholder="email financeiro"
                    name="enderecoFinanceiroEmail"
                    value={user?.revendedor?.emailFi}
                  />
                  <Input
                    disabled
                    label="Cobrança"
                    name="enderecoCobrancaEmail"
                    placeholder="email de cobrança"
                    value={user?.revendedor?.email_cobranca}
                  />
                  <Input
                    disabled
                    label="Compras"
                    name="enderecoComprasEmail"
                    placeholder="email de compras"
                    value={user?.revendedor?.emailCo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DadosCadastrais;
