import React, { useState, FormEvent } from 'react';
import InputMask from 'react-input-mask';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import { askPasswordChange } from '../../services/api';
import { useAppContext } from '../../contexts/app';

const FormSolicitacaoSenha: React.FC = () => {
  const [state, setState] = useState({ cnpj: '', email: '', loading: false, errorMessage: '' });
  const { showLoader, hideLoader } = useAppContext();

  const history = useHistory();

  async function handleFormSubmit(e: FormEvent) {
    e.preventDefault();

    const loader = showLoader('Processando solicitação.');

    try {
      setState((v) => ({ ...v, loading: true, errorMessage: '' }));

      await askPasswordChange({ cnpj: state.cnpj, email: state.email });

      hideLoader(loader);

      Swal.fire({
        title: 'Solicitação enviada com sucesso!',
        html: `<div>
                        <p>Em breve você receberá um e-mail com instruções para recuperar sua senha de acesso.</p>
                    </div>`,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'OK',
        onClose: () => {
          history.replace('/login');
        },
      });
    } catch (error) {
      if (error?.response?.data?.message) {
        hideLoader(loader);

        setState((v) => ({ ...v, errorMessage: error.response.data.message }));

        setTimeout(() => {
          setState((v) => ({ ...v, errorMessage: '' }));
        }, 5000);
      }
    } finally {
      setState((v) => ({ ...v, loading: false }));
    }
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <h2 className="text-center text-primary">Recuperar Senha</h2>
      <p className="text-center">Informe seu CNPJ e e-mail para continuar.</p>

      {state.errorMessage && <div className="alert alert-danger">{state.errorMessage}</div>}

      <fieldset disabled={state.loading}>
        <div className="form-group">
          <label htmlFor="cnpj" className="d-block text-left">
            CNPJ
          </label>
          <InputMask
            type="text"
            id="cnpj"
            name="cnpj"
            className="form-control form-control-lg"
            mask="99.999.999/9999-99"
            value={state.cnpj}
            onChange={(e: any) => setState({ ...state, cnpj: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label htmlFor="email" className="d-block text-left">
            E-mail
          </label>
          <input
            type="text"
            id="email"
            name="email"
            className="form-control form-control-lg"
            value={state.email}
            onChange={(e) => setState({ ...state, email: e.target.value })}
          />
        </div>

        <button type="submit" className="btn btn-block btn-primary btn-lg mt-5">
          SOLICITAR NOVA SENHA
        </button>
      </fieldset>
    </form>
  );
};

export default FormSolicitacaoSenha;
