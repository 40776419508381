import React, { FormEvent, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import Input from "../../../components/Input";
import { FiEdit, FiSave } from "react-icons/fi";
import { useAppContext } from "../../../contexts/app";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";
import {
  fetchProspectCodigo,
  sendCreateProspect,
  sendUpdateProspect,
} from "../../../services/api";
import ButtonExcluirPropspect from "../Excluir";
import ButtonEfetivarPropspect from "../Efetivar";
import { ProspectProps, acaoFormProspectProps } from "../../../typings";
import SideBarProspect from "../../MinhaConta/SideBarProspect";
import { TipoUsuario } from "../../../entities/TipoUsuario";
import { acaoFormProspect } from "../../../entities/actionForm";
import { format } from "date-fns";

const action: any = {
  cadastro: sendCreateProspect,
  edita: sendUpdateProspect,
};

const OPCAO = {
  sim: 1,
  nao: 2,
};

const PESSOA = {
  juridica: 1, //"J",
  fisica: 2, //"F"
};

interface optionsProps {
  id: number;
  str: string;
  sigla?: string;
}

const options = [
  {},
  {
    id: OPCAO.sim,
    str: "SIM",
    sigla: "S",
  },
  {
    id: OPCAO.nao,
    str: "NÃO",
    sigla: "N",
  },
] as optionsProps[];

const optionsTipo = [
  {},
  {
    id: PESSOA.juridica,
    str: "Jurídica",
    sigla: "J",
  },
  {
    id: PESSOA.fisica,
    str: "Física",
    sigla: "F",
  },
] as optionsProps[];

const TAMANHOS = [
  {
    id: 0,
    str: "Selecione...",
    sigla: "",
  },
  {
    id: 1,
    str: "Pequena",
    sigla: "P",
  },
  {
    id: 2,
    str: "Média",
    sigla: "M",
  },
  {
    id: 2,
    str: "Grande",
    sigla: "G",
  },
];

const EMPRESAS = [
  {
    id: 0,
    str: "Selecione...",
  },
  {
    id: 1,
    str: "TECWAY",
    sigla: "T",
  },
  {
    id: 2,
    str: "JASON",
    sigla: "J",
  },
  {
    id: 3,
    str: "REXON",
    sigla: "R",
  },
  {
    id: 4,
    str: "METALMATRIX",
    sigla: "M",
  },
  {
    id: 3,
    str: "REAT.REXON",
    sigla: "A",
  },
];

const ORIGIN = [
  {
    id: "0",
    str: "Selecione...",
  },
  {
    id: "1",
    str: "Via Ommichat",
  },
  {
    id: "2",
    str: "Cadastro Formulário do Site",
  },
  {
    id: "3",
    str: "Prospecção do Vendedor",
  },
  {
    id: "4",
    str: "Nova Loja",
  },
  {
    id: "5",
    str: "Via Atendimento",
  },
  {
    id: "6",
    str: "Tradaq",
  },
];

const Cadastro: React.FC = () => {
  const history = useHistory();
  const { state } = useLocation<{
    codigo: string | "";
    loja: string | "00";
    acao?: acaoFormProspectProps | undefined;
  }>();

  const isDisabled =
    state.acao !== acaoFormProspect.cadastro &&
    state.acao !== acaoFormProspect.editar &&
    parseInt(state?.codigo) > 0;

  const isDisabledButtons =
    isDisabled || state.acao === acaoFormProspect.cadastro;

  const [codigo, setCodigo] = useState("");
  const [tipo, setTipo] = useState<optionsProps>({} as optionsProps);
  const [simples, setSimples] = useState<optionsProps>({} as optionsProps);
  const [conhece, setConhece] = useState<optionsProps>({} as optionsProps);
  const [bloqueado, setBloqueado] = useState<optionsProps>({} as optionsProps);
  const [nome, setNome] = useState("");
  const [loja, setLoja] = useState("");
  const [dataCadastro, setDataCadastro] = useState("");
  const [dataProximoContato, setDataProximoContato] = useState("");
  const [codigoSupervisor, setCodigoSupervisor] = useState("");
  const [responsavel, setResponsavel] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [bairro, setBairro] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [uf, setUf] = useState("");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  const [emailNFe, setEmailNFe] = useState("");
  const [emailVenda, setEmailVenda] = useState("");
  const [tamanho, setTamanho] = useState({} as any);
  const [empresa, setEmpresa] = useState({} as any);
  const [origin, setOrigin] = useState({} as any);
  const [contato, setContato] = useState("");
  const { showLoader, hideLoader, user } = useAppContext();

  const tomorrow = useMemo(() => {
    const d = new Date();
    const day = d.getDate(); //+ 1;
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    const formatedDay = day < 10 ? `0${day}` : day;
    const formatedMonth = month < 10 ? `0${month}` : month;

    return `${year}-${formatedMonth}-${formatedDay}`;
  }, []);

  const formatDate = (date: string) => {
    const d = new Date(date);
    const day = d.getDate() + 1;
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    const formatedDay = day < 10 ? `0${day}` : day;
    const formatedMonth = month < 10 ? `0${month}` : month;

    return `${year}-${formatedMonth}-${formatedDay}`;
  };

  function preencheDataForm(item: ProspectProps) {
    setTipo(optionsTipo[item.pessoa === "J" ? 1 : 2]);
    setSimples(options[item.simplesNacional === "N" ? 2 : 1]);
    setBloqueado(parseInt(item.bloqueado) === 1 ? options[1] : options[2]);
    setConhece(options[item.conhece === "N" ? 2 : 1]);

    setCodigo(state?.codigo ? state.codigo : "");
    setNome(item.nome);
    setLoja(item.loja);
    setDataCadastro(
      !item.dataCadastro ||
        item.dataCadastro === "" ||
        !new Date(item.dataCadastro)
        ? tomorrow
        : format(new Date(item.dataCadastro), "yyyy-MM-dd")
    );
    setDataProximoContato(
      !item.dataProximoContato ||
        item.dataProximoContato === "" ||
        !new Date(item.dataProximoContato)
        ? tomorrow
        : format(new Date(item.dataProximoContato), "yyyy-MM-dd")
    );
    setCodigoSupervisor(
      !item.codigoSupervisor || item.codigoSupervisor.length === 0
        ? user!.codsuper
        : item?.codigoSupervisor
    );
    setResponsavel(
      !item.codigoRepresentante || item.codigoRepresentante.length === 0
        ? user!.codigo
        : item.codigoRepresentante
    );
    setNomeFantasia(item.nomeFantasiaAbreviado);
    setCnpj(item.cnpj);
    setInscricaoEstadual(item.inscricaoEstadual);
    setCep(item.cep);
    setEndereco(item.endereco);
    setBairro(item.bairro);
    setMunicipio(item.municipio);
    setUf(item.uf);
    setTelefone(item?.telefone ? `${item.ddd}${item.telefone}` : "");
    setCelular(item?.telefoneFax ? `${item.ddd}${item.telefoneFax}` : "");
    setContato(item.contato);
    setEmailNFe(item.emailNFe);
    setEmailVenda(item.emailVenda);
    //setAtivo(item.ativo ? STATUS.ativo : STATUS.inativo);
    //setEfetivarCliente(parseInt(item.bloqueado));
    setOrigin(ORIGIN[0]);
    setTamanho(TAMANHOS[0]);
    setEmpresa(EMPRESAS[0]);
  }

  async function carregaDadosProspect() {
    const loader = showLoader(
      "Por favor aguarde. carregando cadastro prospect."
    );
    //setCodigo(state?.codigo ? state.codigo : "");
    const response: ProspectProps = await fetchProspectCodigo({
      codigo: state?.codigo,
      loja: state?.codigo,
    });

    if (parseInt(response?.codigo) === parseInt(state.codigo)) {
      //await setProspect(response);
      await preencheDataForm(response);

      hideLoader(loader);

      return;
    }
  }

  /*const dataNewProspect = (): ProspectProps => {
    const newProspect = {
      codigo: "", //qtdPrefixo,
      loja: "",
      nome: "B2B - Cadastro prospec",
      pessoa: "J", //PESSOA.juridica,
      nomeFantasiaAbreviado: "B2B-01",
      endereco: "Endereço testes cadsastro, 777",
      cep: "02122187",
      simplesNacional: "N", //OPCAO.nao,
      uf: "SP",
      municipio: "são paulo",
      bairro: "jardins",
      ddd: "11",
      telefone: "42422222",
      telefoneFax: "988775577",
      contato: "Douglas",
      cnpj: "21222555000107",
      inscricaoEstadual: "isento",
      emailNFe: "teste@empresa.com.br",
      emailVenda: "teste@contato.com.br",
      conhece: "S", //OPCAO.sim,
      codigoRepresentante: user?.codigo ? user?.codigo : "",
      tamanho: TAMANHOS[1].sigla,
      codigoCliente: "",
      lojaCliente: "",
      bloqueado: "2", //OPCAO.nao,
      codigoSupervisor: user?.codsuper ? user?.codsuper : "",
      originCliente: String(ORIGIN[1].id),
      dataCadastro: format(new Date(), "yyyy-MM-dd"),
      dataProximoContato: format(new Date(), "yyyy-MM-dd"),
      ativo: false, //STATUS.ativo,
      efetivarCliente: "N", //OPCAO.nao,Tamanho
      legenda: {
        color: "",
        legenda: "",
      },
    } as ProspectProps;

    return newProspect;
  };*/

  async function handleNovoCadastro() {
    try {
      const loader = showLoader(
        "Por favor aguarde. Para cadastrar novo prospect."
      );
      state.acao = acaoFormProspect.cadastro;
      state.codigo = "";
      state.loja = "";

      /*prospect.codigo = "";
      prospect.nome = "";
      await setProspect({} as ProspectProps);*/

      await setTipo(optionsTipo[1]);
      await setSimples(options[0]);
      await setBloqueado(options[2]);
      await setConhece(options[0]);
      await setOrigin(ORIGIN[1]);
      await setTamanho(TAMANHOS[1]);
      await setEmpresa(EMPRESAS[1]);

      await setCodigo("");
      await setLoja("");
      await setNome("");
      await setDataCadastro(tomorrow);
      await setDataProximoContato(tomorrow);
      await setNomeFantasia("");
      await setCnpj("");
      await setInscricaoEstadual("");
      await setCep("");
      await setEndereco("");
      await setBairro("");
      await setMunicipio("");
      await setUf("");
      await setTelefone("");
      await setCelular("");
      await setEmailNFe("");
      await setEmailVenda("");
      await setContato("");

      await preencheDataForm({} as ProspectProps); // dataNewProspect());

      await setTimeout(async () => {
        hideLoader(loader);
      }, 600);
    } catch {
      throw new Error("Erro ao limpar dados o formulário");
    }
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      const loader = showLoader("Por favor aguarde. Salvando cadastro .");
      const formEvent = e.target as any;

      let items: any = {};
      for await (var item of formEvent) {
        //if (item.name.length > 0 && item.name != "codigo") {
        items[`${item.name}`] = item.value;
        //}

        /*if (item.name.length > 0 && item.name == "codigo") {
          items[`${item.name}`] = prospect.codigo;
        }*/
      }

      const executar = action[String(state?.acao)];

      await executar({
        ...items,
        bloqueado: bloqueado.sigla === "N" ? OPCAO.nao : OPCAO.sim,
        codigoRepresentante: `${
          TipoUsuario[user!.tipo!.nome.toLowerCase()].perfil
        }${user?.codigo}`,
      });

      //carregaDadosLocal();

      setTimeout(() => {
        hideLoader(loader);

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: `${
            state.acao === "cadastro" ? "Cadastro" : "Atualização"
          } prospect realizado!`,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
          onClose: () => {
            history.push("/prospects");
          },
        });
      }, 600);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Erro",
        text:
          "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });

      console.log(error);
    }
  }

  useEffect(() => {
    if (state?.codigo) {
      carregaDadosProspect();

      //preecheDataForm(prospect);
      return;
    }
    preencheDataForm({} as ProspectProps);
  }, []);

  return (
    <div className="container-fluid my-4 pb-4">
      <div className="row">
        <div className="col-md-3">
          <SideBarProspect handleNovoCadastro={handleNovoCadastro} />
        </div>
        <div className="col-md-9 mt-4 mt-md-0">
          <h3>Cadastro Prospect</h3>

          <hr />

          <form
            onSubmit={(e) => handleSubmit(e)}
            //onReset={() => onCancel()}
            style={{ position: "relative" }}
          >
            <div className="row">
              <div className="col-md-5">
                <Input
                  disabled
                  placeholder="000000"
                  className="col-md-4"
                  label="Código Propect"
                  name="codigo"
                  value={codigo}
                />
              </div>
              <div className="col-md-1">
                <Input
                  disabled={isDisabled}
                  className="col-md-12"
                  placeholder="00"
                  label="Loja"
                  name="loja"
                  value={loja}
                  onChange={(e) => setLoja(e.target.value)}
                />
              </div>

              <div className="col-md-3 mt-4 mt-md-0">
                <Input
                  disabled={true}
                  className="col-md-12"
                  placeholder="000000"
                  label="Responsável"
                  name="codigoRepresentante"
                  value={responsavel}
                  onChange={(e) => setResponsavel(e.target.value)}
                />
              </div>

              <div className="col-md-3 mt-4 mt-md-0">
                <Input
                  disabled={true}
                  className="col-md-12"
                  placeholder="000000"
                  label="Cod. Supervisor"
                  name="codigoSupervisor"
                  value={codigoSupervisor}
                  onChange={(e) => setCodigoSupervisor(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mt-4 mt-md-0">
                <div className="card">
                  <div className="card-header">
                    <strong className="text-secondary">Dados Prospect</strong>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-3">
                        <Input
                          required
                          disabled={isDisabled}
                          type="date"
                          //min={tomorrow}
                          value={dataCadastro}
                          className="col-md-12"
                          label="Data Cadastro"
                          name="dataCadastro"
                          onChange={(e) =>
                            setDataCadastro(formatDate(e.target.value))
                          }
                        />
                      </div>

                      <div className="col-md-3 m-0 pr-0 mt-4 mt-md-0 ">
                        <label className="col-md-12">Tipo pessoa</label>
                        <Select
                          id="tipo"
                          className="col-md-12"
                          label="Tipo pessoa"
                          name="pessoa"
                          options={!isDisabled ? optionsTipo : []}
                          getOptionLabel={(item) => item.str}
                          getOptionValue={(item) => `${item.sigla}`}
                          onChange={(value) =>
                            isDisabled ?? setTipo(value as optionsProps)
                          }
                          value={tipo}
                          styles={{
                            option: (provided) => ({
                              ...provided,
                              fontSize: ".9rem !important",
                              textAlign: "left",
                              minWidth: "50px",
                            }),
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 0,
                              /*borderColor: "#eee",*/
                              border: "1px solid var(--color-azul-600)",
                              backgroundColor: isDisabled
                                ? "var(--color-cinza-600)"
                                : "",
                              //showFormTransportadora === false && transportadoraErrors.nome ? '#dc3545' : '#204d74',
                            }),
                          }}
                        />
                      </div>
                      <div className="col-md-2 m-0 p-0 mt-4 mt-md-0">
                        <label className="col-md-12">Simples</label>
                        <Select
                          id="simples"
                          disabled={isDisabled}
                          className="col-md-12"
                          label="Simples"
                          name="simplesNacional"
                          getOptionLabel={(item) => item.str}
                          getOptionValue={(item) => `${item.sigla}`}
                          options={!isDisabled ? options : []}
                          onChange={(value) =>
                            setSimples(value as optionsProps)
                          }
                          value={simples}
                          styles={{
                            option: (provided) => ({
                              ...provided,
                              fontSize: ".9rem !important",
                              textAlign: "left",
                              minWidth: "50px",
                            }),
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 0,
                              border: "1px solid var(--color-azul-600)",
                              backgroundColor: isDisabled
                                ? "var(--color-cinza-600)"
                                : "",

                              //showFormTransportadora === false && transportadoraErrors.nome ? '#dc3545' : '#204d74',
                            }),
                          }}
                        />
                      </div>
                      <div className="col-md-2 m-0 p-0 mt-4 mt-md-0">
                        <label className="col-md-12">Conhece Ferrari?</label>
                        <Select
                          id="conhece"
                          disabled={isDisabled}
                          className="col-md-12"
                          label="conhece"
                          name="conhece"
                          getOptionLabel={(item) => item.str}
                          getOptionValue={(item) => `${item.sigla}`}
                          options={!isDisabled ? options : []}
                          onChange={(value) =>
                            setConhece(value as optionsProps)
                          }
                          value={conhece}
                          styles={{
                            option: (provided) => ({
                              ...provided,
                              fontSize: ".9rem !important",
                              textAlign: "left",
                              minWidth: "25px",
                            }),
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 0,
                              border: "1px solid var(--color-azul-600)",
                              backgroundColor: isDisabled
                                ? "var(--color-cinza-600)"
                                : "",
                              //showFormTransportadora === false && transportadoraErrors.nome ? '#dc3545' : '#204d74',
                            }),
                          }}
                        />
                      </div>
                      <div className="col-md-2  mt-md-0">
                        <Input
                          id="bloqueado"
                          type="text"
                          disabled={true}
                          className="col-md-12"
                          label="Bloqueado?"
                          name="bloqueado"
                          value={bloqueado.str}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <Input
                          type="date"
                          required
                          min={tomorrow}
                          disabled={isDisabled}
                          value={dataProximoContato}
                          className="col-md-12"
                          label="Data Proximo Contato"
                          name="dataProximoContato"
                          onChange={(e) =>
                            setDataProximoContato(formatDate(e.target.value))
                          }
                        />
                      </div>
                      <div className="col-md-3 m-0 pr-0 mt-4 mt-md-0">
                        <label className="col-md-12">Tamanho</label>
                        <Select
                          id="tamanho"
                          disabled="true"
                          className="col-md-12"
                          label="tamanho"
                          name="tamanho"
                          getOptionLabel={(item) => item.str}
                          getOptionValue={(item) => `${item.sigla}`}
                          options={!isDisabled ? TAMANHOS : []}
                          onChange={(value) =>
                            setTamanho(value as optionsProps)
                          }
                          value={tamanho}
                          styles={{
                            option: (provided) => ({
                              ...provided,
                              fontSize: ".9rem !important",
                              textAlign: "left",
                              minWidth: "50px",
                            }),
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 0,
                              border: "1px solid var(--color-azul-600)",
                              backgroundColor: isDisabled
                                ? "var(--color-cinza-600)"
                                : "",
                              //showFormTransportadora === false && transportadoraErrors.nome ? '#dc3545' : '#204d74',
                            }),
                          }}
                        />
                      </div>
                      <div className="col-md-3 m-0 pl-0 pr-0 mt-md-0">
                        <label className="col-md-12">Empresa</label>
                        <Select
                          id="empresas"
                          disabled="true"
                          className="col-md-12"
                          label="empresas"
                          name="empresas"
                          getOptionLabel={(item) => item.str}
                          getOptionValue={(item) => `${item.id}`}
                          options={!isDisabled ? EMPRESAS : []}
                          onChange={(value) =>
                            setEmpresa(value as optionsProps)
                          }
                          value={empresa}
                          styles={{
                            option: (provided) => ({
                              ...provided,
                              fontSize: ".9rem !important",
                              textAlign: "left",
                              minWidth: "50px",
                            }),
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 0,
                              border: "1px solid var(--color-azul-600)",
                              backgroundColor: isDisabled
                                ? "var(--color-cinza-600)"
                                : "",
                              //showFormTransportadora === false && transportadoraErrors.nome ? '#dc3545' : '#204d74',
                            }),
                          }}
                        />
                      </div>
                      <div className="col-md-3 m-0 pr-0 mt-4 mt-md-0">
                        <label className="col-md-12">Origem cliente</label>
                        <Select
                          id="origem"
                          disabled="true"
                          className="col-md-12"
                          label="origem"
                          name="originCliente"
                          getOptionLabel={(item) => item.str}
                          getOptionValue={(item) => `${item.id}`}
                          options={!isDisabled ? ORIGIN : []}
                          onChange={(value) => setOrigin(value as optionsProps)}
                          value={origin}
                          styles={{
                            option: (provided) => ({
                              ...provided,
                              fontSize: ".9rem !important",
                              textAlign: "left",
                              minWidth: "50px",
                            }),
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 0,
                              border: "1px solid var(--color-azul-600)",
                              backgroundColor: isDisabled
                                ? "var(--color-cinza-600)"
                                : "",
                              //showFormTransportadora === false && transportadoraErrors.nome ? '#dc3545' : '#204d74',
                            }),
                          }}
                        />
                      </div>
                    </div>

                    <Input
                      required
                      label="Nome"
                      name="nome"
                      value={nome}
                      disabled={isDisabled}
                      placeholder="Digite o nome do prospect"
                      onChange={(e) => setNome(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="text-secondary">Dados Empresa</strong>
                  </div>
                  <div className="card-body">
                    <Input
                      disabled={isDisabled}
                      placeholder="Digite o nome da fantasia da empresa"
                      label="Nome Fantasia"
                      name="nomeFantasiaAbreviado"
                      value={nomeFantasia}
                      onChange={(e) => setNomeFantasia(e.target.value)}
                    />
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label="CNPJ"
                          name="cnpj"
                          value={cnpj}
                          required
                          disabled={isDisabled}
                          mask="99.999.999/9999-99"
                          placeholder={"00.000.000/0000-00"}
                          onChange={(e) => setCnpj(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          disabled={isDisabled}
                          mask="999.999.999/9"
                          label="Inscrição Estadual"
                          name="inscricaoEstadual"
                          placeholder={"000.000.000/0"}
                          value={inscricaoEstadual}
                          onChange={(e) => setInscricaoEstadual(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-4 mt-md-0">
                <div className="card">
                  <div className="card-header">
                    <strong className="text-secondary">
                      Endereço da Empresa
                    </strong>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <Input
                          disabled={isDisabled}
                          className="col-md-12"
                          placeholder="Digite o endereço da empresa"
                          label="Endereço"
                          name="endereco"
                          value={endereco}
                          onChange={(e) => setEndereco(e.target.value)}
                        />
                      </div>

                      <div className="col-md-3">
                        <Input
                          disabled={isDisabled}
                          placeholder="Digite bairro"
                          label="Bairro"
                          name="bairro"
                          value={bairro}
                          onChange={(e) => setBairro(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <Input
                          required
                          disabled={isDisabled}
                          placeholder="Digite a município"
                          label="Município"
                          name="municipio"
                          value={municipio}
                          onChange={(e) => setMunicipio(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <Input
                          disabled={isDisabled}
                          label="CEP"
                          name="cep"
                          value={cep}
                          className="col-md-12"
                          mask="99999-999"
                          placeholder={"00000-000"}
                          onChange={(e) => setCep(e.target.value)}
                        />
                      </div>
                      <div className="col-md-1">
                        <Input
                          max={2}
                          name="uf"
                          required
                          label="UF"
                          value={uf}
                          type="text"
                          maxLength={2}
                          placeholder="UF"
                          disabled={isDisabled}
                          onChange={(e) => setUf(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* <Input disabled={isDisabled} label="E-mail para NFe" name="email" value={user?.revendedor?.email} /> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mt-4 mt-md-0">
                <div className="card">
                  <div className="card-header">
                    <strong className="text-secondary">Telefones</strong>
                  </div>
                  <div className="card-body row">
                    <div className="col-md-6">
                      <Input
                        disabled={isDisabled}
                        label="Fixo"
                        name="telefone"
                        value={`${telefone}`}
                        required
                        placeholder={"00 0000-0000"}
                        mask="99 9999-9999"
                        onChange={(e) => setTelefone(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        disabled={isDisabled}
                        required
                        label="Celular"
                        name="telefoneFax"
                        value={celular}
                        placeholder={"00 00000-0000"}
                        mask="99 99999-9999"
                        onChange={(e) => setCelular(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-4 mt-md-0">
                <div className="card">
                  <div className="card-header">
                    <strong className="text-secondary">E-mails</strong>
                  </div>
                  <div className="card-body">
                    <Input
                      disabled={isDisabled}
                      placeholder="email@dominio.com"
                      label="E-mail de NFe"
                      name="emailNFe"
                      type="email"
                      required
                      value={emailNFe}
                      onChange={(e) => setEmailNFe(e.target.value)}
                    />
                    <Input
                      disabled={isDisabled}
                      label="E-mail Venda"
                      placeholder="email@dominio.com"
                      name="emailVenda"
                      type="email"
                      required
                      value={emailVenda}
                      onChange={(e) => setEmailVenda(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-4 mt-md-0">
                <div className="card">
                  <div className="card-header">
                    <strong className="text-secondary">Contato</strong>
                  </div>
                  <div className="card-body">
                    <Input
                      disabled={isDisabled}
                      placeholder="Nome contato"
                      label="Nome"
                      name="contato"
                      type="text"
                      value={contato}
                      onChange={(e) => setContato(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="d-block d-md-flex flex-fill m-0 p-0 mb-md-4 mt-4">
              <div className="d-block d-md-flex flex-fill m-0 p-0 ml-md-2 ml-md-2 mb-4 mb-md-0 col-md-6">
                <ButtonExcluirPropspect
                  data={{ codigo, loja, nome }}
                  disabled={isDisabled || isDisabledButtons}
                  action={() => history.push("/prospects")}
                  props={
                    "d-flex align-items-center justify-content-center btn btn-block btn-lg btn-outline-danger mt-0"
                  }
                />
              </div>
              <div className="d-block d-md-flex flex-fill m-0 p-0 ml-md-2 ml-md-2 mb-4 mb-md-0 col-md-6">
                <ButtonEfetivarPropspect
                  data={{ codigo, loja, nome }}
                  disabled={isDisabled || isDisabledButtons}
                  action={() => history.push("/prospects")}
                  props="d-flex align-items-center justify-content-center btn btn-block btn-lg btn-outline-success mt-0"
                />
              </div>

              <div className="d-block d-md-flex flex-fill m-0 p-0 mr-md-2 ml-md-2 mb-4 mb-md-0 col-md-6">
                {isDisabledButtons ? (
                  <button
                    type="submit"
                    disabled={isDisabled}
                    title="Salvar cadastro do prospect"
                    className="d-flex align-items-center justify-content-center btn btn-block btn-lg btn-outline-primary mt-0"
                    //onClick={() => handleSubmit}
                  >
                    <FiSave className="mr-2" />
                    <span>Salvar</span>
                  </button>
                ) : (
                  <button
                    disabled={isDisabled}
                    type="submit"
                    title="Atualizar cadastro do prospect"
                    className="d-flex align-items-center justify-content-center btn btn-block btn-lg btn-outline-warning mt-0"
                    //onClick={() => {}}
                  >
                    <FiEdit className="mr-2" />
                    <span>Atualizar</span>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Cadastro;
