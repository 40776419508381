import React, { FormEvent, useState } from 'react';
import Swal from 'sweetalert2';

import { Produto } from '../../../../typings';
import Input from '../../../../components/Input';
import { solicitaNotaFiscaPdf, solicitaPedidoPdf } from '../../../../services/api';

interface ItemPedido {
  produto: Produto;
  valorUnitario: string;
  notaFiscal: string;
  dataEmissaoNotaFiscal: string;
  status: string;
  valorTotal: string;
  quantidade: string;
}

interface Pedido {
  numero: string;
  notaserie: string; //JL-HF_2022-06
  condicaoPagamento: string;
  descricaoCondicaoPagamento: string;
  dataEmissaoPedido: string;
  itens: ItemPedido[];
  transportadora: {
    nome: string;
    cnpj: string;
  };
  pedidob2b: {
    dataCriacao: string;
    usuario: {
      cnpj: string;
      nome: string;
    };
  };
}

const SolicitarEnvioEmail: React.FC<{ pedido: Pedido }> = ({ pedido }) => {
  const [email, setEmail] = useState('');
  const [isNota] = useState(parseInt(pedido.notaserie) >= 0);
  const [enviaPedido, setEnviaPedido] = useState(false);
  const [enviaNota, setEnviaNota] = useState(false);
  const [loading, setLoading] = useState(false);
  let errorMessage = '';

  function filterPedidoItens(itens: ItemPedido[]) {
    if (itens.length > 0) {
      return itens.filter((item) => Number(item.quantidade) > 0);
    }

    return [];
  }

  function getNumeroNF(itens: ItemPedido[]) {
    const filtered = filterPedidoItens(itens);

    if (filtered.length > 0 && filtered[0].notaFiscal) {
      return filtered[0].notaFiscal;
    }

    return '';
  }

  async function handleFormSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      /*console.log(`
      data:{
        Nota: ${enviaNota ? getNumeroNF(pedido.itens) : ''},
        Serie: ${enviaNota ? pedido.notaserie : ''},
        Pedido: ${enviaPedido ? pedido.numero : ''},
        Email: ${email}
      }
      `);*/
      setLoading(true);

      if (enviaNota) {
        try {
          await solicitaNotaFiscaPdf({
            email: email.toString().trim(),
            nota: getNumeroNF(pedido.itens).toString(),
            serie: pedido.notaserie.toString(),
          });
        } catch (error) {
          console.log(error);
          errorMessage = 'Nota Fiscal';
        }
      }

      if (enviaPedido) {
        try {
          await solicitaPedidoPdf({
            email: email.toString().trim(),
            pedido: pedido.numero.toString(), //'623368'
          });
        } catch (error) {
          console.log(error);
          errorMessage = errorMessage.length > 0 ? 'Nota Fiscal e Pedido' : 'Pedido';
          throw Error();
        }
      }

      setLoading(false);

      await Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Solicitação foi enviada, em breve você receberá email!',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'OK',
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: `Falha ao solicitar envio de email ${errorMessage}, tente mais tarde!`,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'OK',
      });
    }
  }

  return (
    <>
      {loading && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(255, 255, 255, 0.75)',
            zIndex: 1000,
          }}>
          <div className="spinner-border text-info"></div>
          <div className="text-primary mt-4">Solicitando envio de e-mail.</div>
        </div>
      )}
      <hr />
      <table
        className="mb-4 text-left table table-borderless"
        style={{
          fontSize: '14px',
        }}>
        <tbody>
          <tr>
            <td
              style={{
                width: '15%',
              }}>
              <b>Nota Fiscal:</b>
            </td>
            <td
              style={{
                width: '45%',
              }}>
              {getNumeroNF(pedido.itens)}
            </td>
          </tr>
          <tr>
            <td>
              <b>Serie Nota:</b>
            </td>
            <td>{pedido.notaserie}</td>
          </tr>
          <tr>
            <td>
              <b>Pedido:</b>
            </td>
            <td>{pedido.numero}</td>
          </tr>
          <tr>
            <td>
              <b>Data:</b>
            </td>
            <td>{new Date(pedido.dataEmissaoPedido).toLocaleDateString('pt-BR')}</td>
          </tr>
        </tbody>
      </table>

      <form
        onSubmit={handleFormSubmit}
        style={{
          border: '1px solid #eee',
          fontSize: '.9rem',
        }}
        className="p-4">
        <div
          style={{
            display: 'flex',
            alignItems: 'left',
            marginBottom: '.5rem',
            gap: '5px',
          }}>
          {isNota && (
            <>
              <input
                type="checkbox"
                style={{
                  width: '20px',
                  height: '20px',
                }}
                onChange={(e) => (e.target.checked ? setEnviaNota(true) : setEnviaNota(false))}
              />
              <label className="p-1 mr-2">Nota Fiscal</label>
            </>
          )}
          <input
            type="checkbox"
            style={{
              width: '20px',
              height: '20px',
              marginLeft: '1.5rem',
            }}
            onChange={(e) => (e.target.checked ? setEnviaPedido(true) : setEnviaPedido(false))}
          />
          <label className="p-1">Pedido</label>
        </div>

        <div className="alert alert-info">
          <div className="row align-items-center">
            <div className="col-md">
              <strong>Para adicionar mais de um email, separar por ; </strong>
            </div>
          </div>
        </div>

        <Input
          label="E-mail"
          name="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <button
          type="submit"
          className="btn btn-block btn-primary btn-lg mt-4"
          disabled={(!enviaNota && !enviaPedido) || email.length === 0 ? true : false}>
          SOLICITAR ENVIO
        </button>
      </form>
    </>
  );
};

export default SolicitarEnvioEmail;
