import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  FaExpandArrowsAlt,
  FaRegThumbsDown,
  FaRegThumbsUp,
  FaThList,
} from "react-icons/fa";
import { FiSearch, FiSlash } from "react-icons/fi";
import Swal from "sweetalert2";
import { parseISO, format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import withReactContent from "sweetalert2-react-content";

import { Produto, Revendedor } from "../../../typings";
import { useAppContext } from "../../../contexts/app";
import {
  fetchSupervisorOrcamentos,
  supervisorOrcamentosCarregarProdutos,
  fetchInteracoesOrcamento,
} from "../../../services/api";
import OrcamentoInteracoes from "../../../components/OrcamentoInteracoes";

import Aprovar from "./Aprovar";
import Reprovar from "./Reprovar";
import Detalhes from "./Detalhes";

interface ItemPedido {
  produto: Produto;
  valorUnitario: string;
  notaFiscal: string;
  dataEmissaoNotaFiscal: string;
  status: string;
  valorTotal: string;
  quantidade: string;
}

interface Pedido {
  numero: string;
  notaserie: string; //JL-HF_2022-06
  condicaoPagamento: string;
  descricaoCondicaoPagamento: string;
  dataEmissaoPedido: string;
  itens: ItemPedido[];
  transportadora: {
    nome: string;
    cnpj: string;
  };
  pedidob2b: {
    dataCriacao: string;
    usuario: {
      cnpj: string;
      nome: string;
    };
  };
}

const Orcamentos = () => {
  const [orcamento, setOrcamento] = useState<any[]>([]);
  const [clientes, setClientes] = useState<Revendedor[]>([]);
  const [filteredClientes, setFilteredClientes] = useState<any[]>([]);
  const [searchText, setSearchText] = useState("");
  const { user, showLoader, hideLoader, setUserCliente } = useAppContext();

  const sortParam = useRef("nome");
  const sortOrder = useRef("asc");

  useEffect(() => {
    async function fetchItems() {
      try {
        const loader = showLoader(
          "Carregando sua lista de clientes. Por favor aguarde."
        );

        const response = await fetchSupervisorOrcamentos();

        if (response.data) {
          const qtdPrefixo = "00000";

          const formatData = await response.data.map((item: any) => {
            item.id =
              String(qtdPrefixo.slice(String(item.id).length)) + item.id;
            item.dataCriacao = String(
              format(parseISO(item.dataCriacao), "dd/MM/yyyy HH:mm:ss", {
                locale: ptBR,
              })
            );
            item.dataInteracao =
              item.dataInteracao.length === 0
                ? "----"
                : String(
                    format(
                      parseISO(item.dataInteracao),
                      "dd/MM/yyyy HH:mm:ss",
                      { locale: ptBR }
                    )
                  );

            return {
              ...item,

              StrStatus: getStatusOrcamento(item.status),
              pedido: {
                qtdItens: item.itens?.length,
                condPagamento: `${item.condicaoPagamento}-${item.descricaoCondicaoPagamento}`,
                /*totalDesconto: '3.09',
                totalSemImposto: '8.35',
                totalIPI: '0.37',
                totalST: '0.00',
                totalComImposto: '8.72',*/
              },
            };
          });

          const _pedidos = [...formatData];
          const filtered = _pedidos.filter(
            (item: Pedido) => item.itens.length > 0
          );
          const sorted = filtered.sort((a, b) => {
            return new Date(a.dataCriacao) > new Date(b.dataCriacao) ? 1 : -1;
          });

          setOrcamento(sorted);
        }

        hideLoader(loader);
      } catch (error) {
        Swal.fire({
          icon: "warning",
          title: "Erro",
          text:
            "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });

        console.log(error);
      }
    }

    if (clientes.length === 0) {
      fetchItems();
    }
  }, [
    clientes.length,
    hideLoader,
    setUserCliente,
    showLoader,
    user,
    orcamento.length,
  ]);

  async function sortBy(
    field: "id" | "dataCriacao" | "revendedor" | "usuario" | "nome" | "cnpj"
  ) {
    const items = [...orcamento];

    if (sortParam.current === field) {
      sortOrder.current = sortOrder.current === "asc" ? "desc" : "asc";
    } else {
      sortParam.current = field;
      sortOrder.current = "asc";
    }

    const sorted = items.sort((a, b) => {
      if (sortOrder.current === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });

    setClientes(sorted);
  }

  /*JL-HF_2022-06 */
  function getStatusOrcamento(status: number) {
    const strStatus: any = {
      1: "SALVO",
      2: "AGUARDANDO",
      3: "REJEITADO",
      4: "APROVADO",
      5: "EFETIVADO",
    };

    return strStatus[String(status)];
  }

  async function showDetalhePedido(pedido: any, cnpj: string) {
    const ReactSwal = withReactContent(Swal);

    //const loader = showLoader('Por favor aguarde. Carregando detalhes orçamento.');
    showLoader("Por favor aguarde. Carregando detalhes orçamento.");

    try {
      const produtos = pedido.itens.map((item: any) => {
        return {
          ...item,
          percentualDesconto: parseFloat(item.percentualDesconto),
        };
      });

      const response = await supervisorOrcamentosCarregarProdutos({
        revendedorCnpj: cnpj,
        condicaoPagamento: pedido.condicaoPagamento,
        calcularImpostos: "true",
        calcularEntregas: "false",
        calcularQuantidadeMes: "true",
        itens: produtos,
      });

      const formatData = await response.data.map(
        (item: any, index: number) => ({
          ...item,
          percentualDesconto: produtos.map((i: any) => {
            if (i.codigo === item.codigo) {
              return i.percentualDesconto;
            } else {
              return 0;
            }
          })[index],
        })
      );

      ReactSwal.fire({
        title: "Detalhes orçamento",
        html: (
          <Detalhes
            orcamento={pedido}
            itens={formatData}
            action={ReactSwal}
            aprovarOrcamento={showAprovarOrcamento}
            reprovarOrcamento={showReprovarOrcamento}
          />
        ),
        width: "80%",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        showCloseButton: true,
        //confirmButtonText: 'Enviar Solicitação',
      });
      //hideLoader(loader);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Erro",
        text:
          "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });

      console.log(error);
    }
  }

  async function showDetalheInteracoes(id: any) {
    const ReactSwal = withReactContent(Swal);

    const loader = showLoader(
      "Por favor aguarde. Carregando as interações do orcamentos."
    );

    const response: any = await fetchInteracoesOrcamento({ id });

    hideLoader(loader);

    ReactSwal.fire({
      title: "Interações do orçamento",
      html: (
        <OrcamentoInteracoes
          data={response.data}
          id={id}
          setOrcamento={setOrcamento}
        />
      ),
      width: "80%",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
      //confirmButtonText: 'Enviar Solicitação',
    });
  }

  function showReprovarOrcamento(orcamento?: any) {
    const ReactSwal = withReactContent(Swal);

    ReactSwal.fire({
      title: "Orçamento",
      html: <Reprovar orcamento={orcamento} setOrcamento={setOrcamento} />,
      width: "40%",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  function showAprovarOrcamento(orcamento?: any) {
    const ReactSwal = withReactContent(Swal);

    ReactSwal.fire({
      title: "Orçamento",
      html: <Aprovar orcamento={orcamento} setOrcamento={setOrcamento} />,
      width: "40%",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  const handleSearch = useCallback(
    async (e: FormEvent | null) => {
      if (e) {
        e.preventDefault();
      }

      const filtered = orcamento.filter((orcamento) => {
        const nome = orcamento.revendedor.nome.toLowerCase();
        const cnpj = orcamento.revendedor.cnpj.toLowerCase();

        return (
          cnpj.includes(searchText.toLowerCase()) ||
          nome.includes(searchText.toLowerCase())
        );
      });

      setFilteredClientes(filtered);
    },
    [orcamento, searchText]
  );

  const handleReset = useCallback(() => {
    setSearchText("");
    setFilteredClientes(orcamento);
  }, [orcamento]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearch(null);
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchText, handleSearch, handleReset]);

  return (
    <div className="container-fluid my-4">
      <div className="d-flex flex-fill flex-column">
        {/*
        <div className="d-block d-md-flex align-items-center justify-content-between p-4 border border-primary mb-4">
          <h5 className="mb-0 text-primary mb-4 mb-md-0">Bem vindo, {user?.nome}!</h5>
          <p className="mb-0 text-primary mb-4 mb-md-0">DEV - {user?.tipo.nome}</p>
        </div>
  */}
        <form onSubmit={handleSearch} onReset={() => handleReset()}>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Digite o CNPJ ou nome do cliente"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <div className="input-group-append">
              <button
                type="submit"
                className="btn btn-lg btn-primary d-flex align-items-center"
              >
                <FiSearch />
              </button>
              {searchText && (
                <button
                  type="reset"
                  className="btn btn-lg btn-outline-primary d-flex align-items-center"
                >
                  <FiSlash />
                </button>
              )}
            </div>
          </div>
        </form>
        {filteredClientes.length > 0 ? (
          <div className="table-responsive">
            <table
              className="table table-striped table-borderless"
              style={{
                fontSize: "14px",
              }}
            >
              <thead className="thead-dark">
                <tr>
                  <th
                    style={{ width: "100px", cursor: "pointer" }}
                    onClick={() => sortBy("id")}
                  >
                    <div className="d-flex align-items-center">
                      <span className="mr-1">Orçamento</span>
                      {/* {sortParam.current === 'id' ? (
                        sortOrder.current === 'asc' ? (
                          <FiChevronUp />
                        ) : (
                          <FiChevronDown />
                        )
                      ) : (
                        <FaSort />
                      )} */}
                    </div>
                  </th>
                  <th
                    style={{ width: "100px", cursor: "pointer" }}
                    onClick={() => sortBy("dataCriacao")}
                  >
                    <div className="d-flex align-items-center">
                      <span className="mr-1">Data</span>
                      {/* {sortParam.current === 'dataCriacao' ? (
                        sortOrder.current === 'asc' ? (
                          <FiChevronUp />
                        ) : (
                          <FiChevronDown />
                        )
                      ) : (
                        <FaSort />
                      )} */}
                    </div>
                  </th>
                  <th
                    style={{ width: "100px", cursor: "pointer" }}
                    onClick={() => sortBy("usuario")}
                  >
                    <div className="d-flex align-items-center">
                      <span className="mr-1">Cód. Representante</span>
                      {/* {sortParam.current === 'usuario' ? (
                        sortOrder.current === 'asc' ? (
                          <FiChevronUp />
                        ) : (
                          <FiChevronDown />
                        )
                      ) : (
                        <FaSort />
                      )} */}
                    </div>
                  </th>
                  <th
                    style={{ width: "100px", cursor: "pointer" }}
                    onClick={() => sortBy("revendedor")}
                  >
                    <div className="d-flex align-items-center">
                      <span className="mr-1">Código Cliente</span>
                      {/* {sortParam.current === 'revendedor' ? (
                        sortOrder.current === 'asc' ? (
                          <FiChevronUp />
                        ) : (
                          <FiChevronDown />
                        )
                      ) : (
                        <FaSort />
                      )} */}
                    </div>
                  </th>
                  <th
                    style={{ width: "1px", cursor: "pointer" }}
                    onClick={() => sortBy("cnpj")}
                  >
                    <div className="d-flex align-items-center">
                      <span className="mr-1">CNPJ</span>
                      {/* {sortParam.current === 'cnpj' ? (
                        sortOrder.current === 'asc' ? (
                          <FiChevronUp />
                        ) : (
                          <FiChevronDown />
                        )
                      ) : (
                        <FaSort />
                      )} */}
                    </div>
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("nome")}
                  >
                    <div className="d-flex align-items-center">
                      <span className="mr-1">Nome Cliente</span>
                      {/* {sortParam.current === 'nome' ? (
                        sortOrder.current === 'asc' ? (
                          <FiChevronUp />
                        ) : (
                          <FiChevronDown />
                        )
                      ) : (
                        <FaSort />
                      )} */}
                    </div>
                  </th>

                  <th style={{ width: "1px" }} title="Condição de pagamento">
                    Detalhes
                  </th>
                  <th colSpan={3}></th>
                </tr>
              </thead>
              <tbody>
                {orcamento.map((o) => (
                  <tr key={o.id}>
                    <td className="text-center" style={{ fontSize: "14px" }}>
                      {o.id}
                    </td>
                    <td className="text-center" style={{ fontSize: "14px" }}>
                      {o.dataCriacao}
                    </td>
                    <td
                      className="text-center"
                      style={{ fontSize: "14px", whiteSpace: "nowrap" }}
                    >
                      {o.usuario.codigo}
                    </td>
                    <td
                      className="text-center"
                      style={{ fontSize: "14px", whiteSpace: "nowrap" }}
                    >
                      {o.revendedor.loja}-{o.revendedor.codigo}
                    </td>
                    <td
                      className="text-center"
                      style={{ fontSize: "14px", whiteSpace: "nowrap" }}
                    >
                      {o.revendedor.cnpj}
                    </td>
                    <td style={{ fontSize: "14px", wordWrap: "break-word" }}>
                      {o.revendedor.nome}
                    </td>
                    <td style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      <small>
                        <span className="mr-1">
                          <b>Qtd. Itens:</b>
                        </span>
                        {o.quantidadeItens} <br />
                        Cond. Pg.: {o.pedido.condPagamento}
                        <br />
                        <span className="mr-1">
                          <b>Total Descontos.:</b>
                        </span>
                        {Number(`${o.valorTotalDescontos}`).toLocaleString(
                          "pt-BR",
                          {
                            style: "currency",
                            currency: "BRL",
                          }
                        )}
                        <br />
                        <span className="mr-1">
                          <b>Total S/Impost.:</b>
                        </span>
                        {Number(`${o.valorTotalSemImpostos}`).toLocaleString(
                          "pt-BR",
                          {
                            style: "currency",
                            currency: "BRL",
                          }
                        )}
                        <br />
                        <span className="mr-1">
                          <b>Total IPI:</b>
                        </span>
                        {Number(`${o.ipiTotal}`).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                        <br />
                        <span className="mr-1">
                          <b>Total ST:</b>
                        </span>
                        {Number(`${o.stTotal}`).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                        <br />
                        <span className="mr-1">
                          <b>Total C/Impost.:</b>
                        </span>
                        {Number(`${o.valorTotalComImpostos}`).toLocaleString(
                          "pt-BR",
                          {
                            style: "currency",
                            currency: "BRL",
                          }
                        )}
                      </small>
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <button
                        type="button"
                        title="Aprovar orçamento"
                        className="btn btn-sm btn-outline-success d-flex align-items-center"
                        onClick={() => showAprovarOrcamento(o)}
                      >
                        <FaRegThumbsUp />
                      </button>
                      <br />
                      <button
                        type="button"
                        title="Reprovar orçamento"
                        className="btn btn-sm btn-outline-danger d-flex align-items-center"
                        onClick={() => showReprovarOrcamento(o)}
                      >
                        <FaRegThumbsDown />
                      </button>
                      <br />
                      <button
                        type="button"
                        title="Interações orçamento - motivo (Justificativas)"
                        className="btn btn-sm btn-outline-warning d-flex align-items-center"
                        onClick={() => showDetalheInteracoes(o.id)}
                      >
                        <FaThList />
                      </button>
                      <br />
                      <button
                        type="button"
                        title="Exibir detalhes do orçamento"
                        className="btn btn-sm btn-outline-primary d-flex align-items-center"
                        onClick={() => showDetalhePedido(o, o.revendedor.cnpj)}
                      >
                        <FaExpandArrowsAlt />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="alert alert-info">
            {searchText === ""
              ? "Nenhum orçamento cadastrado."
              : "Nenhum orçamento encontrado."}
          </div>
        )}
      </div>
    </div>
  );
};

export default Orcamentos;
