import React from "react";

import ButtonContato from "../../../components/ButtonContato";
import { TipoUsuario } from "../../../entities/TipoUsuario";
import {
  fetchAgendaRegistrosDeContatosPorCodigoCliente,
  sendAgendaRegistroContatoCliente,
} from "../../../services/api";

const ButtonComentarioPedido: React.FC<{
  props: any;
  loja: string | null;
  grupo?: any | null;
  cnpj?: string | "";
  codigo?: string | undefined;
  userTipo?: string | undefined;
  userCodigo?: string | undefined;
  setIsShowDropdownOpcoes?: React.Dispatch<React.SetStateAction<number>> | null;
}> = ({
  setIsShowDropdownOpcoes,
  userCodigo,
  userTipo,
  codigo,
  grupo,
  loja,
  cnpj,
  props,
}: any) => {
  async function handleFormSubmitProps({ data, files }: any) {
    await sendAgendaRegistroContatoCliente({
      data: {
        ...data,
        loja,
        codigo,
        codigoRepresentante: `${
          TipoUsuario[userTipo.toLowerCase()].perfil
        }${userCodigo}`,
      },
      files,
    });
  }

  async function fetchAgendaRegistroContatosProps() {
    return await fetchAgendaRegistrosDeContatosPorCodigoCliente({
      codigo,
      loja,
    });
  }

  return (
    <ButtonContato
      props={props}
      origin="pedido"
      codigo={codigo}
      grupo={grupo}
      cnpj={cnpj}
      loja={loja}
      action={fetchAgendaRegistroContatosProps}
      setIsShowDropdownOpcoes={setIsShowDropdownOpcoes}
      handleCreateRegistroContato={handleFormSubmitProps}
      //fetchAgendaRegistroContatosProps={fetchAgendaRegistroContatosProps}
    />
  );
};

export default ButtonComentarioPedido;
