import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { NavLink, useHistory } from "react-router-dom";
import { differenceInDays } from "date-fns";
import {
  FaBox,
  FaBoxes,
  FaBuffer,
  FaMapMarkedAlt,
  FaMoneyBillAlt,
  FaRegBuilding,
  FaRegIdCard,
  FaShoppingCart,
  FaSort,
} from "react-icons/fa";
import {
  FiChevronDown,
  FiChevronUp,
  FiEye,
  FiLock,
  FiSearch,
  FiSlash,
} from "react-icons/fi";
import Swal from "sweetalert2";
import { BsCircleFill, BsFillInfoCircleFill } from "react-icons/bs";

import { PieChart } from "react-minimal-pie-chart";

import { LegendaProps, Revendedor } from "../../typings";
import { useAppContext } from "../../contexts/app";
import {
  fetchClientesRepresentante,
  updateClienteAtivo,
} from "../../services/api";

import changeActiveLegenda from "../../utils/changeActiveLengenda";
import withReactContent from "sweetalert2-react-content";
import Legendas from "../../components/Legendas";
import { legendasCliente } from "../../utils/legendasData";
import ButtonContatoCliente from "./Contato";
import ButtonContatoAnexos from "../../components/ButtonContatoAnexos";

import ButtonContatoHistorico from "../../components/ButtonContatoHistorico";

const Clientes = () => {
  const [clientes, setClientes] = useState<Revendedor[]>([]);
  const [filteredClientes, setFilteredClientes] = useState<Revendedor[]>([]);
  const [searchText, setSearchText] = useState("");
  const {
    user,
    showLoader,
    hideLoader,
    setUser,
    setUserCliente,
    setIsRepresentante,
  } = useAppContext();

  const sortParam = useRef("nome");
  const sortOrder = useRef("asc");

  const history = useHistory();

  const totalVendasMes = useMemo(() => {
    let valor = 0;

    clientes.forEach((cliente) => {
      valor += Number(cliente?.valorComprado || 0);
    });

    return valor;
  }, [clientes]);

  const metaAtingida = useMemo(() => {
    const meta = !user?.meta ? 0.0 : Number(user?.meta);
    const total =
      meta > 0 && totalVendasMes > 0 ? (totalVendasMes / meta) * 100 : 0;

    return `${Math.round(total)}%`;
  }, [totalVendasMes, user]);

  const yesterday = useMemo(() => {
    const date = new Date();

    date.setDate(date.getDate() - 1);

    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;

    return `${day}/${month}`;
  }, []);

  const today = useMemo(() => {
    return new Date();
  }, []);

  async function sortBy(
    field:
      | "codigo"
      | "nome"
      | "municipio"
      | "uf"
      | "cnpj"
      | "dataProximoContato"
  ) {
    const items = [...clientes];
    if (sortParam.current === field) {
      sortOrder.current = sortOrder.current === "asc" ? "desc" : "asc";
    } else {
      sortParam.current = field;
      sortOrder.current = "asc";
    }

    const sorted = items.sort((a, b) => {
      if (sortOrder.current === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });

    setClientes(sorted);
  }

  async function changeActiveClient(cliente: Revendedor, url: string) {
    setIsShowDropdownOpcoes(0);
    const modalResponse = await Swal.fire({
      title: cliente.nome,
      text: `${
        url === "/"
          ? "Confirma a criação de pedido para este cliente?"
          : "Acessar conta cliente"
      }`,
      showCancelButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    });

    if (modalResponse.isConfirmed) {
      try {
        const loader = showLoader(
          "Alterando o cliente ativo. Por favor aguarde."
        );
        const response = await updateClienteAtivo({
          codigo: cliente.codigo,
          loja: cliente.loja,
        });

        hideLoader(loader);

        if (response.data) {
          setUser(setIsRepresentante(response.data)); //JL-HF_2023-06

          history.push(`${url}`);
        }
      } catch (error) {
        Swal.fire({
          icon: "warning",
          title: "Erro",
          text:
            "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });

        console.log(error);
      }
    }
  }

  function showLegendaClientes() {
    const ReactSwal = withReactContent(Swal);

    //pedido.itens = filterPedidoItens(pedido.itens);
    ReactSwal.fire({
      title: "Legendas - Cliente",
      html: <Legendas legendas={legendasCliente} />,
      width: "40%",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: "FECHAR",
    });
  }

  //JL-HF 2023-11
  function calculaQtdDiasInatividade(date: string) {
    const dataUltimoPedido = new Date(date);
    const qtdDias = differenceInDays(today, dataUltimoPedido);
    return qtdDias;
  }

  const handleSearch = useCallback(
    async (e: FormEvent | null) => {
      if (e) {
        e.preventDefault();
      }

      const filtered = clientes.filter((cliente) => {
        const codigo = cliente.codigo; //JL-HF_2023-02
        const nome = cliente.nome.toLowerCase();
        const cnpj = cliente.cnpj.toLowerCase();
        const dataProximoContato = new Date(
          cliente.dataProximoContato
        ).toLocaleDateString("pt-BR"); //JL-HF_2023-12

        return (
          cnpj.includes(searchText.toLowerCase()) ||
          nome.includes(searchText.toLowerCase()) ||
          codigo.includes(searchText.toLocaleLowerCase()) ||
          dataProximoContato.includes(searchText) //JL-HF_2023-12
        );
      });

      setFilteredClientes(filtered);
    },
    [clientes, searchText]
  );

  const handleReset = useCallback(() => {
    setSearchText("");
    setFilteredClientes(clientes);
  }, [clientes]);

  const [isShowDropdownOpcoes, setIsShowDropdownOpcoes] = useState<number>(0);

  function setShowDropdownOpcoes(id: number) {
    if (isShowDropdownOpcoes === id) {
      setIsShowDropdownOpcoes(0);
      return;
    }

    setIsShowDropdownOpcoes(id);
  }

  useEffect(() => {
    async function fetchClientes() {
      try {
        const loader = showLoader(
          "Carregando sua lista de clientes. Por favor aguarde."
        );
        const response = await fetchClientesRepresentante(
          `${sortParam.current}:${sortOrder.current}`
        );

        setUserCliente(null);

        if (response.data.itemCount > 0) {
          let legenda: LegendaProps;

          const data = response.data.items.map((item: any) => {
            legenda = changeActiveLegenda("", item.dataProximoContato, 0);

            return {
              ...item,
              legenda,
            };
          });
          // setClientes(response.data.items);
          // setFilteredClientes(response.data.items);
          //response.data.items = data;

          setClientes(data);
          setFilteredClientes(data);
        }

        hideLoader(loader);
      } catch (error) {
        Swal.fire({
          icon: "warning",
          title: "Erro",
          text:
            "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });

        console.log(error);
      }
    }

    if (clientes.length === 0) {
      fetchClientes();
    }
  }, [
    //clientes,
    clientes.length,
    //filteredClientes,
    //hideLoader,
    //setUserCliente,
    //showLoader,
    //user,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearch(null);
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchText, handleSearch, handleReset]);

  return (
    <div className="container-fluid my-4">
      <div className="d-flex flex-fill flex-column">
        <div className="d-block d-md-flex align-items-center justify-content-between p-4 border border-primary mb-4">
          <h5 className="mb-0 text-primary mb-4 mb-md-0">
            Bem vindo, {user?.nome}!
            {/* <br />
            <span className="pt-2 text-secondary">
              <small>PERFIL: {user?.tipo?.nome}</small>
            </span> */}
          </h5>
          <div className="d-flex align-items-center justify-content-between">
            <PieChart
              lineWidth={35}
              startAngle={-90}
              style={{ width: "60px" }}
              background="#e7e7e7"
              totalValue={Number(user?.meta || 0)}
              data={[{ value: totalVendasMes, color: "#28a745" }]}
              label={() => metaAtingida}
              labelStyle={{
                fontSize: "20px",
                fill: "#555",
              }}
              labelPosition={0}
            />
            <div className="d-flex flex-column ml-2">
              <div className="d-block d-md-flex align-items-center">
                <span className="d-block d-md-inline flex-grow-1 mr-2">
                  Meta do mês:{" "}
                </span>
                <strong className="text-primary">
                  {Number(user?.meta || 0).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </strong>
              </div>
              <div className="d-block d-md-flex align-items-center">
                <span className="d-block d-md-inline flex-grow-1 mr-2">
                  Vendas no mês:{" "}
                </span>
                <strong className="text-primary">
                  {totalVendasMes.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </strong>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSearch} onReset={() => handleReset()}>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Digite o Código, CNPJ, Data ou nome do cliente"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <div className="input-group-append">
              <button
                type="submit"
                className="btn btn-lg btn-primary d-flex align-items-center"
              >
                <FiSearch />
              </button>
              {searchText && (
                <button
                  type="reset"
                  className="btn btn-lg btn-outline-primary d-flex align-items-center"
                >
                  <FiSlash />
                </button>
              )}
            </div>
          </div>
        </form>

        {filteredClientes.length > 0 ? (
          <div className="table-responsive" style={{ minHeight: "60vh" }}>
            <table className="table table-striped table-borderless">
              <thead className="thead-dark">
                <tr>
                  <th
                    style={{ width: "1px", maxWidth: "1px" }}
                    className="text-center"
                  >
                    {/*JL-HF_2023-09 - Legendas */}
                    <button
                      title="Legendas prospect"
                      className="btn btn-default p-0 m-0"
                      onClick={() => showLegendaClientes()}
                    >
                      <BsFillInfoCircleFill size={16} color="#fff" />
                    </button>
                  </th>
                  <th
                    style={{
                      cursor: "pointer",
                    }}
                    className="col-md-1"
                    onClick={() => sortBy("codigo")}
                  >
                    <div className="d-flex align-items-center">
                      <span className="mr-1">Código</span>
                      {sortParam.current === "codigo" ? (
                        sortOrder.current === "asc" ? (
                          <FiChevronUp />
                        ) : (
                          <FiChevronDown />
                        )
                      ) : (
                        <FaSort />
                      )}
                    </div>
                  </th>
                  <th
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => sortBy("nome")}
                  >
                    <div className="d-flex align-items-center">
                      <span className="mr-1">Nome - CLASS./CAT</span>
                      {sortParam.current === "nome" ? (
                        sortOrder.current === "asc" ? (
                          <FiChevronUp />
                        ) : (
                          <FiChevronDown />
                        )
                      ) : (
                        <FaSort />
                      )}
                    </div>
                  </th>
                  <th
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => sortBy("municipio")}
                  >
                    <div className="d-flex align-items-center">
                      <span className="mr-1">UF / Município</span>
                      {sortParam.current === "uf" ? (
                        sortOrder.current === "asc" ? (
                          <FiChevronUp />
                        ) : (
                          <FiChevronDown />
                        )
                      ) : (
                        <FaSort />
                      )}
                    </div>
                  </th>
                  {/* <th
                    style={{ width: "1px", cursor: "pointer" }}
                    onClick={() => sortBy("uf")}
                  >
                    <div className="d-flex align-items-center">
                      <span className="mr-1">UF</span>
                      {sortParam.current === "uf" ? (
                        sortOrder.current === "asc" ? (
                          <FiChevronUp />
                        ) : (
                          <FiChevronDown />
                        )
                      ) : (
                        <FaSort />
                      )}
                    </div>
                  </th> */}
                  <th
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => sortBy("cnpj")}
                  >
                    <div className="d-flex align-items-center">
                      <span className="mr-1">CNPJ</span>
                      {sortParam.current === "cnpj" ? (
                        sortOrder.current === "asc" ? (
                          <FiChevronUp />
                        ) : (
                          <FiChevronDown />
                        )
                      ) : (
                        <FaSort />
                      )}
                    </div>
                  </th>
                  {/* <th style={{ width: "1px" }}>Class. / Cat.</th> */}
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <div>Compras no Mês</div>
                    <small>(até {yesterday})</small>
                  </th>

                  <th className="text-center">
                    {/*JL-HF_2023-09 - Data último contato */}
                    <div>Qtd. Dias</div>
                    <small>Inatividade</small>
                  </th>
                  <th className="text-center">
                    {/*JL-HF_2023-09 - Data último contato */}
                    {/* <div>Data</div>
                    <small>proximo contato</small> */}

                    <div
                      className="d-flex align-items-center"
                      style={{
                        cursor: "pointer",
                        width: "10px",
                        maxWidth: "10px",
                      }}
                      onClick={() => sortBy("dataProximoContato")}
                    >
                      <span className="mr-1">
                        <div>Data</div>
                        <small>próximo contato</small>
                      </span>
                      {sortParam.current === "dataProximoContato" ? (
                        sortOrder.current === "asc" ? (
                          <FiChevronUp />
                        ) : (
                          <FiChevronDown />
                        )
                      ) : (
                        <FaSort />
                      )}
                    </div>
                  </th>
                  <th
                    className="text-center"
                    style={{ width: "10px", maxWidth: "10px" }}
                  >
                    {/* Opções */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredClientes.map((cliente) => (
                  <tr key={cliente.id}>
                    <td
                      className="text-center"
                      title={
                        cliente?.dataProximoContato
                          ? ""
                          : "Sem data último contato"
                      }
                    >
                      {cliente?.legenda && cliente?.dataProximoContato ? (
                        <BsCircleFill
                          size={16}
                          color={cliente?.legenda.color}
                          title={cliente.legenda.legenda}
                        />
                      ) : (
                        "--"
                      )}
                    </td>

                    <td
                      style={{
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        width: "60px",
                        maxWidth: "60px",
                      }}
                    >
                      {cliente.codigo}-{cliente.loja}
                    </td>
                    <td style={{ fontSize: "14px" }}>
                      {cliente.nome}
                      <br />
                      <small>
                        Class./CAT: {cliente.classificacao}/
                        {cliente.classificacaoDescricao}
                      </small>
                    </td>
                    <td
                      className="col-md-1"
                      style={{
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {cliente.uf} / {cliente.municipio}
                    </td>
                    {/* <td style={{ fontSize: "1px", whiteSpace: "nowrap" }}>
                      {cliente.uf}
                    </td> */}
                    <td
                      className="col-sm-1"
                      style={{ fontSize: "14px", whiteSpace: "nowrap" }}
                    >
                      {cliente.cnpj}
                    </td>
                    {/* <td style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      {cliente.classificacao} / {cliente.classificacaoDescricao}
                    </td> */}
                    <td style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      {Number(cliente.valorComprado || 0).toLocaleString(
                        "pt-BR",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )}
                    </td>
                    <td
                      style={{
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {cliente?.dataUltimoPedido
                        ? calculaQtdDiasInatividade(cliente.dataUltimoPedido)
                        : "--"}
                    </td>
                    <td
                      style={{
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {cliente?.dataProximoContato
                        ? new Date(
                            cliente.dataProximoContato
                          ).toLocaleDateString("pt-BR")
                        : "-- / -- / ----"}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {/* <button
                        type="button"
                        className={[
                          'btn align-items-center',
                          Number(cliente.valorComprado || 0) > 0 ? 'btn-primary' : 'btn-danger',
                        ].join(' ')}
                        onClick={() => changeActiveClient(cliente)}>
                        <FaBox className="mr-2" />
                        Criar pedido
                      </button> */}
                      {/* JL-HF_2023-06 => Add Dropdown*/}
                      <div className="btn-group dropleft col-12">
                        {/* <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => setShowDropdownOpcoes(cliente.id)}
                          title="Menu">
                          {isShowDropdownOpcoes === cliente.id ? <FiX size={24} /> : <FiMenu size={24} />}
                        </button> */}

                        <button
                          type="button"
                          className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => setShowDropdownOpcoes(cliente.id)}
                        >
                          {/* <span className="sr-only">Toggle Dropdown</span> */}
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => setShowDropdownOpcoes(cliente.id)}
                        >
                          Opções
                        </button>

                        <div
                          className={`dropdown-menu pt-0 ${
                            isShowDropdownOpcoes === cliente.id ? "show" : ""
                          }`}
                        >
                          <NavLink
                            to={"#"}
                            className={[
                              "dropdown-item btn align-items-center col col-12 pt-2 pb-2",
                              Number(cliente.valorComprado || 0) > 0
                                ? "btn-primary"
                                : "btn-danger",
                            ].join(" ")}
                            onClick={() => changeActiveClient(cliente, "/")}
                          >
                            <FaBox className="mr-2" />
                            Criar pedido
                          </NavLink>
                          <div className="dropdown-divider mt-0"></div>

                          <ButtonContatoCliente
                            origin="cliente"
                            nameButton={"Contato"}
                            codigo={cliente.codigo}
                            loja={cliente.loja}
                            cnpj={cliente.cnpj}
                            nome={cliente.nome}
                            userCodigo={user!.codigo}
                            userTipo={user!.tipo!.nome}
                            setClientes={setClientes}
                            setIsShowDropdownOpcoes={setIsShowDropdownOpcoes}
                            props="dropdown-item btn align-items-center col col-12 m-0 pt-0 "
                          />

                          {/*<button
                            //disabled={isDisabled}
                            type="button"
                            //value={""} //dataCadastro}
                            className="dropdown-item btn align-items-center col col-12 m-0 pt-0 pb-0"
                            title="Histórico dos contatos"
                            name="historico"
                            onClick={() =>
                              onShowAgendaHistorico(
                                cliente.codigo,
                                cliente.loja
                              )
                            }
                            //onChange={(e) => setDataCadastro(e.target.value)}
                          >
                            <FiEye className="mr-2" />
                            Histórico
                          </button>*/}
                          <ButtonContatoHistorico
                            origin="cliente"
                            nome={cliente.nome}
                            loja={cliente.loja}
                            codigo={cliente.codigo}
                            setIsShowDropdownOpcoes={setIsShowDropdownOpcoes}
                            className="dropdown-item btn align-items-center col col-12 m-0 "
                          />

                          <ButtonContatoAnexos
                            tipo={"1"}
                            codigo={cliente.codigo}
                            setIsShowDropdownOpcoes={setIsShowDropdownOpcoes}
                            props="btn-default dropdown-item d-flex align-items-center mr-2"
                          />

                          {/* <button
                            onClick={() => {}}
                            className="btn-default dropdown-item d-flex align-items-center mr-2"
                          >
                            <FiEye className="mr-2" />
                            Históricos
                          </button> */}

                          <div className="dropdown-divider"></div>
                          <button
                            onClick={() =>
                              changeActiveClient(cliente, "/minhaconta/pedidos")
                            }
                            className="btn-default dropdown-item d-flex align-items-center mr-2"
                          >
                            <FaShoppingCart className="mr-2" />
                            Meus Pedidos
                          </button>
                          <button
                            //to="#"
                            onClick={() =>
                              changeActiveClient(
                                cliente,
                                "/minhaconta/orcamentos"
                              )
                            }
                            className="dropdown-item d-flex align-items-center mr-2"
                          >
                            <FaBuffer className="mr-2" />
                            Meus Orçamentos
                          </button>
                          <button
                            //to="#"
                            onClick={() =>
                              changeActiveClient(cliente, "/minhaconta/faturas")
                            }
                            className="dropdown-item d-flex align-items-center mr-2"
                          >
                            <FaMoneyBillAlt className="mr-2" />
                            Faturamento em aberto
                          </button>
                          <button
                            //to="#"
                            onClick={() =>
                              changeActiveClient(
                                cliente,
                                "/minhaconta/controlecoletas"
                              )
                            }
                            className="dropdown-item d-flex align-items-center mr-2"
                          >
                            <FaBoxes size={24} className="mr-2" />
                            Controle Coleta
                          </button>
                          <button
                            //to="#"
                            onClick={() =>
                              changeActiveClient(
                                cliente,
                                "/minhaconta/enderecos"
                              )
                            }
                            className="dropdown-item d-flex align-items-center mr-2"
                          >
                            <FaRegBuilding className="mr-2" />
                            Meus endereços
                          </button>

                          <button
                            //to="#"
                            onClick={() =>
                              changeActiveClient(
                                cliente,
                                "/minhaconta/dadoscadastrais"
                              )
                            }
                            className="dropdown-item d-flex align-items-center mr-2"
                          >
                            <FaRegIdCard className="mr-2" />
                            Meu cadastro
                          </button>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://maps.google.com/maps?q=${cliente.endereco}+${cliente.municipio}+${cliente.uf}`}
                            className={[
                              "dropdown-item btn align-items-center col col-12 pt-2 pb-2",
                              Number(cliente.valorComprado || 0) > 0
                                ? "btn-primary"
                                : "btn-danger",
                            ].join(" ")}
                            onClick={() => setIsShowDropdownOpcoes(0)}
                          >
                            <FaMapMarkedAlt className="mr-2" />
                            Mapa
                          </a>
                          <div className="dropdown-divider"></div>
                          <button
                            //to="#"
                            onClick={() =>
                              changeActiveClient(
                                cliente,
                                "/minhaconta/alterarsenha"
                              )
                            }
                            className="dropdown-item d-flex align-items-center mr-2"
                          >
                            <FiLock className="mr-2" />
                            Alterar senha
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="alert alert-info">
            {searchText === ""
              ? "Nenhum cliente cadastrado."
              : "Nenhum cliente encontrado."}
          </div>
        )}
      </div>
    </div>
  );
};

export default Clientes;
