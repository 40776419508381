//JL-HF_2022-06
import React, { useEffect, useState } from "react";
import { FiSave, FiX } from "react-icons/fi";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import Input from "../Input";
import { ItemPedido, User } from "../../typings";
import { createOrcamento } from "../../services/api";

//import { useHistory } from 'react-router-dom';
//import { useAppContext } from '../../contexts/app';
//import Select from 'react-select';
//import { createPedido } from '../../services/api';
//import PedidoMessageFrete from '../PedidoMessageFrete';

interface OrcamentoResumoProps {
  quantidadeItens: number;
  condicaoPagamento: string;
  valorTotalSemImpostos: number;
  valorTotalComImpostos: number;
  valorTotalDescontos: number;
  ipiTotal: number;
  stTotal: number;
  itens: ItemPedido[];
  user: User | null;
  history: any;
  onCancel(): void;
  //onConfirm(response: AxiosResponse): void;
}

const OrcamentoResumo: React.FC<OrcamentoResumoProps> = ({
  quantidadeItens,
  condicaoPagamento,
  valorTotalSemImpostos,
  valorTotalComImpostos,
  valorTotalDescontos,
  ipiTotal,
  stTotal,
  itens = [],
  history,
  user,
  onCancel = () => {},
}) => {
  const [isDisabled, setIsDisabled] = useState(valorTotalDescontos > 0);
  const [justificativaDesconto, setJustificativaDesconto] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [justificativaDescontoError, setJustificativaDescontoError] = useState(
    ""
  );
  const [freteFOB, setFreteFOB] = useState(false);
  const qtdCasas = "00000";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function validateJustificativaDesconto() {
    let isValid = true;
    setJustificativaDescontoError("");

    if (justificativaDesconto === "" && valorTotalDescontos > 0) {
      isValid = false;

      setJustificativaDescontoError("Preenchimento obrigatório");
    } else {
      if (justificativaDesconto.length < 3 && valorTotalDescontos > 0) {
        isValid = false;

        setJustificativaDescontoError("Digite pelo menos 3 caracteres");
      }
    }

    setIsDisabled(isValid);

    return isValid;
  }

  useEffect(() => {
    const timer = setTimeout(async () => {
      validateJustificativaDesconto();
    }, 1000);

    return () => clearTimeout(timer);
  }, [setIsDisabled, validateJustificativaDesconto]);

  function scrollToTop() {
    const swalContainer = document.querySelector(".swal2-container");

    if (swalContainer) {
      swalContainer.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  async function handleSubmit(enviaAprovacao: boolean) {
    //e?: FormEvent) {
    //e.preventDefault();

    try {
      setErrorMessage("");

      if (valorTotalDescontos > 0 && !validateJustificativaDesconto()) {
        scrollToTop();

        return false;
      }

      setLoading(true);

      const filterItens = itens.filter(
        (item) =>
          item.quantidadeComprar > 0 && {
            codigo: item.produto.codigo,
            quantidade: item.quantidadeComprar,
            percentualDesconto: item.percentualDesconto,
            entrega2Data: item.entrega2Data,
          }
      );

      const response = await createOrcamento({
        condicaoPagamento: condicaoPagamento.split("-")[0].trim(),
        pedidoCliente: "",
        pedidoRepresentante: "",
        justificativaDesconto:
          valorTotalDescontos > 0
            ? justificativaDesconto
            : "Orçamento sem desconto adicional.",
        valorTotalSemImpostos: Number(valorTotalSemImpostos.toFixed(2)),
        valorTotalComImpostos: Number(valorTotalComImpostos.toFixed(2)),
        valorTotalDescontos: Number(valorTotalDescontos.toFixed(2)),
        ipiTotal: Number(ipiTotal.toFixed(2)),
        stTotal: Number(stTotal.toFixed(2)),
        quantidadeItens,
        enviaAprovacao,
        tipoFrete: freteFOB ? "F" : "C", //F = FOB | C = CIF
        itens: filterItens.map((item) => ({
          codigo: item.produto.codigo,
          quantidade: item.quantidadeComprar,
          percentualDesconto: item.percentualDesconto,
          entrega2Data: item.entrega2Data,
        })),
      });

      setLoading(false);

      if (response.data) {
        //onConfirm(response);
        return response;
      }
    } catch (error) {
      scrollToTop();

      let message =
        "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.";

      setLoading(false);

      if (error?.response?.data?.message) {
        message = error?.response?.data?.message;
      }

      setErrorMessage(message);

      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  }

  function formatIdOrcamento(id: number) {
    const formatId = String(qtdCasas.slice(String(id).length)) + id;

    return formatId;
  }

  async function salvarOrcamento() {
    try {
      setLoading(true);

      const response = await handleSubmit(false);

      setLoading(false);

      if (response) {
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          html: `<div>
                  <p>Orçamento foi salvo!</p>
                  <p><b>Orçamento:</b> ${formatIdOrcamento(
                    response.data.id
                  )}</p>
                  <h3>OBRIGADO!</h3>
                </div>`,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
          onClose: () => {
            history.push("/minhaconta/orcamentos");
          },
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Erro",
        text:
          "Falha ao enviar sua solicitação de aprovação do orçamento, tente mais tarde!",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });
    }
  }

  async function salvarESolicitarAprovacao() {
    try {
      setLoading(true);

      const response = await handleSubmit(true);

      setLoading(false);

      if (response) {
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          html: `<div>
                 <p>Solicitação foi enviada!</p>
                <p><b>Orçamento:</b> ${formatIdOrcamento(response.data.id)}</p>
                 <h3>OBRIGADO!</h3>
                </div>`,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
          onClose: () => {
            history.push("/minhaconta/orcamentos");
          },
        });
      }
      //hideLoader(loader);
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Erro",
        text:
          "Falha ao enviar sua solicitação de aprovação do orçamento, tente mais tarde!",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });
    }
  }

  async function handleSolicitarAprovacao() {
    const ReactSwal = withReactContent(Swal);

    if (valorTotalDescontos > 0) {
      const response = await ReactSwal.fire({
        title: "Detalhe do produto",
        html: "<p>Deseja enviar este orçamento para analise e aprovação?</p>",
        width: "800px",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      });

      if (response.isConfirmed) {
        salvarESolicitarAprovacao();
      } else {
        salvarOrcamento();
      }
    } else {
      salvarOrcamento();
    }
  }

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(255, 255, 255, 0.75)",
            zIndex: 1000,
          }}
        >
          <div className="spinner-border text-info"></div>
          <div className="text-primary mt-4">
            Enviando solicitação orçamento.
          </div>
        </div>
      )}
      <form onReset={() => onCancel()} style={{ position: "relative" }}>
        <fieldset disabled={loading}>
          {errorMessage && (
            <div className="alert alert-danger text-left">{errorMessage}</div>
          )}

          {valorTotalDescontos > 0 && (
            <Input
              label="Justificativa do desconto aplicado"
              name="justificativaDesconto"
              value={justificativaDesconto}
              onChange={(e) => setJustificativaDesconto(e.target.value)}
              maxLength={300}
              errorMessage={justificativaDescontoError}
              onBlur={() => validateJustificativaDesconto()}
            />
          )}

          <div className="form-group d-flex align-items-center justify-content-between">
            <label>Qual tipo de frete?</label>
            <div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="freteFOBFalse"
                  value="false"
                  checked={freteFOB === false}
                  onChange={(e) => setFreteFOB(false)}
                />
                <label className="form-check-label" htmlFor="freteFOBFalse">
                  CIF
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="freteFOBTrue"
                  value="true"
                  checked={freteFOB === true}
                  onChange={(e) => setFreteFOB(true)}
                />
                <label className="form-check-label" htmlFor="freteFOBTrue">
                  FOB
                </label>
              </div>
            </div>
          </div>

          <table className="table table-striped table-bordered">
            <tbody>
              <tr>
                <td className="text-left">Quantidade de itens</td>
                <td className="text-right">{quantidadeItens}</td>
              </tr>
              <tr>
                <td className="text-left">Condição de pagamento</td>
                <td className="text-right">{condicaoPagamento}</td>
              </tr>
              {valorTotalDescontos > 0 && (
                <tr>
                  <td className="text-left">Total de descontos</td>
                  <td className="text-right">
                    {valorTotalDescontos.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                </tr>
              )}
              <tr>
                <td className="text-left">Total sem impostos</td>
                <td className="text-right">
                  {valorTotalSemImpostos.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </td>
              </tr>
              <tr>
                <td className="text-left">Total de IPI</td>
                <td className="text-right">
                  {ipiTotal.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </td>
              </tr>
              <tr>
                <td className="text-left">Total de ST</td>
                <td className="text-right">
                  {stTotal.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </td>
              </tr>
              <tr>
                <td className="text-left">
                  <strong>Total a pagar (com impostos)</strong>
                </td>
                <td className="text-right">
                  {valorTotalComImpostos.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </td>
              </tr>
            </tbody>
          </table>

          <p>Deseja salvar este orçamento?</p>

          <div className="row">
            <div className="col">
              <button
                type="reset"
                className="btn btn-block btn-lg btn-outline-danger d-flex align-items-center justify-content-center mt-3"
              >
                <FiX size={24} className="mr-3" />
                Não
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                disabled={!isDisabled}
                onClick={() => handleSolicitarAprovacao()}
                className="btn btn-block btn-lg btn-outline-primary d-flex align-items-center justify-content-center mt-3"
              >
                <FiSave size={24} className="mr-3" />
                Sim
              </button>
            </div>
            {/*<div className="col">
            <button
              type="submit"
              onClick={() => setIsEnviar(true)}
              className="btn btn-block btn-lg btn-outline-success d-flex align-items-center justify-content-center mt-3">
              <FiThumbsUp size={24} className="mr-3" />
              Sim
            </button>
          </div>*/}
          </div>
        </fieldset>
      </form>
    </>
  );
};

export default OrcamentoResumo;
// function showLoader(arg0: string) {
//   throw new Error('Function not implemented.');
// }
