import React from "react";
import Swal from "sweetalert2";
import { sendEfetivarProspect } from "../../../services/api";
import { FiCheck } from "react-icons/fi";
import { useAppContext } from "../../../contexts/app";

const ButtonEfetivarProspect = ({
  data,
  action,
  handlerEfetivar,
  props,
  setIsShowDropdownOpcoes,
  ...rest
}: any) => {
  const { showLoader, hideLoader } = useAppContext();

  async function handleEfetivar() {
    try {
      const loader = showLoader(
        "Por favor aguarde. Efetivando prospect como cliente."
      );

      await sendEfetivarProspect({ codigo: data.codigo, loja: data.loja });

      setTimeout(() => {
        hideLoader(loader);

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Cadastro prospect efetivado como cliente!",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
          onClose: () => {
            action();
          },
        });
      }, 1200);
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: `Falha ao efetivar cadastro do prospect com cliente, tente mais tarde!`,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });
    }
  }

  async function onShowEfetivar() {
    //e.preventDefault();
    !setIsShowDropdownOpcoes === false ?? setIsShowDropdownOpcoes(0);

    const response = await Swal.fire({
      icon: "question",
      title: "Atenção",
      html: `<p>Deseja Efetivar Cadastro Prospect com Cliente?</p>
                <hr class="mt-4"/>
                <div>
                  <small>
                    <p class="text-left pl-4"><strong>código:</strong> ${data.codigo}<p/>
                    <p class="text-left pl-4"><strong>nome:</strong> ${data.nome}</p>
                  </small>
                </div>
                <hr/>
                `,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: true,
      showConfirmButton: true,
      showCloseButton: true,
      cancelButtonText: "Não",
      confirmButtonText: "Sim",
      onClose: () => {},
    });

    if (response.isConfirmed) {
      handleEfetivar();
    }
  }

  return (
    <button
      type="button"
      //disabled={isDisabledConfirmaPedido}
      {...rest}
      className={props}
      onClick={() => onShowEfetivar()}
      title={"Efetivar cadastro do prospect como cliente."}
    >
      <FiCheck className="mr-2" />
      <span>Efetivar</span>
    </button>
  );
};

export default ButtonEfetivarProspect;
