/*JL-HF_2023-09 */
import { isEqual, isBefore, isAfter } from "date-fns";

import { legendasProspect } from "../utils/legendasData";

import { PROSPECT_BLOQUEADO } from "../entities/TipoUsuario";

function formatDate(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

const legendas: any = legendasProspect;
const dateAtual = formatDate(new Date());
let dateContato = dateAtual;

export default function changeActiveLegenda(
  codigoCliente: string,
  dataProximoContato: string,
  bloqueado: number
) {
  if (bloqueado === PROSPECT_BLOQUEADO && codigoCliente !== "PENDEN") {
    return legendas["bloqueado"];
  }

  if (codigoCliente.length === 0) {
    dateContato = formatDate(new Date(dataProximoContato));

    if (isEqual(dateAtual, dateContato)) {
      return legendas["hoje"];
    } else if (isBefore(dateAtual, dateContato)) {
      return legendas["futuro"];
    } else if (isAfter(dateAtual, dateContato)) {
      return legendas["atrasado"];
    }
  }

  if (codigoCliente.length > 0 && codigoCliente === "PENDEN") {
    //PENDEN = PENDENTE regra Protheus
    return legendas["pendente"];
  }

  if (parseInt(codigoCliente) > 0) {
    return legendas["efetivado"];
  }

  return legendas["pendente"];
}
