import { LegendaProps } from "../typings";

export interface tipoProps {
  tipo: "futuro" | "atrasado" | "hoje" | "pendente" | "efetivado" | "bloqueado";
}

export const legendasProspect: LegendaProps = {
  futuro: {
    color: `var(--color-verde-500)`,
    legenda: "Próximo Contato Futuro",
  },
  atrasado: {
    color: `var(--color-vermelho-500)`,
    legenda: "Próximo Contato Atrasado",
  },
  hoje: {
    color: `var(--color-amarelo-500)`,
    legenda: "Próximo Contato Hoje",
  },
  pendente: {
    color: `var(--color-azul-500)`,
    legenda: "Solicitação ce Efetivação Pendente",
  },
  efetivado: {
    color: `var(--color-marrom-500)`,
    legenda: "Prospect Efetivado em Cliente",
  },
  bloqueado: {
    color: `var(--color-cinza-500)`,
    legenda: "Prospect Bloqueado",
  },
};

export const legendasCliente: LegendaProps = {
  futuro: {
    color: `var(--color-verde-500)`,
    legenda: "Próximo Contato Futuro",
  },
  atrasado: {
    color: `var(--color-vermelho-500)`,
    legenda: "Próximo Contato Atrasado",
  },
  hoje: {
    color: `var(--color-amarelo-500)`,
    legenda: "Próximo Contato Hoje",
  },
  /*pendente: {
    color: `var(--color-azul-500)`,
    legenda: "Solicitação ce Efetivação Pendente",
  },
  efetivado: {
    color: `var(--color-marrom-500)`,
    legenda: "Prospect Efetivado em Cliente",
  },
  bloqueado: {
    color: `var(--color-cinza-500)`,
    legenda: "Prospect Bloqueado",
  },*/
};
