import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { FaSort } from "react-icons/fa";
import {
  FiChevronDown,
  FiChevronUp,
  FiEdit3,
  FiEye,
  //FiPaperclip,
  FiPlusCircle,
  FiSearch,
  FiSlash,
  FiUserPlus,
} from "react-icons/fi";

import { BsCircleFill, BsFillInfoCircleFill } from "react-icons/bs";
import Swal from "sweetalert2";

import { useAppContext } from "../../contexts/app";
import { fetchProspect } from "../../services/api";
import withReactContent from "sweetalert2-react-content";
import ButtonExcluirProspect from "./Excluir";
import ButtonEfetivarProspect from "./Efetivar";

import ButtonContatoProspect from "./Contato";
import Legendas from "../../components/Legendas";

import cnpjCpfMaskCustom from "../../utils/formatarCnpjCpf";
import { legendasProspect } from "../../utils/legendasData";
import changeActiveLegenda from "../../utils/changeActiveLengenda";

import { LegendaProps, ProspectProps } from "../../typings";
import { acaoFormProspect } from "../../entities/actionForm";
import {
  TIPOPESSOA,
  TipoUsuario,
  PROSPECT_BLOQUEADO,
} from "../../entities/TipoUsuario";
import ButtonContatoAnexos from "../../components/ButtonContatoAnexos";
import ButtonContatoHistorico from "../../components/ButtonContatoHistorico";

/*function formatDate(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}*/

const Prospects = () => {
  const legendas: LegendaProps = legendasProspect;
  //const dateAtual = formatDate(new Date());
  //let dateContato = dateAtual;
  const [searchText, setSearchText] = useState("");
  const [prospects, setProspects] = useState<ProspectProps[]>([]);
  const [filteredProspects, setFilteredProspects] = useState<ProspectProps[]>(
    []
  );
  const { user, showLoader, hideLoader, setUserCliente } = useAppContext();

  const sortParam = useRef("nome");
  const sortOrder = useRef("asc");

  const history = useHistory();

  /*const yesterday = useMemo(() => {
    const date = new Date();

    date.setDate(date.getDate() - 1);

    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;

    return `${day}/${month}`;
  }, []);*/

  // eslint-disable-next-line
  async function fetchListProspect() {
    try {
      const loader = showLoader(
        "Carregando sua lista de prospect. Por favor aguarde."
      );

      setUserCliente(null);

      const response = await fetchProspect(
        `${TipoUsuario[user!.tipo.nome.toLowerCase()].perfil}${user?.codigo}`
      );

      /*console.log(">>LISTA");
      console.log(response);*/

      if (response.length > 0) {
        setProspects(response); //response.data.items);
        const field = "codigo";
        const sorted = response.sort((a: ProspectProps, b: ProspectProps) => {
          if (sortOrder.current === "asc") {
            return a[field] > b[field] ? 1 : -1;
          } else {
            return a[field] < b[field] ? 1 : -1;
          }
        });

        const merged: any = await transformItems(sorted);

        setFilteredProspects(merged);

        /*response.forEach((element) => {
          console.log(">>RESPONSE");
          console.log(element);
        });*/
      }

      hideLoader(loader);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Erro",
        text:
          "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });

      console.log(error);
    }
  }

  async function sortBy(
    field: "codigo" | "nome" | "municipio" | "uf" | "cnpj"
  ) {
    const items = [...prospects];
    if (sortParam.current === field) {
      sortOrder.current = sortOrder.current === "asc" ? "desc" : "asc";
    } else {
      sortParam.current = field;
      sortOrder.current = "asc";
    }

    const sorted = items.sort((a, b) => {
      if (sortOrder.current === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });

    //setClientes(sorted);
    const merged: any = await transformItems(sorted);

    setFilteredProspects(merged);
  }

  async function transformItems(itens: ProspectProps[]) {
    const merged = await itens.map((item: ProspectProps) => {
      const formatItem = {
        ...item,
        cnpj: cnpjCpfMaskCustom(item.cnpj),
        pessoa: TIPOPESSOA[item.pessoa],
        dataProximoContato: new Date(
          item.dataProximoContato
        ).toLocaleDateString("pt-BR"),
        ativo:
          item.codigoCliente === "PENDEN" ||
          parseInt(item.bloqueado) === PROSPECT_BLOQUEADO,
        legenda: changeActiveLegenda(
          item.codigoCliente,
          item.dataProximoContato,
          parseInt(item.bloqueado)
        ),
      } as ProspectProps;

      return formatItem;
    });

    return merged; //.filter((item) => item.codigo == "002111");
  }

  /*function changeActiveLegenda(
    codigoCliente: string,
    dataProximoContato: string,
    bloqueado: number
  ) {
    if (bloqueado === propectBloqueado) {
      return legendas["bloqueado"];
    }

    if (codigoCliente.length === 0) {
      dateContato = formatDate(new Date(dataProximoContato));

      if (isEqual(dateAtual, dateContato)) {
        return legendas["hoje"];
      } else if (isBefore(dateAtual, dateContato)) {
        return legendas["futuro"];
      } else if (isAfter(dateAtual, dateContato)) {
        return legendas["atrasado"];
      }
    }

    if (codigoCliente.length > 0 && codigoCliente === "PENDEN") {
      //PENDEN = PENDENTE regra Protheus
      return legendas["pendente"];
    }

    if (parseInt(codigoCliente) > 0) {
      return legendas["efetivado"];
    }

    return legendas["pendente"];
  }*/

  const handleSearch = useCallback(
    async (e: FormEvent | null) => {
      if (e) {
        e.preventDefault();
      }

      const filtered = prospects.filter((prospect) => {
        const codigo = prospect.codigo; //JL-HF_2023-02
        const nome = prospect.nome.toLowerCase();
        const cnpj = prospect.cnpj.toLowerCase();

        return (
          cnpj.includes(searchText.toLowerCase()) ||
          nome.includes(searchText.toLowerCase()) ||
          codigo.includes(searchText.toLocaleLowerCase())
        );
      });
      const merged: any = await transformItems(filtered);
      setFilteredProspects(merged);
    },
    [prospects, searchText]
  );

  const handleReset = useCallback(() => {
    setSearchText("");
    setFilteredProspects(prospects);
  }, [prospects]);

  const [isShowDropdownOpcoes, setIsShowDropdownOpcoes] = useState<number>(0);

  function setShowDropdownOpcoes(id: number) {
    if (isShowDropdownOpcoes === id) {
      setIsShowDropdownOpcoes(0);
      return;
    }

    setIsShowDropdownOpcoes(id);
  }

  function showLegendaProspect() {
    const ReactSwal = withReactContent(Swal);

    ReactSwal.fire({
      title: "Legendas - Prospect",
      html: <Legendas legendas={legendas} />,
      width: "40%",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: "FECHAR",
    });
  }
  function handleActionCadastro(codigo: string, loja: string, acao: string) {
    history.push("/prospects/cadastro", {
      codigo: codigo,
      loja: loja,
      acao,
    });
  }

  useEffect(() => {
    if (prospects.length === 0) {
      fetchListProspect();
    }
  }, [
    //prospects,
    prospects.length,
    //fetchListProspect,
    //hideLoader,
    //setUserCliente,
    //showLoader,
    //user,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearch(null);
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchText, handleSearch, handleReset]);

  return (
    <div className="container-fluid my-4">
      <div className="">
        <div className="d-block d-md-flex align-items-center justify-content-between">
          <div className="flex-fill">
            <h4 className="text-center text-secondary">
              <FiUserPlus size={24} className="mr-2" />
              LISTA PROSPECT
            </h4>
          </div>
        </div>
      </div>
      <div className="col-md-12 d-flex align-items-center justify-content-end m-0 p-0 mb-2">
        <button
          className="btn btn-primary d-flex align-items-center mr-0 col-md-1"
          onClick={() =>
            handleActionCadastro("", "", acaoFormProspect.cadastro)
          }
        >
          <FiPlusCircle className="mr-2" />
          Incluir
        </button>
      </div>
      <div className="d-flex flex-fill flex-column">
        <form onSubmit={handleSearch} onReset={() => handleReset()}>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Digite o Código, CNPJ ou nome do prospect"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <div className="input-group-append">
              <button
                type="submit"
                className="btn btn-lg btn-primary d-flex align-items-center"
              >
                <FiSearch />
              </button>
              {searchText && (
                <button
                  type="reset"
                  className="btn btn-lg btn-outline-primary d-flex align-items-center"
                >
                  <FiSlash />
                </button>
              )}
            </div>
          </div>
        </form>

        {filteredProspects.length > 0 ? (
          <div className="table-responsive mb-5">
            <table className="table table-striped table-borderless">
              <thead className="thead-dark">
                <tr>
                  <th style={{ width: "1px" }} className="text-center">
                    <button
                      title="Legendas prospect"
                      className="btn btn-default"
                      onClick={() => showLegendaProspect()}
                    >
                      <BsFillInfoCircleFill size={16} color="#fff" />
                    </button>
                  </th>
                  <th
                    style={{ width: "100px", cursor: "pointer" }}
                    onClick={() => sortBy("codigo")}
                  >
                    <div className="d-flex align-items-center">
                      <span className="mr-1">Código</span>
                      {sortParam.current === "codigo" ? (
                        sortOrder.current === "desc" ? (
                          <FiChevronUp />
                        ) : (
                          <FiChevronDown />
                        )
                      ) : (
                        <FaSort />
                      )}
                    </div>
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("nome")}
                  >
                    <div className="d-flex align-items-center">
                      <span className="mr-1">Nome</span>
                      {sortParam.current === "nome" ? (
                        sortOrder.current === "asc" ? (
                          <FiChevronUp />
                        ) : (
                          <FiChevronDown />
                        )
                      ) : (
                        <FaSort />
                      )}
                    </div>
                  </th>
                  <th
                    style={{ width: "1px", cursor: "pointer" }}
                    onClick={() => sortBy("municipio")}
                  >
                    <div className="d-flex align-items-center">
                      <span className="mr-1">Município</span>
                      {sortParam.current === "municipio" ? (
                        sortOrder.current === "asc" ? (
                          <FiChevronUp />
                        ) : (
                          <FiChevronDown />
                        )
                      ) : (
                        <FaSort />
                      )}
                    </div>
                  </th>
                  <th
                    style={{ width: "1px", cursor: "pointer" }}
                    onClick={() => sortBy("uf")}
                  >
                    <div className="d-flex align-items-center">
                      <span className="mr-1">UF</span>
                      {sortParam.current === "uf" ? (
                        sortOrder.current === "asc" ? (
                          <FiChevronUp />
                        ) : (
                          <FiChevronDown />
                        )
                      ) : (
                        <FaSort />
                      )}
                    </div>
                  </th>
                  <th
                    style={{ width: "1px", cursor: "pointer" }}
                    onClick={() => sortBy("cnpj")}
                  >
                    <div className="d-flex align-items-center">
                      <span className="mr-1">CNPJ</span>
                      {sortParam.current === "cnpj" ? (
                        sortOrder.current === "asc" ? (
                          <FiChevronUp />
                        ) : (
                          <FiChevronDown />
                        )
                      ) : (
                        <FaSort />
                      )}
                    </div>
                  </th>
                  <th style={{ width: "1px" }} className="text-center">
                    Tipo
                  </th>
                  <th style={{ width: "1px" }} className="text-center">
                    <div>Data</div>
                    <small>último contato</small>
                  </th>
                  <th className="text-center" style={{ width: "1px" }}>
                    {/* Opções */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredProspects.map((prospect: ProspectProps) => (
                  <tr key={prospect.codigo}>
                    <td className="text-center">
                      {prospect?.legenda && (
                        <BsCircleFill
                          size={16}
                          color={prospect.legenda.color}
                          title={prospect.legenda.legenda}
                        />
                      )}
                    </td>
                    <td style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      {prospect.codigo}-{prospect.loja}
                    </td>
                    <td style={{ fontSize: "14px" }}>{prospect.nome}</td>
                    <td style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      {prospect.municipio.toUpperCase()}
                    </td>
                    <td style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      {prospect.uf.toUpperCase()}
                    </td>
                    <td style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      {prospect.cnpj}
                    </td>
                    <td style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      {String(prospect.pessoa).toUpperCase()}
                    </td>
                    <td style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      {prospect.dataProximoContato}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {/* JL-HF_2023-06 => Add Dropdown*/}
                      <div className="btn-group dropleft col-12">
                        <button
                          type="button"
                          title={
                            !prospect.ativo ? prospect.legenda.legenda : ""
                          }
                          disabled={prospect.ativo}
                          className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() =>
                            setShowDropdownOpcoes(parseInt(prospect.codigo))
                          }
                        >
                          {/* <span className="sr-only">Toggle Dropdown</span> */}
                        </button>
                        <button
                          type="button"
                          title={prospect.ativo ? prospect.legenda.legenda : ""}
                          disabled={prospect.ativo}
                          className="btn btn-primary"
                          onClick={() =>
                            setShowDropdownOpcoes(parseInt(prospect.codigo))
                          }
                        >
                          Opções
                        </button>

                        <div
                          className={`dropdown-menu pt-0 ${
                            isShowDropdownOpcoes === parseInt(prospect.codigo)
                              ? "show"
                              : ""
                          }`}
                        >
                          {/*<ButtonContatoProspect
                            props="dropdown-item btn align-items-center col col-12"
                            codigo={prospect.codigo}
                        />*/}
                          <ButtonContatoProspect
                            props="dropdown-item btn align-items-center col col-12 mt-1 "
                            loja={prospect.loja}
                            nome={prospect.nome}
                            codigo={prospect.codigo}
                            userCodigo={user?.codigo}
                            userTipo={user?.tipo?.nome}
                            nameButton={"Contato"}
                            origin="prospect"
                            cnpj={""}
                            grupo={""}
                            setProspects={setProspects}
                            setIsShowDropdownOpcoes={setIsShowDropdownOpcoes}
                          />

                          <ButtonContatoHistorico
                            origin="prospect"
                            nome={prospect.nome}
                            loja={prospect.loja}
                            codigo={prospect.codigo}
                            setIsShowDropdownOpcoes={setIsShowDropdownOpcoes}
                            className="dropdown-item btn align-items-center col col-12 m-0 "
                          />

                          <ButtonContatoAnexos
                            tipo={"2"}
                            codigo={prospect.codigo}
                            setIsShowDropdownOpcoes={setIsShowDropdownOpcoes}
                            props="btn-default dropdown-item d-flex align-items-center mr-2 mt-1"
                          />

                          <div className="dropdown-divider"></div>
                          <button
                            className="dropdown-item btn align-items-center col col-12"
                            onClick={() =>
                              handleActionCadastro(
                                prospect.codigo,
                                prospect.loja,
                                acaoFormProspect.visualizar
                              )
                            }
                          >
                            <FiEye className="mr-2" />
                            Visualizar
                          </button>
                          <button
                            type="button"
                            className="dropdown-item btn align-items-center col col-12"
                            //onClick={() => changeActiveClient(prospect)}
                            onClick={() =>
                              handleActionCadastro(
                                prospect.codigo,
                                prospect.loja,
                                acaoFormProspect.editar
                              )
                            }
                          >
                            <FiEdit3 className="mr-2" />
                            Editar
                          </button>
                          <ButtonEfetivarProspect
                            data={prospect}
                            action={fetchListProspect}
                            props="dropdown-item d-flex align-items-center mr-2 "
                            setIsShowDropdownOpcoes={setIsShowDropdownOpcoes}
                          />
                          <ButtonExcluirProspect
                            data={prospect}
                            action={fetchListProspect}
                            setIsShowDropdownOpcoes={setIsShowDropdownOpcoes}
                            props="dropdown-item d-flex align-items-center mr-2 "
                          />
                          {/* <div className="dropdown-divider"></div>
                          <NavLink
                            to="/minhaconta/pedidos"
                            className="dropdown-item d-flex align-items-center mr-2"
                            title="Anexos de arquivos"
                          >
                            <FiPaperclip className="mr-2" />
                            Anexos
                          </NavLink> */}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="alert alert-info">
            {searchText === ""
              ? "Nenhum prospect cadastrado."
              : "Nenhum prospect encontrado."}
          </div>
        )}
      </div>
    </div>
  );
};

export default Prospects;
