/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, MouseEvent, useState, useEffect, useRef } from 'react';
import { FiPlus, FiMinus, FiGrid, FiTrash2, FiZoomIn } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';

import { ItemPedido } from '../../../typings';
import ENV from '../../../ENV';
//import { useAppContext } from '../../../contexts/app';

import styles from './ItemPedidoResgate.module.css';

interface ItemPedidoProps {
  itemPedido: ItemPedido;
  onCheck(): void;
  onUncheck(): void;
  onChangeQuantidade(novaQuantidade: number): void;
  onShowDetalheProduto(): void;
  onShowHistoricoItem(): void;
  onShowDetalheItem(): void;
  onChangeDesconto(): void;
  onRemove(): void;
}

const RowPedido: React.FC<ItemPedidoProps> = ({
  itemPedido,
  onCheck = () => {},
  onUncheck = () => {},
  onChangeQuantidade = () => {},
  onShowDetalheProduto = () => {},
  onShowHistoricoItem = () => {},
  onShowDetalheItem = () => {},
  onChangeDesconto = () => {},
  onRemove = () => {},
}) => {
  const [quantidade, setQuantidade] = useState(itemPedido.quantidadeComprar);
  //const { user } = useAppContext();

  const shouldUpdateItem = useRef(false);

  const imageProduto = useMemo(() => {
    return `${ENV.ASSETS_URL}/${itemPedido.produto?.codigo}.jpg`;
  }, [itemPedido]);

  /*const precoTotalSemImpostosFormatado = useMemo(() => {
    return itemPedido.precoTotalSemImpostos.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }, [itemPedido.precoTotalSemImpostos]);*/

  const precoTotalComImpostosFormatado = useMemo(() => {
    if (itemPedido.impostoCalculado) {
      return itemPedido.precoTotalComImpostos.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    }

    return 'A calcular';
  }, [itemPedido.impostoCalculado, itemPedido.precoTotalComImpostos]);

  function handleShowProdutoDetail(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();

    onShowDetalheProduto();
  }

  async function handleShowProdutoHistory() {
    onShowHistoricoItem();
  }

  function handleShowItemPedidoDetail() {
    onShowDetalheItem();
  }

  async function changeQuantidade(value: number) {
    if (isNaN(value)) {
      return;
    }

    shouldUpdateItem.current = true;

    setQuantidade(value);
  }

  /*function handleAddDesconto() {
        onChangeDesconto();
    }*/

  function handleRemove() {
    onRemove();
  }

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (shouldUpdateItem.current) {
        onChangeQuantidade(quantidade);

        shouldUpdateItem.current = false;
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [onChangeQuantidade, quantidade]);

  return (
    <>
      <ReactTooltip type="info" place="left" />

      <div className={[styles.itemContainer, 'd-flex border-top border-left border-right border-primary'].join(' ')}>
        <div className="d-flex flex-fill align-items-center border-right border-primary p-2">
          <div className="pl-2 pr-3">
            <input
              type="checkbox"
              style={{
                width: '20px',
                height: '20px',
              }}
              onChange={(e) => (e.target.checked ? onCheck() : onUncheck())}
            />
          </div>

          <a href="" className="text-decoration-none" onClick={(e) => handleShowProdutoDetail(e)}>
            <img src={imageProduto} width="50" alt={itemPedido.produto.titulo} />
            <div>
              <div>
                <strong style={{ wordBreak: 'break-word' }}>{itemPedido.produto.titulo}</strong>
              </div>
              <div>
                <small>MODELO: {itemPedido.produto.modelo}</small>
              </div>
              <div>
                <small>CÓDIGO: {itemPedido.produto.codigo}</small>
              </div>
            </div>
          </a>
        </div>

        <div
          className="d-flex flex-fill flex-column border-right border-primary"
          style={{ minWidth: 100, maxWidth: 100 }}>
          <div className="bg-light p-1 text-center border-bottom border-primary">
            <strong>Quantidade</strong>
          </div>
          <div className="d-flex flex-fill flex-column justify-content-center">
            <div className="d-flex flex-column justify-content-center p-1">
              <small className="text-center">Sugerida:</small>
              <small className="text-center">{itemPedido.quantidadeSugerida} un.</small>
            </div>
            <div className="d-flex flex-column justify-content-center p-1">
              <small className="text-center">Compras no mês:</small>
              <small className="text-center">{itemPedido.quantidadeMes} un.</small>
            </div>
          </div>
        </div>

        <div
          className="d-flex flex-fill flex-column border-right border-primary"
          style={{ minWidth: 150, maxWidth: 150 }}>
          <div className="bg-light p-1 text-center border-bottom border-primary">
            <strong>Comprar</strong>
          </div>
          <div className="d-flex flex-fill align-items-center p-2">
            <div className="input-group input-group-sm">
              <div className="input-group-prepend">
                <button
                  type="button"
                  className="btn btn-outline-secondary d-flex align-items-center"
                  onClick={() => changeQuantidade(quantidade - 1)}>
                  <FiMinus />
                </button>
              </div>
              <input
                type="text"
                className="form-control text-center"
                value={quantidade}
                maxLength={5}
                onChange={(e) => changeQuantidade(Number(e.target.value))}
              />
              <div className="input-group-append">
                <button
                  type="button"
                  className="btn btn-outline-secondary d-flex align-items-center"
                  onClick={() => changeQuantidade(quantidade + 1)}>
                  <FiPlus />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="d-flex flex-fill flex-column border-right border-primary"
          style={{ minWidth: 100, maxWidth: 100 }}>
          <div className="bg-light p-1 text-center border-bottom border-primary">
            <strong>Preço Base</strong>
          </div>
          <div className="d-flex flex-fill flex-column justify-content-center position-relative">
            <div className="d-flex flex-column justify-content-center p-1">
              <small className="text-center">De:</small>
              <small className="text-center">{itemPedido.precoBaseFormatado}</small>
            </div>
            <div className="d-flex flex-column justify-content-center p-1">
              <small className="text-center">Por:</small>
              <small className="text-center">{itemPedido.precoPorFormatado}</small>
            </div>

            {itemPedido.percentualDesconto !== undefined && itemPedido.percentualDesconto !== 0 && (
              <span
                className={['badge p-1', itemPedido.percentualDesconto > 0 ? 'badge-info' : 'badge-warning'].join(' ')}
                style={{ position: 'absolute', top: 0, right: 0, left: 0 }}>
                {itemPedido.percentualDesconto > 0 ? 'DESCONTO' : 'ACRÉSCIMO'}:{' '}
                {Math.abs(itemPedido.percentualDesconto)}%
              </span>
            )}
          </div>
        </div>

        <div
          className="d-flex flex-fill flex-column border-right border-primary"
          style={{ minWidth: 80, maxWidth: 80 }}>
          <div className="bg-light p-1 text-center border-bottom border-primary">
            <strong>IPI</strong>
          </div>
          <div className="d-flex flex-fill flex-column align-items-center justify-content-center">
            {itemPedido.impostoCalculado && <small className="p-1">{itemPedido.percentualIpiFormatado}</small>}
            <small className="p-1">
              {itemPedido.impostoCalculado ? (
                <>
                  {itemPedido.ipi.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </>
              ) : (
                <>A calcular</>
              )}
            </small>
          </div>
        </div>

        <div
          className="d-flex flex-fill flex-column border-right border-primary"
          style={{ minWidth: 100, maxWidth: 100 }}>
          <div className="bg-light p-1 text-center border-bottom border-primary">
            <strong>Subst. Trib.</strong>
          </div>
          <div className="d-flex flex-fill flex-column align-items-center justify-content-center">
            {itemPedido.impostoCalculado && <small className="p-1">{itemPedido.percentualStFormatado}</small>}
            <small className="p-1">
              {itemPedido.impostoCalculado ? (
                <>
                  {itemPedido.st.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </>
              ) : (
                <>A calcular</>
              )}
            </small>
          </div>
        </div>

        <div
          className="d-flex flex-fill flex-column border-right border-primary"
          style={{ minWidth: 150, maxWidth: 150 }}>
          <div className="bg-light p-1 text-center border-bottom border-primary">
            <strong>Valor Total</strong>
          </div>
          <div className="d-flex flex-fill flex-column justify-content-center position-relative">
            <div className="d-flex flex-column justify-content-center p-1">
              <small className="text-center">Sem impostos:</small>
              <strong className="text-center">
                {itemPedido.precoPor.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </strong>
            </div>
            <div className="d-flex flex-column justify-content-center p-1">
              <small className="text-center">Com impostos:</small>
              <strong className="text-center">{precoTotalComImpostosFormatado}</strong>
            </div>

            {itemPedido.percentualDesconto !== undefined && itemPedido.percentualDesconto !== 0 && (
              <span
                className={['badge p-1', itemPedido.percentualDesconto > 0 ? 'badge-info' : 'badge-warning'].join(' ')}
                style={{ position: 'absolute', top: 0, right: 0, left: 0 }}>
                {itemPedido.percentualDesconto > 0 ? 'DESCONTO' : 'ACRÉSCIMO'}:{' '}
                {Math.abs(itemPedido.percentualDesconto)}%
              </span>
            )}
          </div>
        </div>

        <div
          className="d-flex flex-fill flex-column border-right border-primary"
          style={{ minWidth: 130, maxWidth: 130 }}>
          <div className="bg-light p-1 text-center border-bottom border-primary">
            <strong>Entregas</strong>
          </div>
          <div className="d-flex flex-fill flex-column justify-content-center">
            <div className="d-flex p-1">
              <small className="flex-fill">{itemPedido.entrega1Quantidade} un.</small>
              <small>
                {itemPedido.entrega1Data
                  ? new Date(itemPedido.entrega1Data).toLocaleDateString('pt-BR')
                  : 'A confirmar'}
              </small>
            </div>
            <div className="d-flex p-1">
              <small className="flex-fill">{itemPedido.entrega2Quantidade} un.</small>
              <small>
                {itemPedido.entrega2Data
                  ? new Date(itemPedido.entrega2Data).toLocaleDateString('pt-BR')
                  : 'A confirmar'}
              </small>
            </div>
          </div>
        </div>

        <div className="d-flex flex-fill flex-column" style={{ minWidth: 50, maxWidth: 50 }}>
          <button
            type="button"
            className="btn btn-sm btn-link d-flex flex-fill align-items-center justify-content-center my-1"
            data-tip="Exibe o histórico de compras deste item"
            onClick={() => handleShowProdutoHistory()}>
            <FiGrid className="text-primary" size={24} />
          </button>
          <button
            type="button"
            className="btn btn-sm btn-link d-flex flex-fill align-items-center justify-content-center my-1"
            data-tip="Exibe os detalhes do item"
            onClick={() => handleShowItemPedidoDetail()}>
            <FiZoomIn className="text-success" size={24} />
          </button>

          <button
            type="button"
            className="btn btn-sm btn-link d-flex flex-fill align-items-center justify-content-center my-1"
            data-tip="Remove o item"
            onClick={() => handleRemove()}>
            <FiTrash2 className="text-danger" size={24} />
          </button>
        </div>
      </div>
    </>
  );
};

export default RowPedido;
