import React, { FormEvent, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Swal from 'sweetalert2';

import Input from '../../../components/Input';
import SideBar from '../SideBar';
import { useAppContext } from '../../../contexts/app';
import { changePassword } from '../../../services/api';

const AlterarSenha = () => {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] = useState(false);
  const [errors, setErrors] = useState({
    password: '',
    newPassword: '',
    newPasswordConfirmation: '',
  });
  const { showLoader, hideLoader } = useAppContext();

  function validate() {
    let isValid = true;

    setErrors(() => ({
      password: '',
      newPassword: '',
      newPasswordConfirmation: '',
    }));

    if (password === '') {
      isValid = false;

      setErrors((values) => ({ ...values, password: 'Preenchimento obrigatório' }));
    }

    if (newPassword === '') {
      isValid = false;

      setErrors((values) => ({ ...values, newPassword: 'Preenchimento obrigatório' }));
    }

    if (newPasswordConfirmation === '') {
      isValid = false;

      setErrors((values) => ({ ...values, newPasswordConfirmation: 'Preenchimento obrigatório' }));
    }

    if (newPassword !== newPasswordConfirmation) {
      isValid = false;

      setErrors((values) => ({
        ...values,
        newPassword: 'As senhas não são iguais',
        newPasswordConfirmation: 'As senhas não são iguais',
      }));
    }

    return isValid;
  }

  async function handleFormSubmit(e: FormEvent) {
    e.preventDefault();

    if (validate()) {
      const loader = showLoader('Processando solicitação.');

      try {
        await changePassword({ senha: password, novaSenha: newPassword });

        hideLoader(loader);

        Swal.fire({
          title: 'Senha alterada com sucesso!',
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: 'OK',
        });
      } catch (error) {
        let errorMessage = 'Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.';

        if (error?.response?.data?.message) {
          errorMessage = error?.response?.data?.message;
        }

        Swal.fire({
          icon: 'warning',
          title: 'Erro',
          text: errorMessage,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: 'OK',
        });
      }
    }
  }

  return (
    <div className="container-fluid my-4">
      <div className="row">
        <div className="col-md-3">
          <SideBar />
        </div>
        <div className="col-md-9 mt-4 mt-md-0">
          <h3>Alteração de senha</h3>

          <hr />

          <div className="row">
            <div className="col-md-6 offset-md-3">
              <form onSubmit={handleFormSubmit}>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  label="Digite a senha atual"
                  name="password"
                  value={password}
                  className="form-control-lg"
                  onChange={(e) => setPassword(e.target.value)}
                  errorMessage={errors.password}
                  onBlur={() => validate()}
                  append={
                    <span className="position-absolute" style={{ right: '16px' }}>
                      {showPassword ? (
                        <FaEyeSlash onClick={() => setShowPassword(false)} />
                      ) : (
                        <FaEye onClick={() => setShowPassword(true)} />
                      )}
                    </span>
                  }
                />

                <Input
                  type={showNewPassword ? 'text' : 'password'}
                  label="Digite a nova senha"
                  name="newpassword"
                  value={newPassword}
                  className="form-control-lg"
                  onChange={(e) => setNewPassword(e.target.value)}
                  errorMessage={errors.newPassword}
                  onBlur={() => validate()}
                  append={
                    <span className="position-absolute" style={{ right: '16px' }}>
                      {showNewPassword ? (
                        <FaEyeSlash onClick={() => setShowNewPassword(false)} />
                      ) : (
                        <FaEye onClick={() => setShowNewPassword(true)} />
                      )}
                    </span>
                  }
                />

                <Input
                  type={showNewPasswordConfirmation ? 'text' : 'password'}
                  label="Digite a nova senha novamente"
                  name="newpassword"
                  value={newPasswordConfirmation}
                  className="form-control-lg"
                  onChange={(e) => setNewPasswordConfirmation(e.target.value)}
                  errorMessage={errors.newPasswordConfirmation}
                  onBlur={() => validate()}
                  append={
                    <span className="position-absolute" style={{ right: '16px' }}>
                      {showNewPasswordConfirmation ? (
                        <FaEyeSlash onClick={() => setShowNewPasswordConfirmation(false)} />
                      ) : (
                        <FaEye onClick={() => setShowNewPasswordConfirmation(true)} />
                      )}
                    </span>
                  }
                />

                <button type="submit" className="btn btn-block btn-primary btn-lg">
                  CONFIRMAR
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlterarSenha;
