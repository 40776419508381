import React, { useMemo } from 'react';
import { subMonths } from 'date-fns';

interface Entry {
  mes: string;
  ano: string;
  quantidade: string;
}

const ProdutoHistory: React.FC<{ entries: Entry[]; quantidadeSugerida: number }> = ({
  entries = [],
  quantidadeSugerida = 0,
}) => {
  const entradas = useMemo(() => {
    const items = [];
    const d = new Date();

    d.setDate(1);

    for (let i = 0; i <= 11; i++) {
      const sub = subMonths(d, i + 1);
      const ano = String(sub.getFullYear());
      const mes = String(sub.getMonth() + 1).padStart(2, '0');
      const item = entries.find((item) => item.ano === ano && item.mes === mes);

      items.push({ ano, mes, quantidade: item ? item.quantidade : '0' });
    }

    return items;
  }, [entries]);

  const quantidadeCompradaMes = useMemo(() => {
    const anoAtual = String(new Date().getFullYear());
    const mesAtual = String(new Date().getMonth() + 1);
    const entryMesAtual = entries.find((e) => e.ano === anoAtual && e.mes === mesAtual);

    if (entryMesAtual) {
      return entryMesAtual.quantidade;
    }

    return 0;
  }, [entries]);

  function getMedia(numMeses: number) {
    let total = 0;

    entradas.slice(0, numMeses).forEach((item) => {
      total += Number(item.quantidade);
    });

    return Math.round(total / numMeses);
  }

  return (
    <>
      {entradas.length > 0 ? (
        <div className="row">
          <div className="col-md-5">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th colSpan={2}>Últimas compras</th>
                </tr>
              </thead>
              <tbody>
                {entradas.map((entry) => (
                  <tr key={`${entry.mes}-${entry.ano}`}>
                    <td>
                      {entry.mes} / {entry.ano}
                    </td>
                    <td>{entry.quantidade} un.</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-md-7">
            <div className="row mb-2">
              <div className="col-8 text-left">Média dos últimos 12 meses:</div>
              <div className="col-4 text-right">{getMedia(12)} un.</div>
            </div>
            <div className="row mb-2">
              <div className="col-8 text-left">Média dos últimos 6 meses:</div>
              <div className="col-4 text-right">{getMedia(6)} un.</div>
            </div>
            <div className="row mb-2">
              <div className="col-8 text-left">Média dos últimos 3 meses:</div>
              <div className="col-4 text-right">{getMedia(3)} un.</div>
            </div>
            <div className="row mb-2">
              <div className="col-8 text-left">Quantidade sugerida:</div>
              <div className="col-4 text-right">{quantidadeSugerida} un.</div>
            </div>
            <div className="row mb-2">
              <div className="col-8 text-left">Quantidade comprada no mês:</div>
              <div className="col-4 text-right">{quantidadeCompradaMes} un.</div>
            </div>

            <div className="alert alert-info mt-4">Média dos últimos 12 meses.</div>
          </div>
        </div>
      ) : (
        <div className="alert alert-warning mb-0">Não há histórico de compra desse produto.</div>
      )}
    </>
  );
};

export default ProdutoHistory;
