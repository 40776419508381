import React from "react";
import Swal from "sweetalert2";
import { FiCalendar, FiXCircle } from "react-icons/fi";
import RegistroContatoForm from "../RegistroContatoForm";
import withReactContent from "sweetalert2-react-content";
import { FaRegComment } from "react-icons/fa";

const ButtonContato = ({
  setIsShowDropdownOpcoes,
  handleCreateRegistroContato,
  //fetchAgendaRegistroContatosProps,
  codigoLogin,
  nameButton,
  origin,
  action,
  codigo,
  grupo,
  props,
  loja,
  nome,
  cnpj,
  ...rest
}: any) => {
  async function onShowForm() {
    //e.preventDefault();

    //recolhe menu dropdown
    setIsShowDropdownOpcoes && setIsShowDropdownOpcoes(0);

    const ReactSwal = withReactContent(Swal);

    await ReactSwal.fire({
      //icon: "info",
      title:
        origin !== "pedido"
          ? "Registro de Contato"
          : "Registro de Justificativa ou comentário",
      html: (
        <RegistroContatoForm
          loja={loja}
          nome={nome}
          cnpj={cnpj}
          grupo={grupo}
          codigo={codigo}
          origin={origin}
          handleCreateRegistroContato={handleCreateRegistroContato}
          //fetchAgendaRegistroContatosProps={fetchAgendaRegistroContatosProps}
        />
      ),
      width: "60%",
      allowEscapeKey: false,
      allowOutsideClick: false,
      //showCancelButton: true,
      showConfirmButton: false,
      showCloseButton: true,
      //showDenyButton: true,
      //reverseButtons: true,
      //cancelButtonText: "Cancelar",
      //confirmButtonText: "Salvar",
      //denyButtonText: "Historico",
      // customClass: {
      //   actions: "my-actions",
      //   cancelButton: "order-1 right-gap",
      //   confirmButton: "order-2",
      //   denyButton: "order-3",
      // },
      //onClose: () => {},
    });

    /*if (response.isConfirmed) {
      handleEfetivar();
    }*/
  }
  return (
    <button
      type="button"
      //disabled={isDisabledConfirmaPedido}
      {...rest}
      className={props}
      onClick={() => onShowForm()}
      title={
        origin !== "pedido"
          ? "Regitrar contato."
          : "Deixe seu comentário ou justificativa para grupo produto ou produto"
      }
    >
      {origin !== "pedido" ? (
        <>
          {nameButton === "Fechar" ? (
            <FiXCircle className="mr-2" />
          ) : (
            <FiCalendar className="mr-2" />
          )}
          <span>{nameButton}</span>
        </>
      ) : (
        <FaRegComment size={18} color={"##0ea5e9"} />
      )}
    </button>
  );
};

export default ButtonContato;
