import React from 'react';

import { Produto } from '../../typings';

interface ProdutoDetailProps {
  produto: Produto;
}

const ProdutoDetail: React.FC<ProdutoDetailProps> = ({ produto }) => {
  return (
    <div>
      <div className="card mb-3">
        <div className="card-header bg-primary">
          <strong className="text-white">APLICAÇÃO</strong>
        </div>
        <div className="card-body text-left">{produto.aplicacao}</div>
      </div>

      <div className="card">
        <div className="card-header bg-primary">
          <strong className="text-white">DADOS TÉCNICOS</strong>
        </div>
        <div className="card-body text-left">{produto.caracteristica}</div>
      </div>
    </div>
  );
};

export default ProdutoDetail;
