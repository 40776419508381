import React, { InputHTMLAttributes, ReactElement } from "react";
import InputMask from "react-input-mask";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  mask?: string;
  className?: string;
  prepend?: ReactElement;
  append?: ReactElement;
  errorMessage?: string;
}

const Input: React.FC<InputProps> = ({
  label,
  name,
  mask,
  className,
  prepend,
  append,
  errorMessage,
  ...rest
}) => {
  return (
    <div className="form-group text-left flex-grow-1">
      {label && <label htmlFor={name}>{label}</label>}

      <div className="d-flex align-items-center position-relative">
        {prepend}

        {mask ? (
          <InputMask
            mask={mask.toString()}
            name={name.toString()}
            id={name.toString()}
            className={[
              "form-control flex-grow-1",
              errorMessage ? "is-invalid" : "",
              className,
            ].join(" ")}
            {...rest}
          />
        ) : (
          <input
            type="text"
            name={name}
            id={name}
            className={[
              "form-control flex-grow-1",
              errorMessage ? "is-invalid" : "",
              className,
            ].join(" ")}
            {...rest}
          />
        )}

        {append}
      </div>
      {errorMessage && (
        <div className="invalid-feedback d-block">{errorMessage}</div>
      )}
    </div>
  );
};

export default Input;
