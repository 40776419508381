import React from 'react';

import ENV from '../../../../ENV';
import { Produto } from '../../../../typings';

interface ItemPedido {
  produto: Produto;
  valorUnitario: string;
  notaFiscal: string;
  dataEmissaoNotaFiscal: string;
  status: string;
  valorTotal: string;
  quantidade: string;
}

interface Pedido {
  numero: string;
  condicaoPagamento: string;
  descricaoCondicaoPagamento: string;
  dataEmissaoPedido: string;
  itens: ItemPedido[];
  transportadora: {
    nome: string;
    cnpj: string;
  };
  pedidob2b: {
    dataCriacao: string;
    usuario: {
      cnpj: string;
      nome: string;
    };
  };
}

const DetalhePedido: React.FC<{ pedido: Pedido }> = ({ pedido }) => {
  function formatCNPJ(cnpj: string) {
    const rejexCnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

    return rejexCnpj;
  }
  return (
    <div className="text-left">
      {pedido.pedidob2b && (
        <div className="alert alert-info">
          <p>
            <strong>Pedido realizado via B2B.</strong>
          </p>
          <p>
            <strong>Por</strong>: {pedido.pedidob2b.usuario.cnpj} - {pedido.pedidob2b.usuario.nome}
          </p>
          <p className="mb-0">
            <strong>Em</strong>: {new Date(pedido.pedidob2b.dataCriacao).toLocaleDateString('pt-BR')} às{' '}
            {new Date(pedido.pedidob2b.dataCriacao).toLocaleTimeString('pt-BR')}
          </p>
        </div>
      )}
      <div
        style={{
          border: '1px solid #333',
          fontSize: '.9rem',
        }}
        className="p-2">
        <p>
          <b>TRANSPORTADORA</b>
        </p>
        <p>
          <b>Nome:</b> {pedido.transportadora.nome}
        </p>
        <p>
          <b>CNPJ:</b> {formatCNPJ(pedido.transportadora.cnpj)}
        </p>
      </div>

      <div className="table-responsive">
        <table className="table table-striped table-borderless">
          <thead className="thead-dark">
            <tr>
              <th style={{ width: '50%' }}>Produto</th>
              <th style={{ width: '1px' }}>Quantidade</th>
              <th style={{ width: '1px' }}>Valor</th>
              <th style={{ width: '1px' }}>Valor total</th>
              <th style={{ width: '1px' }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {pedido.itens.map((item, index) => (
              <tr key={index}>
                <td className="text-uppercase">
                  {item.produto ? (
                    <div className="d-flex align-items-center">
                      <img
                        src={`${ENV.ASSETS_URL}/${item.produto.codigo}.jpg`}
                        alt={item.produto.codigo}
                        width="50px"
                        className="mr-3"
                      />
                      <span>{item.produto.titulo}</span>
                    </div>
                  ) : (
                    'Produto descontinuado'
                  )}
                </td>
                <td className="text-uppercase">{item.quantidade}</td>
                <td className="text-uppercase">
                  {Number(item.valorUnitario).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </td>
                <td className="text-uppercase">
                  {Number(item.valorTotal).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </td>
                <td className="text-uppercase" style={{ whiteSpace: 'nowrap' }}>
                  {item.status}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DetalhePedido;
