import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import FormSolicitacaoSenha from './FormSolicitacaoSenha';
import FormAlteracaoSenha from './FormAlteracaoSenha';

const RecuperacaoSenha = () => {
  const [state, setState] = useState({ usuario: '', codigo: '' });

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const usuario = queryParams.get('usuario') || '';
    const codigo = queryParams.get('codigo') || '';

    setState({ usuario, codigo });
  }, [location.search]);

  return (
    <div className="d-flex flex-fill align-items-center">
      <div className="col-sm-8 offset-sm-2 col-xl-4 offset-xl-4 border border-blue p-4">
        {state.usuario && state.codigo ? <FormAlteracaoSenha /> : <FormSolicitacaoSenha />}
      </div>
    </div>
  );
};

export default RecuperacaoSenha;
