import React, { useState } from "react";
import { FaBoxOpen, FaMoneyBillAlt, FaShoppingCart } from "react-icons/fa";
import {
  FiGrid,
  FiMenu,
  FiUser,
  FiUserPlus,
  FiUsers,
  FiX,
} from "react-icons/fi";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SolicitarEnvioEmailPedidos from "../../pages/Relatorios/Pedidos/SolicitarEnvioEmail";
import SolicitarEnvioEmailFaturamento from "../../pages/Relatorios/Faturamento/SolicitarEnvioEmail";
import { useAppContext } from "../../contexts/app";
import ModalDisponibilidadeProduto from "../ModalDisponibilidadeProduto";

interface Props {
  setIsCliente: (state: boolean) => void;
}

const HeaderMenuDropdowDesktopHanburg = ({ setIsCliente }: Props) => {
  const { user } = useAppContext();
  const [isShowMenu, setIsShowMenu] = useState(false);

  function setShowMenuDesktop() {
    setIsShowMenu(!isShowMenu);
  }

  function showSolcitarEnvioEmailPedidos() {
    const ReactSwal = withReactContent(Swal);

    setShowMenuDesktop();
    ReactSwal.fire({
      title: "Solicitar Envio de e-mail",
      html: (
        <SolicitarEnvioEmailPedidos codigo={user!.codigo} tipo={user!.tipo} />
      ),
      width: "40%",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  function showSolcitarEnvioEmailFaturamento() {
    const ReactSwal = withReactContent(Swal);

    setShowMenuDesktop();
    ReactSwal.fire({
      title: "Solicitar Envio de e-mail",
      html: (
        <SolicitarEnvioEmailFaturamento
          codigo={user?.codigo}
          tipo={user?.tipo}
        />
      ),
      width: "40%",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  function showMenuDisponibilidadeProduto() {
    setIsCliente(false);
    setShowMenuDesktop();

    const ReactSwal = withReactContent(Swal);

    setShowMenuDesktop();
    ReactSwal.fire({
      title: "Consulta Disponibilidade Produto",
      html: <ModalDisponibilidadeProduto />,
      width: "40%",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: "Fechar",
    });
  }

  /*useEffect(() => {
    setTimeout(() => {
      showSolcitarEnvioEmailPedidos();
      console.log(user);
    }, 1000);
  }, []);*/

  return (
    <>
      <div className="btn-group dropleft col-12">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setShowMenuDesktop()}
          title="Menu"
        >
          {isShowMenu ? <FiX size={24} /> : <FiMenu size={24} />}
        </button>
        {/* <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={setShowMenuDesktop}>
            <span className="sr-only">Toggle Dropdown</span>
          </button> */}

        <div className={`dropdown-menu ${isShowMenu ? "show" : ""}`}>
          {user?.isRepresentante ? (
            <>
              <NavLink
                to="/prospects"
                onClick={() => setShowMenuDesktop()}
                className="dropdown-item btn btn-outline-primary d-flex align-items-center mr-2"
              >
                <FiUserPlus className="mr-2" />
                Prospects
              </NavLink>
              <NavLink
                to="/clientes"
                onClick={() => [setIsCliente(false), setShowMenuDesktop()]}
                className="dropdown-item btn btn-outline-primary d-flex align-items-center mr-2 pt-1 pb-1"
              >
                <FiUsers className="mr-2" />
                Ver Meus Clientes
              </NavLink>

              <div className="dropdown-divider"></div>
              <button
                onClick={() => showMenuDisponibilidadeProduto()}
                className="dropdown-item btn btn-outline-primary d-flex align-items-center mr-2 pt-1 pb-1"
              >
                <FaBoxOpen className="mr-2" />
                Consulta disponibilidade produto
              </button>
              <div className="dropdown-divider"></div>

              <div>
                <span className="dropdown-item d-flex align-items-center mr-2">
                  <FiGrid className="mr-2" />
                  Relatórios
                </span>
                <button
                  onClick={() => showSolcitarEnvioEmailPedidos()}
                  className="dropdown-item btn btn-outline-primary d-flex align-items-center mr-2 pl-4 pt-1"
                >
                  <FaShoppingCart className="mr-2 ml-4" />
                  Pedidos
                </button>
                <button
                  onClick={() => showSolcitarEnvioEmailFaturamento()}
                  className="dropdown-item btn btn-outline-primary d-flex align-items-center mr-2 pl-4"
                >
                  <FaMoneyBillAlt className="mr-2 ml-4" />
                  Faturamento
                </button>
              </div>
            </>
          ) : (
            <NavLink
              to="/minhaconta"
              onClick={() => [setIsCliente(false), setShowMenuDesktop()]}
              className="toggle dropdown-item btn btn-outline-primary d-flex align-items-center mr-2 pt-1 pb-1"
            >
              <FiUser className="mr-2" />
              Minha Conta
            </NavLink>
          )}
        </div>
      </div>
    </>
  );
};

export default HeaderMenuDropdowDesktopHanburg;
