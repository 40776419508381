import React from "react";
import { NavLink } from "react-router-dom";
import { FaHome, FaPlusCircle, FaCalendar } from "react-icons/fa";

//JL-HF_2023-09
const SideBarProspect: React.FC<{
  handleNovoCadastro: () => void;
}> = ({ handleNovoCadastro }) => {
  return (
    <>
      <button
        className="btn btn-block btn-lg btn-outline-primary d-flex align-items-center"
        title="Incluir novo cadastro prospect"
        onClick={() => handleNovoCadastro()}
      >
        <FaPlusCircle className="mr-4" />
        Incluir
      </button>
      <NavLink
        exact
        to="/prospects"
        className="btn btn-block btn-lg btn-outline-primary d-flex align-items-center"
      >
        <FaCalendar className="mr-4" />
        Contato
      </NavLink>

      <NavLink
        exact
        to="/prospects"
        className="btn btn-block btn-lg btn-outline-primary d-flex align-items-center"
      >
        <FaHome className="mr-4" />
        Retornar ao início
      </NavLink>
    </>
  );
};

export default SideBarProspect;
