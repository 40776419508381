import React, { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import Swal from "sweetalert2";

import Select from "react-select";
import {
  GrupoVendas,
  RelatorioEmailPedidosFaturamento,
  Revendedor,
  StatusPedidoProps,
  TipoUser,
} from "../../../typings";
import Input from "../../../components/Input";

import {
  fetchClientesRepresentante,
  fetchGrupoVendasAll,
} from "../../../services/api";
import { isAfter, isBefore, isEqual, parseISO } from "date-fns";
import { TipoUsuario } from "../../../entities/TipoUsuario";
import { StatusPedidos } from "../../../entities/StatusPedido";

import { validaFormatacaoEmail } from "../../../utils/regexValidaEmail";
import LoadingCustom from "../../../components/LoadingCustom";

interface Props {
  title: string;
  codigo: string | undefined;
  tipo?: TipoUser | undefined;
  origin: "pedido" | "faturamento";
  handleFormSubmitProps: (data: RelatorioEmailPedidosFaturamento) => void;
}

const ModalSolicitarEnvioEmail = (props: Props) => {
  const tomorrow = useMemo(() => {
    const d = new Date(); //Date(2023, 5, 1);
    const day = d.getDate(); //  +1;
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    const formatedDay = day < 10 ? `0${day}` : day;
    const formatedMonth = month < 10 ? `0${month}` : month;

    return `${year}-${formatedMonth}-${formatedDay}`;
  }, []);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  /*const [email, setEmail] = useState(
    "homologacaosmtp@gmail.com; josuel.lopes@hfbr.com.br; homologacaopop@gmail.com, teste@com.br, test.com.br"
  );*/
  const [tipo, setTipo] = useState("1");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataInicio, setDataInicio] = useState(""); //useState(tomorrow);
  const [dataFim, setDataFim] = useState(""); //useState(tomorrow);
  const [dataInicioMessageError, setDataInicioMessageError] = useState("");
  const [dataFimMessageError, setDataFimMessageError] = useState("");
  const [emailMessageError, setEmailMessageError] = useState("");
  const [clientes, setClientes] = useState<Revendedor[]>([] as Revendedor[]);
  const [cliente, setCliente] = useState<Revendedor>({} as Revendedor);
  const [gruposVendas, setGruposVendas] = useState<GrupoVendas[]>(
    [] as GrupoVendas[]
  );
  const [grupoVenda, setGrupoVenda] = useState<GrupoVendas>({} as GrupoVendas);
  /*const [statusPedido, setStatusPedido] = useState(StatusPedidos);*/
  const [status, setStatus] = useState<StatusPedidoProps>();

  const sortParam = useRef("nome");
  const sortOrder = useRef("asc");

  let errorMessage = "";

  function validateDataInicio() {
    let isValid = true;

    setDataInicioMessageError("");

    if (dataInicio === "") {
      isValid = false;
    }

    if (
      dataInicio !== "" &&
      isAfter(parseISO(dataInicio), parseISO(dataFim)) &&
      !isEqual(parseISO(dataInicio), parseISO(dataFim))
    ) {
      isValid = false;
      setDataInicioMessageError("Data deve ser menor que data Fim");
    }

    //setIsButtonDisabled(!isValid);
    return isValid;
  }

  function validateDataFim() {
    let isValid = true;

    setDataFimMessageError("");

    /*if (dataFim !== "" && isAfter(parseISO(dataFim), parseISO(tomorrow))) {
      isValid = false;

      setDataFimMessageError("Data deve ser anterior ao dia de hoje");
    }*/
    if (dataFim === "") {
      isValid = false;
    }

    if (
      dataFim !== "" &&
      isBefore(parseISO(dataFim), parseISO(dataInicio)) &&
      !isEqual(parseISO(dataInicio), parseISO(dataFim))
    ) {
      isValid = false;

      setDataFimMessageError("Data deve ser posterior a data de início");
    }

    return isValid;
  }

  function ValidaEmails() {
    let emailIsValid = true;
    setEmailMessageError("");

    const formatEmails = email
      .toLowerCase()
      .trim()
      .replaceAll(";", ",")
      .split(",");

    formatEmails.map((e) => {
      if (!validaFormatacaoEmail(e.trim())) {
        emailIsValid = false;
        setIsButtonDisabled(true);
        setEmailMessageError("*Verifique e-mail é inválido!");
      }
      return null;
    });

    return emailIsValid;
  }

  function ValidaStatus() {
    return (
      props.origin === "faturamento" ||
      (props.origin === "pedido" && status?.id)
    );
  }

  function validaCamposForm() {
    //(parseInt(cliente?.codigo) > 0 || parseInt(grupoVenda?.codigo) > 0) &&
    const isValid =
      ValidaStatus() &&
      ValidaEmails() &&
      validateDataFim() &&
      validateDataInicio();

    setIsButtonDisabled(!isValid);

    return !isValid;
  }

  async function handleFormSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      setLoading(true);
      validaCamposForm();

      if (!isButtonDisabled) {
        const data = {
          codigoLogin: props.codigo,
          codigoCliente: cliente?.codigo ? cliente?.codigo : "",
          loja: cliente?.loja ? cliente?.loja : "",
          perfil: TipoUsuario[props.tipo!.nome.toLowerCase()].perfil,
          grupo: grupoVenda?.codigo ? grupoVenda?.codigo : "",
          status: status?.id ? status?.id : "",
          DataDe: String(dataInicio).replaceAll("-", ""),
          DataAt: String(dataFim).replaceAll("-", ""),
          emails: email.replaceAll(",", ";").trim(),
          tipo,
        } as RelatorioEmailPedidosFaturamento;

        await props.handleFormSubmitProps(data);

        setLoading(false);

        await Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Solicitação foi enviada, em breve você receberá email!",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });
      }
      setLoading(false);
      return;
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: `Falha ao solicitar envio de email ${errorMessage}, tente mais tarde!`,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });
    }
  }

  useEffect(() => {
    async function fetchClientes() {
      try {
        /*const loader = showLoader(
          "Carregando sua lista de clientes. Por favor aguarde."
        );*/

        setLoading(true);

        const response = await fetchClientesRepresentante(
          `${sortParam.current}:${sortOrder.current}`
        );

        if (response.data.itemCount > 0) {
          setClientes([
            {
              loja: "0",
              codigo: "0",
              nome: "Selecione...",
            },
            ...response.data.items,
          ]);
        }
        setLoading(false);
        //hideLoader(loader);
      } catch (error) {
        Swal.fire({
          icon: "warning",
          title: "Erro",
          text:
            "Ocorreu um erro no processamento da requisição lista clientes. Por favor, tente novamente mais tarde.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });
        console.log(error);
      }
    }

    async function fetchGrupoVendas() {
      try {
        const response = await fetchGrupoVendasAll();
        //`${sortParam.current}:${sortOrder.current}`

        if (response.data.length > 0) {
          setGruposVendas([
            {
              codigo: "0",
              descricao: "Selecione...",
            },
            ...response.data,
          ]);
        }

        //hideLoader(loader);
      } catch (error) {
        Swal.fire({
          icon: "warning",
          title: "Erro",
          text:
            "Ocorreu um erro no processamento da requisição grupo vendas. Por favor, tente novamente mais tarde.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });
        console.log(error);
      }
    }

    if (clientes.length === 0) {
      fetchClientes();
      fetchGrupoVendas();
    }
    validaCamposForm();
  }, [clientes, cliente, grupoVenda, dataInicio, dataFim, email, status]);

  return (
    <>
      {loading && (
        <LoadingCustom
          isLoading={loading}
          texto="Solicitando envio de e-mail."
        />
      )}
      <hr />

      <h4 className="text-secondary text-center mt-2 ">{props.title}</h4>

      <form
        onSubmit={handleFormSubmit}
        style={{
          fontSize: ".9rem",
        }}
        className="p-4"
      >
        <table
          className="text-left table table-borderless"
          style={{
            fontSize: "14px",
          }}
        >
          <tbody>
            <tr>
              <td>
                <b>Loja:</b>
              </td>
              <td>
                <Input
                  name="loja"
                  value={cliente?.loja}
                  type="text"
                  disabled
                  className="col-md-2"
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Tipo:</b>
              </td>
              <td>
                <input
                  name="tipo"
                  value="1"
                  type="radio"
                  className="col-sm-1 ml-0 pl-0"
                  checked={tipo === "1"}
                  onChange={(e) => setTipo(e.target.value)}
                />
                <label>PDF</label>
                <span className="col-md-2" />
                <input
                  name="tipo"
                  value="2"
                  type="radio"
                  className="col-sm-1"
                  checked={tipo === "2"}
                  onChange={(e) => setTipo(e.target.value)}
                />
                <label>EXCEL</label>
              </td>
            </tr>
            <tr>
              <td>
                <b>Grupo:</b>
              </td>

              <td>
                <div className="flex-grow-1 mr-1">
                  <Select
                    id="grupo"
                    isDisabled={cliente != null && parseInt(cliente.codigo) > 0}
                    options={gruposVendas}
                    getOptionLabel={(item) =>
                      parseInt(item.codigo) > 0
                        ? `${item.codigo} - ${item.descricao}`
                        : `${item.descricao}`
                    }
                    getOptionValue={(item) => item.codigo}
                    placeholder={
                      cliente != null
                        ? "Selecione Grupo de vendas ou Cliente"
                        : "Selecione o grupo"
                    }
                    styles={{
                      option: (provided) => ({
                        ...provided,
                        fontSize: ".9rem !important",
                        textAlign: "left",
                        minWidth: "400px",
                      }),
                      control: (provided) => ({
                        ...provided,
                        borderRadius: 0,
                      }),
                    }}
                    onChange={(value) => (
                      setGrupoVenda(value as GrupoVendas),
                      setCliente({} as Revendedor)
                    )}
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  width: "15%",
                }}
              >
                <b>Cliente:</b>
              </td>
              <td>
                <div className="flex-grow-1 mr-1">
                  <Select
                    id="cliente"
                    options={clientes}
                    isDisabled={
                      grupoVenda != null && parseInt(grupoVenda.codigo) > 0
                    }
                    getOptionLabel={(cliente) =>
                      parseInt(cliente?.codigo) > 0
                        ? `${cliente.codigo} - ${cliente.loja} - ${cliente.nome}`
                        : `${cliente.nome}`
                    } //DEV-JL
                    getOptionValue={(cliente) => cliente.codigo}
                    placeholder={
                      grupoVenda != null
                        ? "Selecione Cliente ou Grupo de Vendas"
                        : "Selecione o cliente"
                    }
                    styles={{
                      option: (provided) => ({
                        ...provided,
                        fontSize: ".9rem !important",
                        textAlign: "left",
                        minWidth: "400px",
                      }),
                      control: (provided) => ({
                        ...provided,
                        borderRadius: 0,
                      }),
                    }}
                    onChange={(value) => (
                      setCliente(value as Revendedor),
                      setGrupoVenda({} as GrupoVendas)
                    )}
                  />
                </div>
              </td>
            </tr>
            {props.origin === "pedido" && (
              <tr>
                <td>
                  <b>Status:</b>
                </td>
                <td>
                  <div className="flex-grow-1 mr-1">
                    <Select
                      id="status"
                      options={StatusPedidos}
                      getOptionLabel={(item) => `${item.status}`} //DEV-JL
                      getOptionValue={(item) => `${item.id}`}
                      placeholder={"Selecione Status Pedido"}
                      styles={{
                        option: (provided) => ({
                          ...provided,
                          fontSize: ".9rem !important",
                          textAlign: "left",
                          minWidth: "400px",
                        }),
                        control: (provided) => ({
                          ...provided,
                          borderRadius: 0,
                        }),
                      }}
                      onChange={(value) => setStatus(value as any)}
                    />
                  </div>
                </td>
              </tr>
            )}
            <tr>
              <td>
                <b>Data de:</b>
              </td>
              <td>
                <Input
                  required
                  name="dataInicio"
                  value={dataInicio}
                  type="date"
                  max={tomorrow}
                  errorMessage={dataInicioMessageError}
                  onBlur={() => validateDataInicio()}
                  onChange={(e) => setDataInicio(e.target.value)}
                  className="col-md-12"
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Data até:</b>
              </td>
              <td>
                <Input
                  required
                  name="dataFim"
                  value={dataFim}
                  type="date"
                  max={tomorrow}
                  errorMessage={dataFimMessageError}
                  onBlur={() => validateDataFim()}
                  onChange={(e) => setDataFim(e.target.value)}
                  className="col-md-12"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div className="pl-2 pr-2">
          <div className="alert alert-info">
            <div className="row align-items-center">
              <div className="col-md">
                <strong>Para adicionar mais de um email, separar por ; </strong>
              </div>
            </div>
          </div>

          <Input
            type="text"
            label="E-mail"
            name="email"
            value={email}
            errorMessage={emailMessageError}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <br />
        <hr />
        <button
          type="submit"
          className="btn btn-block btn-primary btn-lg mt-4"
          disabled={isButtonDisabled}
          title={
            isButtonDisabled
              ? "Verifique se os campos estão preenchidos corretamente!"
              : "Enviar a solicitação para encaminhar relatório por e-mail!"
          }
        >
          SOLICITAR ENVIO
        </button>
      </form>
    </>
  );
};

export default ModalSolicitarEnvioEmail;
