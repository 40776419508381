import React from "react";

import styles from "./PedidoMessageFrete.module.css";

const PedidoMessageFrete = () => {
  return (
    <>
      <div
        className={[
          styles.itemContainer,
          "d-block mb-3 p-2 flex-fill justify-content-start offset-md-0 col-md text-left",
        ].join(" ")}
      >
        <small>
          <p>
            <b>
              Pedidos abaixo do valor de R$ 2.000,00 será acrescido na nota
              fiscal, o valor de frete correspondente a região da entrega. Vide
              tabela abaixo:
            </b>
          </p>
          <p>Regiões Sul e Sudeste R$ 50,00</p>
          <p>Regiões Centro Oeste, Norte e Nordeste R$ 80,00</p>
          <p>O valor de frete compõe a Base de Cálculo do ICMS e IPI.</p>
        </small>
      </div>
    </>
  );
};

export default PedidoMessageFrete;
