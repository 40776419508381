import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { FaExpandArrowsAlt, FaThList, FaBox, FaSort } from "react-icons/fa";
import { FiChevronDown, FiChevronUp, FiMail, FiTrash2 } from "react-icons/fi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { parseISO, format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

import SideBar from "../SideBar";
import { useAppContext } from "../../../contexts/app";
import { Produto } from "../../../typings";
import {
  fetchClienteOrcamento,
  fetchInteracoesOrcamento,
  gravarOrcamentoInteracao,
  updateItems,
} from "../../../services/api"; //JL-HF_2022-07
import OrcamentoStatus from "../../../components/OrcamentoStatus";
import OrcamentoInteracoes from "../../../components/OrcamentoInteracoes";

import ExcluirOrcamento from "./ExcluirOrcamento";
import DetalheOrcamento from "./DetalheOrcamento";
import SolicitarEnvioEmail from "./SolicitarEnvioEmail"; //JL-HF_2022-06

interface ItemOrcamento {
  produto: Produto;
  valorUnitario: string;
  notaFiscal: string;
  dataEmissaoNotaFiscal: string;
  status: string;
  valorTotal: string;
  quantidade: string;
}

interface Orcamento {
  numero: string;
  notaserie: string; //JL-HF_2022-06
  condicaoPagamento: string;
  descricaoCondicaoPagamento: string;
  dataEmissaoPedido: string;
  itens: ItemOrcamento[];
  transportadora: {
    nome: string;
    cnpj: string;
  };
  pedidob2b: {
    dataCriacao: string;
    usuario: {
      cnpj: string;
      nome: string;
    };
  };
}

const Orcamentos = () => {
  const [orcamento, setOrcamento] = useState<any[]>([]);
  const { showLoader, hideLoader, user } = useAppContext();

  const statusOrcamentoEfetivado = 5; //STATUS:  5 = EFETIVADO

  const sortParam = useRef("id");
  const sortOrder = useRef("asc");

  useEffect(() => {
    async function fetchItems() {
      try {
        const qtdPrefixo = "00000";
        const loader = showLoader("Por favor aguarde. Carregando orcamentos.");
        const response = await fetchClienteOrcamento();

        const formatData = await response.data.map((item: any) => {
          item.id = String(qtdPrefixo.slice(String(item.id).length)) + item.id;
          item.dataCriacao = String(
            format(parseISO(item.dataCriacao), "dd/MM/yyyy", { locale: ptBR })
          );
          item.dataInteracao =
            item.dataInteracao.length === 0
              ? "--/--/----"
              : String(
                  format(parseISO(item.dataInteracao), "dd/MM/yyyy", {
                    locale: ptBR,
                  })
                );

          return {
            ...item,

            StrStatus: getStatusOrcamento(item.status),
            pedido: {
              qtdItens: item.itens?.length,
              condPagamento: `${item.condicaoPagamento}-${item.descricaoCondicaoPagamento}`,
              /*totalDesconto: '3.09',
              totalSemImposto: '8.35',
              totalIPI: '0.37',
              totalST: '0.00',
              totalComImposto: '8.72',*/
            },
          };
        });

        const sorted = formatData.sort((a: any, b: any) => {
          return a.id > b.id;
        });

        await setOrcamento(sorted);

        hideLoader(loader);
      } catch (error) {
        Swal.fire({
          icon: "warning",
          title: "Erro",
          text:
            "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });

        console.log(error);
      }
    }

    if (orcamento.length === 0) {
      fetchItems();
    }
  }, [hideLoader, orcamento.length, showLoader]);

  function filterPedidoItens(itens: ItemOrcamento[]) {
    if (itens.length > 0) {
      return itens.filter((item) => Number(item.quantidade) > 0);
    }

    return [];
  }

  function getStatusOrcamento(status: number) {
    const strStatus: any = {
      1: "SALVO",
      2: "AGUARDANDO",
      3: "REJEITADO",
      4: "APROVADO",
      5: "EFETIVADO",
    };

    return strStatus[String(status)];
  }

  async function setGetStatus() {
    await setOrcamento([]);
  }

  async function showDetalheOrcamento(orcamento: Orcamento) {
    const ReactSwal = withReactContent(Swal);

    const loader = showLoader(
      "Por favor aguarde. Carregando detalhes orçamento."
    );

    try {
      const produtos = orcamento.itens.map((item: any) => {
        return {
          ...item,
          percentualDesconto: parseFloat(item.percentualDesconto),
        };
      });

      const response = await updateItems({
        condicaoPagamento: orcamento.condicaoPagamento,
        calcularImpostos: "true",
        calcularEntregas: "false",
        calcularQuantidadeMes: "true",
        itens: produtos,
      });

      const formatData = await response.data.map(
        (item: any, index: number) => ({
          ...item,
          percentualDesconto: produtos.map((i: any) => {
            if (i.codigo === item.codigo) {
              return i.percentualDesconto;
            } else {
              return 0;
            }
          })[index],
        })
      );

      await ReactSwal.fire({
        title: "Detalhes orçamento",
        html: (
          <DetalheOrcamento
            pedido={orcamento}
            itens={formatData}
            action={ReactSwal}
            setStatus={setGetStatus}
          />
        ),
        width: "80%",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        showCloseButton: true,
        //confirmButtonText: 'Enviar Solicitação',
      });
      hideLoader(loader);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Erro",
        text:
          "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });

      console.log(error);
    }
  }

  //JL-HF_2022-06
  function showSolcitarEnvioEmail(orcamento: Orcamento) {
    const ReactSwal = withReactContent(Swal);

    orcamento.itens = filterPedidoItens(orcamento.itens);

    ReactSwal.fire({
      title: "Solicitar Envio de e-mail",
      html: <SolicitarEnvioEmail pedido={orcamento} />,
      width: "40%",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  async function showDetalheInteracoes(id: number) {
    const ReactSwal = withReactContent(Swal);

    const loader = showLoader(
      "Por favor aguarde. Carregando as interações do orcamentos."
    );

    const response: any = await fetchInteracoesOrcamento({ id });

    hideLoader(loader);

    ReactSwal.fire({
      title: "Interações do orçamento",
      html: (
        <OrcamentoInteracoes
          data={response.data}
          id={id}
          setOrcamento={setOrcamento}
          user={user}
        />
      ),
      width: "80%",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
      //confirmButtonText: 'Enviar Solicitação',
    });
  }

  async function showExcluirOrcamento(orcamento: any) {
    try {
      const ReactSwal = withReactContent(Swal);

      const response = await ReactSwal.fire({
        title: "Excluir do orçamento",
        html: <ExcluirOrcamento orcamento={orcamento} />,
        width: "40%",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      });

      if (response.isConfirmed) {
        const loader = showLoader(
          "Por favor aguarde. Carregando as interações do orcamentos."
        );

        const response = await gravarOrcamentoInteracao({
          id: orcamento.id,
          status: 0, //STATUS: 0 = EXCLUIR
          justificativa: "orçamento foi excluido pelo usuário",
        });

        hideLoader(loader);

        if (response) {
          setOrcamento([]);

          Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: "Orçamento foi excluido!",
            allowEscapeKey: false,
            allowOutsideClick: false,
            confirmButtonText: "OK",
          });
        }
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Falha ao excluir o orçamento, tente mais tarde!",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });
    }
  }

  const history = useHistory();

  async function handleSubmitOrcamento(
    id: string,
    itens: any,
    ultimaCondicaoPagamento: any,
    status: any
  ) {
    const loader = showLoader("Por favor aguarde. Carregando orcamento.");

    try {
      const produtos = itens.map((item: any) => {
        return {
          ...item,
          percentualDesconto: parseFloat(item.percentualDesconto),
        };
      });

      const response = await updateItems({
        condicaoPagamento: ultimaCondicaoPagamento,
        calcularImpostos: "true",
        calcularEntregas: "false",
        calcularQuantidadeMes: "true",
        itens: produtos,
      });

      const formatData = await response.data.map(
        (item: any, index: number) => ({
          ...item,
          percentualDesconto: produtos.map((i: any) => {
            if (i.codigo === item.codigo) {
              return i.percentualDesconto;
            } else {
              return 0;
            }
          })[index],
        })
      );

      history.push("/pedido/mpp", {
        //itemsPlanilha: [],
        carregaQtd: true,
        orcamentoPedido: {
          ultimaCondicaoPagamento,
          produtos: formatData,
          status,
          id,
        },
        //dataOrcamento,
        isOrcamento: true,
      });
      hideLoader(loader);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Erro",
        text:
          "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });

      console.log(error);
    }
  }

  //JL-HF_2023-02
  async function sortBy(field: "id") {
    // | 'data' | 'interacao' | 'status') {
    const items = [...orcamento];
    if (sortParam.current === field) {
      sortOrder.current = sortOrder.current === "asc" ? "desc" : "asc";
    } else {
      sortParam.current = field;
      sortOrder.current = "asc";
    }

    const sorted = items.sort((a, b) => {
      if (sortOrder.current === "asc") {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });

    setOrcamento(sorted);
  }

  return (
    <div className="container-fluid my-4">
      <div className="row">
        <div className="col-md-3">
          <SideBar />
        </div>
        <div className="col-md-9 mt-4 mt-md-0">
          <h3>Meus orçamentos</h3>

          <hr />

          {orcamento.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-striped table-borderless">
                <thead className="thead-dark">
                  <tr>
                    <th style={{ width: "100px" }}>
                      <div
                        className="d-flex align-items-center"
                        style={{ width: "100px", cursor: "pointer" }}
                        onClick={() => sortBy("id")}
                      >
                        <span className="mr-1">Orçamento</span>
                        {sortParam.current === "codigo" ? (
                          sortOrder.current === "asc" ? (
                            <FiChevronUp />
                          ) : (
                            <FiChevronDown />
                          )
                        ) : (
                          <FaSort />
                        )}
                      </div>
                    </th>
                    <th style={{ width: "100px" }}>
                      <div className="d-flex align-items-center">
                        <span className="mr-1">Cód. Representante</span>
                      </div>
                    </th>
                    <th style={{ width: "100px" }}>
                      <div className="d-flex align-items-center justify-content-center">
                        <span className="mr-1 text-center">Data</span>
                      </div>
                    </th>
                    <th style={{ width: "100px" }}>
                      <div className="d-flex align-items-center justify-content-center">
                        <span className="mr-1">Interação</span>
                      </div>
                    </th>

                    <th style={{ width: "100px" }}>
                      <div className="d-flex align-items-center justify-content-center">
                        <span className="mr-1">Status</span>
                      </div>
                    </th>
                    <th
                      style={{ width: "1px" }}
                      title="Condição de pagamento"
                      className="justify-content-center"
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        Detalhes
                      </div>
                    </th>
                    <th colSpan={3}></th>
                  </tr>
                </thead>
                <tbody>
                  {orcamento.map((o, index) => (
                    <tr key={index}>
                      <td className="text-center" style={{ fontSize: "14px" }}>
                        {o.id}
                      </td>
                      <td className="text-center" style={{ fontSize: "14px" }}>
                        {o.usuario?.codigo}
                      </td>
                      <td className="text-center" style={{ fontSize: "14px" }}>
                        {o.dataCriacao}
                      </td>
                      <td
                        className="text-center"
                        style={{ fontSize: "14px", whiteSpace: "nowrap" }}
                      >
                        {o.dataInteracao.split(" ")[0]}
                        <br />
                        {o.dataInteracao.split(" ")[1]}
                      </td>

                      <td
                        style={{
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          textAlign: "center",
                        }}
                      >
                        <OrcamentoStatus id={o.status} status={o.StrStatus} />
                      </td>
                      <td style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                        <small>
                          <span className="mr-1">
                            <b>Qtd. Itens:</b>
                          </span>
                          {o.quantidadeItens} <br />
                          Cond. Pg.: {o.pedido.condPagamento.split("-")[0]}
                          <br />
                          <span className="mr-1">
                            <b>Total Descontos.:</b>
                          </span>
                          {Number(`${o.valorTotalDescontos}`).toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "BRL",
                            }
                          )}
                          <br />
                          <span className="mr-1">
                            <b>Total S/Impost.:</b>
                          </span>
                          {Number(`${o.valorTotalSemImpostos}`).toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "BRL",
                            }
                          )}
                          <br />
                          <span className="mr-1">
                            <b>Total IPI:</b>
                          </span>
                          {Number(`${o.ipiTotal}`).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                          <br />
                          <span className="mr-1">
                            <b>Total ST:</b>
                          </span>
                          {Number(`${o.stTotal}`).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                          <br />
                          <span className="mr-1">
                            <b>Total C/Impost.:</b>
                          </span>
                          {Number(`${o.valorTotalComImpostos}`).toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "BRL",
                            }
                          )}
                        </small>
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <div
                          style={{
                            display: "flex",
                            margin: 0,
                            padding: 0,
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "15px",
                          }}
                        >
                          <button
                            type="button"
                            title="Exibir detalhes do orçamento"
                            className="btn btn-sm btn-outline-primary d-flex align-items-center"
                            onClick={() => showDetalheOrcamento(o)}
                          >
                            <FaExpandArrowsAlt />
                          </button>
                          <button
                            type="button"
                            title="Interações orçamento - motivo (Justificativas)"
                            className="btn btn-sm btn-outline-warning d-flex align-items-center"
                            onClick={() =>
                              showDetalheInteracoes(parseInt(o.id))
                            }
                          >
                            <FaThList />
                          </button>
                          <button
                            type="button"
                            title="Solicitar o envio de orçamento por email"
                            className="btn btn-sm btn-outline-info d-flex align-items-center"
                            onClick={() => showSolcitarEnvioEmail(o)}
                          >
                            <FiMail />
                          </button>
                          <button
                            type="button"
                            title="Excluir orçamento"
                            className="btn btn-sm btn-outline-danger d-flex align-items-center"
                            onClick={() => showExcluirOrcamento(o)}
                          >
                            <FiTrash2 />
                          </button>
                          <button
                            onClick={() =>
                              handleSubmitOrcamento(
                                o.id,
                                o.itens,
                                o.condicaoPagamento,
                                o.status
                              )
                            }
                            type="button"
                            disabled={o.status === statusOrcamentoEfetivado}
                            title={
                              o.status === statusOrcamentoEfetivado
                                ? "Orçamento já foi efetivado como pedido!"
                                : "Acessar orçamento"
                            }
                            className="btn btn-sm btn-outline-success d-flex align-items-center"
                          >
                            <FaBox />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="alert alert-info">Nenhum orçamento cadastrado.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Orcamentos;
