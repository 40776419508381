import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  FormEvent,
} from "react";
import { FaSort } from "react-icons/fa";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import Swal from "sweetalert2";

import { RegistroContatoProps } from "../../typings";
//import ButtonContatoCliente from "../../pages/Clientes/Contato";
//import ButtonContatoProspect from "../../pages/Prospects/Contato";
import LoadingCustom from "../LoadingCustom";
import { format } from "date-fns";

const ModalHistoricoContato = ({
  fetchAgendaRegistroContatosProps,
  props,
}: any) => {
  const [loading, setLoading] = useState(true);
  const [prospects, setProspects] = useState<RegistroContatoProps[]>([]);
  const [filteredHistorico, setfilteredHistorico] = useState<
    RegistroContatoProps[]
  >([]);
  const [searchText, setSearchText] = useState("");
  //const { user, showLoader, hideLoader, setUserCliente } = useAppContext();

  const sortParam = useRef("nome");
  const sortOrder = useRef("asc");
  const [baseRegistroContatoProps] = useState<RegistroContatoProps>({
    codigo: "",
    loja: "",
    dataInteracao: "",
    dataProximoContato: "",
    tipoContato: "",
    observacao: "",
    codigoRepresentante: "",
    codigoInatividade: "",
    codigoConcorrente: "",
    descricaoInatividade: "",
    codigoProduto: "",
    codigogrupoProduto: "",
    descricaoGrupoProduto: "",
    descricaoConcorrenteReduz: "",
    descricaoProduto: "",

    descricaoConcorrenteReduzClass: "",
    descricaoGrupoProdutoClass: "",
  } as RegistroContatoProps);

  async function fetchAgendaHistorico() {
    try {
      setLoading(true);
      // const loader = showLoader(
      //   "Carregando sua lista de prospect. Por favor aguarde."
      // );

      /*const response = await fetchClientesRepresentante(
        `${sortParam.current}:${sortOrder.current}`
      );
      //console.log(response.data.items);
      setUserCliente(null);

      await console.log(data);
      if (response.data.itemCount > 0) {
        setClientes(data); //response.data.items);
        setFilteredClientes(response.data.items);
      }*/

      const response: RegistroContatoProps[] = await fetchAgendaRegistroContatosProps();

      if (response.length > 0) {
        const field = "dataInteracao";

        const sorted: RegistroContatoProps[] = await response.sort(
          (a: RegistroContatoProps, b: RegistroContatoProps) => {
            if (sortOrder.current === "desc") {
              return format(new Date(a[field]), "yyyyMMdd") >
                format(new Date(b[field]), "yyyyMMdd")
                ? 1
                : -1;
            } else {
              return format(new Date(a[field]), "yyyyMMdd") <
                format(new Date(b[field]), "yyyyMMdd")
                ? 1
                : -1;
            }
          }
        );
        //transformItems(sorted);
        const items: RegistroContatoProps[] = await transformItems(sorted);
        setfilteredHistorico(items);
        setProspects(response); //response.data.items);
      }

      //hideLoader(loader);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Erro",
        text:
          "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });

      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const transformItems = async (itens: RegistroContatoProps[]) => {
    let nameClassCss = "";
    const itensHistorico = await itens.map((item: any) => {
      item.loja = !item.loja ? "01" : item.loja;
      item.dataInteracao =
        item?.dataInteracao === "Invalid Date" || !item.dataInteracao
          ? "00/00/0000"
          : item.dataInteracao;
      item.dataProximoContato =
        item?.dataProximoContato === "Invalid Date" || !item.dataProximoContato
          ? "00/00/0000"
          : item.dataProximoContato;

      for (var i in baseRegistroContatoProps) {
        nameClassCss = `${[i]}Class`;
        item[nameClassCss] = "text-left";

        if (!item?.[i] || item?.[i].length === 0) {
          item = { ...item, [nameClassCss]: "text-center", [i]: "-------" };
        }
      }

      return item as RegistroContatoProps;
    });

    return itensHistorico;
  };

  useEffect(() => {
    if (prospects.length === 0) {
      fetchAgendaHistorico();
    }
  }, [prospects, prospects.length]);

  async function sortBy(field: "dataInteracao" | "dataProximoContato") {
    //const items = [...prospects];

    if (sortParam.current === field) {
      sortOrder.current = sortOrder.current === "asc" ? "desc" : "asc";
    } else {
      sortParam.current = field;
      sortOrder.current = "desc";
    }
    const sorted: RegistroContatoProps[] = await prospects.sort(
      (a: RegistroContatoProps, b: RegistroContatoProps) => {
        if (sortOrder.current === "asc") {
          return format(new Date(a[field]), "yyyyMMdd") >
            format(new Date(b[field]), "yyyyMMdd")
            ? 1
            : -1;
        } else {
          return format(new Date(a[field]), "yyyyMMdd") <
            format(new Date(b[field]), "yyyyMMdd")
            ? 1
            : -1;
        }
      }
    );

    const response: any = await transformItems(sorted);
    setfilteredHistorico(response);

    //setClientes(sorted);
    //setfilteredHistorico(sorted);
  }

  /*const handleSearch = useCallback(
    async (e: FormEvent | null) => {
      if (e) {
        e.preventDefault();
      }

      /*const filtered = prospects.filter((historico) => {
        const date = historico.date; //JL-HF_2023-02
        const inativo = historico.inativo == 0 ? "NÃO" : "SIM";
        //const observacao = historico.observacao.toLowerCase();
        const descricao = historico.descricao.toLowerCase();

        console.log(historico.date);
        return (
          date.includes(searchText.toLowerCase()) ||
          inativo.includes(searchText.toLocaleLowerCase()) ||
          observacao.includes(searchText.toLocaleLowerCase()) ||
          descricao.includes(searchText.toLocaleLowerCase())
        );
      });

      setfilteredHistorico(filtered);*/

  /*  setfilteredHistorico(filteredHistorico as any);
    },
    [searchText]
  );*/

  /* const handleReset = useCallback(() => {
    setSearchText("");
    setfilteredHistorico(prospects);
  }, [prospects]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearch(null);
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchText, handleSearch, handleReset]);
*/
  return (
    <>
      <LoadingCustom
        isLoading={loading}
        texto="Buscando historico de contato."
      />

      <hr />
      <div className="container-fluid my-4">
        <div
          className="d-flex flex-fill flex-column"
          style={{ minHeight: "30vh" }}
        >
          {/*<form onSubmit={handleSearch} onReset={() => handleReset()}>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Digite para filtrar"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary d-flex align-items-center"
                >
                  <FiSearch />
                </button>
                {searchText && (
                  <button
                    type="reset"
                    className="btn btn-lg btn-outline-primary d-flex align-items-center"
                  >
                    <FiSlash />
                  </button>
                )}
              </div>
            </div>
          </form>*/}

          <div className="text-left row col col-md-12 m-0 p-0 border">
            <label className="col col-md-2 mt-1 mb-1">
              <strong>Loja:</strong>
            </label>
            <p className="col col-md-10 text-left mt-1 mb-1">{props.loja}</p>

            <label className="col-md-2 mt-1 mb-1">
              <strong>Código:</strong>
            </label>
            <p className="col-md-10 text-left mt-1 mb-1">{props.codigo}</p>

            <label className="col-md-2 mt-1 mb-1">
              <strong>Nome:</strong>
            </label>
            <p className="col-md-10 text-left mt-1 mb-1">{props.nome}</p>
          </div>

          {filteredHistorico.length > 0 ? (
            <div
              className="table-responsive"
              style={{
                height: "40vh",
                maxHeight: "40vh",
              }}
            >
              <table className="table table-striped table-borderless">
                <thead className="thead-dark">
                  <tr>
                    <th
                      style={{ width: "1px", cursor: "pointer" }}
                      onClick={() => sortBy("dataInteracao")}
                    >
                      <div className="d-flex align-items-center text-center">
                        <span>Data</span>
                        {sortParam.current === "dataInteracao" ? (
                          sortOrder.current === "desc" ? (
                            <FiChevronUp />
                          ) : (
                            <FiChevronDown />
                          )
                        ) : (
                          <FaSort />
                        )}
                      </div>
                    </th>
                    <th
                      style={{ width: "1px", cursor: "pointer" }}
                      onClick={() => sortBy("dataProximoContato")}
                    >
                      <div className="d-flex align-items-center text-center">
                        <span>Data Prox.</span>
                        {sortParam.current === "dataProximoContato" ? (
                          sortOrder.current === "desc" ? (
                            <FiChevronUp />
                          ) : (
                            <FiChevronDown />
                          )
                        ) : (
                          <FaSort />
                        )}
                      </div>
                    </th>
                    <th
                      className="text-center"
                      style={{
                        minWidth: "10px",
                        maxWidth: "10px",
                        width: "10px",
                      }}
                    >
                      Inatividade
                    </th>
                    {props.origin === "cliente" && (
                      <>
                        <th className="text-center" style={{ width: "30px" }}>
                          Concorrente
                        </th>
                        <th className="text-center" style={{ width: "30px" }}>
                          Categoria
                        </th>
                        <th className="text-center" style={{ width: "30px" }}>
                          Produto
                        </th>
                      </>
                    )}
                    <th className="text-center" style={{ width: "30px" }}>
                      Observação
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredHistorico.map((historico: any, index) => (
                    <tr key={index}>
                      <td style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                        {new Date(historico.dataInteracao).toLocaleDateString(
                          "pt-BR"
                        )}
                      </td>
                      <td
                        style={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        className="text-center"
                      >
                        {new Date(
                          historico.dataProximoContato
                        ).toLocaleDateString("pt-BR")}
                      </td>
                      <td
                        style={{
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          minWidth: "130px",
                        }}
                        className={historico!.descricaoInatividadeClass}
                      >
                        {historico.descricaoInatividade}
                      </td>
                      {props.origin === "cliente" && (
                        <>
                          <td
                            style={{
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                            }}
                            className={historico.descricaoConcorrenteReduzClass}
                          >
                            {`${historico.codigoConcorrente}-${historico.descricaoConcorrenteReduz}`}
                          </td>
                          <td
                            style={{
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                            }}
                            className={historico.descricaoGrupoProdutoClass}
                          >
                            {historico.descricaoGrupoProduto}
                          </td>

                          <td
                            style={{
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                            }}
                            className={historico.descricaoProdutoClass}
                          >
                            {historico.descricaoProduto}
                          </td>
                        </>
                      )}
                      <td
                        style={{
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                        }}
                        className="text-left"
                      >
                        {historico.observacao}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="alert alert-info">
              {searchText === "" && !loading
                ? "Nenhum contato registrado."
                : ""}
            </div>
          )}
        </div>
      </div>
      <hr />
      {/* {props.orgni === "cliente" ? (
        <ButtonContatoCliente
          props="btn btn-md btn-outline-danger align-items-center col-md-2 col-md-offset-5"
          loja={props.loja}
          cnpj={props.cnpj}
          origin={props.origin}
          nameButton={"Fechar"}
          codigo={props.codigo}
          userCodigo={props.codigo}
        />
      ) : (
        <ButtonContatoProspect
          props="btn btn-md btn-outline-danger align-items-center col-md-2 col-md-offset-5"
          codigo={props.codigo}
          userCodigo={props.codigo}
          userTipo={userTipo}
          origin={props.origin}
          nameButton={"Fechar"}
          cnpj={""}
          grupo={""}
        />
      )} */}
    </>
  );
};

export default ModalHistoricoContato;
