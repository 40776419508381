import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  FormEvent,
} from "react";
import { FaSort } from "react-icons/fa";
import {
  //FiSearch,
  //FiSlash,
  FiChevronUp,
  FiChevronDown,
  FiDownload,
} from "react-icons/fi";
import Swal from "sweetalert2";

import { RegistroContatoProps } from "../../typings";
import {
  fetchAgendaRegistrosDeContatosAnexosPorCodigo,
  fetchAgendaRegistrosDeContatosDownloadAnexosPorCodigo,
} from "../../services/api";
import LoadingCustom from "../LoadingCustom";

const ModalAnexosContato = ({ codigo, tipo, props }: any) => {
  //const qtdPrefixo = "00000000";
  const [loading, setLoading] = useState(true);
  const [arquivos, setArquivos] = useState<any>([]);
  const [filteredHistorico, setfilteredHistorico] = useState<
    RegistroContatoProps[]
  >([]);
  const [searchText, setSearchText] = useState("");
  //const { user, showLoader, hideLoader, setUserCliente } = useAppContext();

  const sortParam = useRef("nome");
  const sortOrder = useRef("asc");

  async function fetchAgendaHistorico() {
    try {
      const response: any = await fetchAgendaRegistrosDeContatosAnexosPorCodigo(
        {
          codigo,
          tipo,
        }
      );

      /*response.map((item: any) => {
        console.log("MAP");
        console.log(item.nome);
      });*/
      if (response.length > 0) {
        setArquivos(response); //response.data.items);
        const field = "dataInteracao";
        const sorted: RegistroContatoProps[] = response.sort(
          (a: any, b: any) => {
            if (sortOrder.current === "asc") {
              return a[field] > b[field] ? 1 : -1;
            } else {
              return a[field] < b[field] ? 1 : -1;
            }
          }
        );
        setfilteredHistorico(sorted);

        /*response.forEach((element) => {
          console.log(">>RESPONSE");
          console.log(element);
        });*/
      }

      //hideLoader(loader);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "Erro",
        text:
          "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });

      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleContatoAnexoDownload(
    codigo: string,
    tipo: string,
    file: string
  ) {
    const response = await fetchAgendaRegistrosDeContatosDownloadAnexosPorCodigo(
      codigo,
      tipo,
      file
    );

    const base64String = response.CONTENT.replace(/[^A-Za-z0-9+/=]/g, "");

    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], {
      type: "application/octet-stream",
    });

    const blobUrl = URL.createObjectURL(blob);

    const downloadLink = document.createElement("a");
    downloadLink.href = blobUrl;
    downloadLink.download = file.toLocaleLowerCase(); // Substitua pelo nome desejado
    document.body.appendChild(downloadLink);

    // Disparar o download
    downloadLink.click();

    // Remover o link do DOM após o download
    document.body.removeChild(downloadLink);
  }

  useEffect(() => {
    if (arquivos.length === 0) {
      fetchAgendaHistorico();
    }
  }, [arquivos, arquivos.length]);

  async function sortBy(field: "dataInteracao" | "dataProximoContato") {
    const items = [...arquivos];
    if (sortParam.current === field) {
      sortOrder.current = sortOrder.current === "asc" ? "desc" : "asc";
    } else {
      sortParam.current = field;
      sortOrder.current = "asc";
    }

    const sorted = items.sort(
      (a: RegistroContatoProps, b: RegistroContatoProps) => {
        if (sortOrder.current === "asc") {
          return new Date(a[field])
            .toLocaleDateString("pt-BR")
            .replaceAll("/", "") >
            new Date(b[field]).toLocaleDateString("pt-BR").replaceAll("/", "")
            ? 1
            : -1;
        } else {
          return new Date(a[field])
            .toLocaleDateString("pt-BR")
            .replaceAll("/", "") <
            new Date(b[field]).toLocaleDateString("pt-BR").replaceAll("/", "")
            ? 1
            : -1;
        }
      }
    );

    //setClientes(sorted);
    setfilteredHistorico(sorted);
  }

  const handleSearch = useCallback(
    async (e: FormEvent | null) => {
      if (e) {
        e.preventDefault();
      }

      setfilteredHistorico(arquivos as any);
    },
    [arquivos, searchText]
  );

  const handleReset = useCallback(() => {
    setSearchText("");
    setfilteredHistorico(arquivos);
  }, [arquivos]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearch(null);
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchText, handleSearch, handleReset]);

  return (
    <>
      <LoadingCustom isLoading={loading} texto="Buscando anexos do contato." />

      <hr />
      <div className="container-fluid my-4">
        <div className="d-flex flex-fill flex-column">
          {/* <form onSubmit={handleSearch} onReset={() => handleReset()}>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Digite para filtrar"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary d-flex align-items-center"
                >
                  <FiSearch />
                </button>
                {searchText && (
                  <button
                    type="reset"
                    className="btn btn-lg btn-outline-primary d-flex align-items-center"
                  >
                    <FiSlash />
                  </button>
                )}
              </div>
            </div>
          </form> */}

          {filteredHistorico.length > 0 ? (
            <div
              className="table-responsive"
              style={{
                height: "40vh",
                maxHeight: "40vh",
              }}
            >
              <table className="table table-striped table-borderless">
                <thead className="thead-dark">
                  <tr>
                    <th
                      style={{ width: "10%", cursor: "pointer" }}
                      onClick={() => sortBy("dataInteracao")}
                    >
                      <div className="d-flex align-items-center text-center">
                        <span className="mr-1">Data</span>
                        {sortParam.current === "dataInteracao" ? (
                          sortOrder.current === "asc" ? (
                            <FiChevronUp />
                          ) : (
                            <FiChevronDown />
                          )
                        ) : (
                          <FaSort />
                        )}
                      </div>
                    </th>
                    <th
                      style={{ width: "80%", cursor: "pointer" }}
                      onClick={() => sortBy("dataInteracao")}
                    >
                      <div className="d-flex align-items-center text-center">
                        <span className="mr-1">Nome</span>
                        {sortParam.current === "dataInteracao" ? (
                          sortOrder.current === "asc" ? (
                            <FiChevronUp />
                          ) : (
                            <FiChevronDown />
                          )
                        ) : (
                          <FaSort />
                        )}
                      </div>
                    </th>
                    <th className="text-center">
                      <span className="text-center">Ação</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {arquivos.map((arquivo: any) => (
                    <tr>
                      <td
                        style={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        className="text-center"
                      >
                        {new Date(arquivo.date).toLocaleDateString("pt-BR")}
                      </td>
                      <td
                        style={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        className="text-left"
                      >
                        {arquivo.nome}
                      </td>
                      <td
                        style={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        className="text-left"
                      >
                        {/* <a
                          // href={`http://localhost:3337/download?codigo=${`${qtdPrefixo.slice(
                          //   String(codigo).length
                          // )}${codigo}`}&tipo=${tipo}&file=${arquivo.nome}`}
                          href={`${ENV.API_URL}/agenda/contatos/download?codigo=${codigo}&tipo=${tipo}&file=${arquivo.nome}`}
                          className="btn btn-sm btn-primary"
                          //onClick={() => handleContatoAnexoDownload()}
                        >
                          TESTE
                        </a> */}
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() =>
                            handleContatoAnexoDownload(
                              codigo,
                              tipo,
                              arquivo.nome
                            )
                          }
                        >
                          <FiDownload className="mr-2" size={18} color="#fff" />
                          Download
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="alert alert-info">
              {searchText === "" ? "Nenhum anexos para contato." : ""}
            </div>
          )}
        </div>
      </div>
      <hr />
      {/* <ButtonContatoCliente
        props="btn btn-md btn-outline-danger align-items-center col-md-2 col-md-offset-5"
        codigo={props.codigo}
        loja={props.loja}
        cnpj={props.cnpj}
        origin={props.origin}
        nameButton={"Fechar"}
        userCodigo={props.codigo}
      /> */}
    </>
  );
};

export default ModalAnexosContato;
