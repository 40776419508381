import React, { useState, FormEvent, useEffect } from 'react';
import { FaEyeSlash, FaEye } from 'react-icons/fa';
import { useLocation, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { confirmPasswordChange } from '../../services/api';
import { useAppContext } from '../../contexts/app';

const FormAlteracaoSenha: React.FC = () => {
  const [state, setState] = useState({
    usuario: '',
    codigo: '',
    novaSenha: '',
    confirmacaoNovaSenha: '',
    showNovaSenha: false,
    showConfirmacaoNovaSenha: false,
    loading: false,
    errorMessage: '',
  });
  const { showLoader, hideLoader } = useAppContext();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const usuario = queryParams.get('usuario') || '';
    const codigo = queryParams.get('codigo') || '';

    setState((values) => ({ ...values, usuario, codigo }));
  }, [location.search]);

  async function handleFormSubmit(e: FormEvent) {
    e.preventDefault();

    if (state.novaSenha !== state.confirmacaoNovaSenha) {
      setState((values) => ({ ...values, errorMessage: 'As senhas digitadas não são iguais.' }));

      setTimeout(() => {
        setState((values) => ({ ...values, errorMessage: '' }));
      }, 5000);

      return;
    }

    const loader = showLoader('Processando solicitação.');

    try {
      setState((values) => ({ ...values, loading: true, errorMessage: '' }));

      await confirmPasswordChange({ id: state.usuario, codigo: state.codigo, novaSenha: state.novaSenha });

      hideLoader(loader);

      Swal.fire({
        title: 'Senha alterada com sucesso!',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'OK',
        onClose: () => {
          history.replace('/login');
        },
      });
    } catch (error) {
      if (error?.response?.data?.message) {
        hideLoader(loader);

        setState((values) => ({ ...values, errorMessage: error.response.data.message }));

        setTimeout(() => {
          setState((values) => ({ ...values, errorMessage: '' }));
        }, 5000);
      }
    } finally {
      setState((values) => ({ ...values, loading: false }));
    }
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <h2 className="text-center text-primary">Alterar Senha</h2>
      <p className="text-center">Digite uma nova senha continuar.</p>

      {state.errorMessage && <div className="alert alert-danger">{state.errorMessage}</div>}

      <fieldset disabled={state.loading}>
        <div className="form-group">
          <label htmlFor="novaSenha">Digite a nova senha</label>
          <div className="position-relative d-flex align-items-center">
            <input
              type={state.showNovaSenha ? 'text' : 'password'}
              id="novaSenha"
              className="form-control form-control-lg"
              value={state.novaSenha}
              onChange={(e) => setState({ ...state, novaSenha: e.target.value })}
            />
            <div className="position-absolute" style={{ right: 16 }}>
              {state.showNovaSenha ? (
                <FaEyeSlash onClick={() => setState((v) => ({ ...v, showNovaSenha: false }))} />
              ) : (
                <FaEye onClick={() => setState((v) => ({ ...v, showNovaSenha: true }))} />
              )}
            </div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="confirmacaoNovaSenha">Digite novamente</label>
          <div className="position-relative d-flex align-items-center">
            <input
              type={state.showConfirmacaoNovaSenha ? 'text' : 'password'}
              id="confirmacaoNovaSenha"
              className="form-control form-control-lg"
              value={state.confirmacaoNovaSenha}
              onChange={(e) => setState({ ...state, confirmacaoNovaSenha: e.target.value })}
            />
            <div className="position-absolute" style={{ right: 16 }}>
              {state.showConfirmacaoNovaSenha ? (
                <FaEyeSlash onClick={() => setState((v) => ({ ...v, showConfirmacaoNovaSenha: false }))} />
              ) : (
                <FaEye onClick={() => setState((v) => ({ ...v, showConfirmacaoNovaSenha: true }))} />
              )}
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-block btn-primary btn-lg mt-5">
          ALTERAR SENHA
        </button>
      </fieldset>
    </form>
  );
};

export default FormAlteracaoSenha;
