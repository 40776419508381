import React, { useState, useEffect, useRef } from 'react';
import { FiSend, FiTrash, FiPlusCircle } from 'react-icons/fi';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { IoIosCalculator } from 'react-icons/io';

import { ItemPedido, Produto } from '../../typings';
import PedidoResumo from '../../components/PedidoResumo';
import ProdutoSearchCampanha from '../../components/ProdutoSearchCampanha';
import {
  updateItems,
  fetchPromocoes,
  fetchCondicoesPagamento,
  fetchTransportadoras,
  fetchHistoricoCompraProduto,
  fetchProdutosParcialCampanha,
} from '../../services/api';
import { useAppContext } from '../../contexts/app';
import ModalSaida from '../../components/ModalSaida';
import ProdutoHistory from '../../components/ProdutoHistory';
import ProdutoDetail from '../../components/ProdutoDetail';
import ItemPedidoDetail from '../../components/ItemPedidoDetail';
import ItemPedidoDesconto from '../../components/ItemPedidoDesconto';

import RowPedido from './RowPedidoResgate';

interface ItemsPlanilha {
  ultimaCondicaoPagamento: string;
  produtos: ItemPedido[];
}

const Pedido: React.FC<{ somenteOfertas?: boolean }> = ({ somenteOfertas = false }) => {
  const { user, userCliente, showLoader, hideLoader, setLoadSaldoMeta } = useAppContext();
  const [condicoesPagamento, setCondicoesPagamento] = useState<{ codigo: string; descricao: string }[]>([]);
  const [condicaoPagamento, setCondicaoPagamento] = useState('001');
  const [items, setItems] = useState<ItemPedido[]>([]);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [dateAtual] = useState(() => Number(userCliente!.meta.currentDate));
  const [dateUltimoPedido] = useState(() => Number(userCliente!.meta!.dtultped));

  const [dateUltimoPedidoMesAnoFormated] = useState(
    String(format(parseISO(userCliente!.meta!.dtultped!), 'dd/MM/yyyy', { locale: ptBR }))
  );

  const [isCalculadoImposto, setIsCalculadoImposto] = useState<boolean>(false);

  const confirmLeave = useRef(false);

  const history = useHistory();

  const { state } = useLocation<{ itemsPlanilha: ItemsPlanilha }>();

  useEffect(() => {
    async function fetchInitialData() {
      try {
        const loader = showLoader(
          'Por favor aguarde. Esta tarefa pode demorar vários segundos. Carregando sugestão de pedido.'
        );

        const promCondicoesPagamento = fetchCondicoesPagamento();

        const cnpj = { cnpj: String(user?.revendedor.cnpj) } || { cnpj: '0' };
        const lista: ItemPedido[] | any = await fetchProdutosParcialCampanha(cnpj); //fetchProdutosCampanha(cnpj);

        const listaProdutosAdd: any = [];

        lista?.data.map((item: any) => {
          listaProdutosAdd.push({
            codigo: item.cod,
            quantidade: 1,
            percentualDesconto: 0,
          });
        });

        const response = await updateItems({
          condicaoPagamento,
          itens: listaProdutosAdd,
          calcularImpostos: 'false',
          calcularEntregas: 'true',
          calcularQuantidadeMes: 'true',
        });

        for (let i of response?.data) {
          i.condpag = '001';
          i.quantidadeComprar = 0;
        }

        let promItems: any = somenteOfertas
          ? fetchPromocoes({
              calcularImpostos: 'false',
              calcularEntregas: 'true',
              calcularQuantidadeMes: 'true',
            })
          : response;

        if (state?.itemsPlanilha) {
          if (
            state?.itemsPlanilha.ultimaCondicaoPagamento &&
            state?.itemsPlanilha.ultimaCondicaoPagamento !== condicaoPagamento
          ) {
            setCondicaoPagamento(state?.itemsPlanilha.ultimaCondicaoPagamento);
          }

          const merged = await transformItems(state.itemsPlanilha.produtos);

          setItems(merged);

          promItems = null;
        }

        const [resCondicoesPagamento, resItems] = await Promise.all([promCondicoesPagamento, promItems]);

        if (resCondicoesPagamento && resCondicoesPagamento.data) {
          setCondicoesPagamento(resCondicoesPagamento.data);

          if (condicaoPagamento === '') {
            setCondicaoPagamento(resCondicoesPagamento.data[0].codigo);
          }
        }

        if (resItems && resItems.data) {
          if (resItems.data.ultimaCondicaoPagamento && resItems.data.ultimaCondicaoPagamento !== condicaoPagamento) {
            setCondicaoPagamento(resItems.data.ultimaCondicaoPagamento);
          }

          const merged = await transformItems(resItems.data); //resItems.items);

          setItems(merged);
        }

        hideLoader(loader);

        if (Number(dateUltimoPedido) !== null && Number(dateUltimoPedido) < Number(dateAtual)) {
          Swal.fire({
            icon: 'info',
            text: `Seu último pedido de compra foi em ${dateUltimoPedidoMesAnoFormated}, para a troca na campanha Compre e Ganhe, é necessário ter um novo pedido no mesmo dia!`,
            allowEscapeKey: false,
            allowOutsideClick: false,
            confirmButtonText: 'OK',
          });
          return false;
        }
      } catch (error) {
        Swal.fire({
          icon: 'warning',
          title: 'Erro',
          text: 'Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.',
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: 'OK',
        });

        console.log(error);
      }
    }

    if (items.length === 0 && condicoesPagamento.length === 0) {
      fetchInitialData();
    }
  }, [
    condicaoPagamento,
    condicoesPagamento.length,
    dateAtual,
    dateUltimoPedido,
    dateUltimoPedidoMesAnoFormated,
    hideLoader,
    items.length,
    showLoader,
    somenteOfertas,
    state,
    transformItems,
    user,
    userCliente,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function transformItems(itens: ItemPedido[]) {
    // -------------------------------------------------------------------------
    // Pedido - Campanha Compre Ganhe - JL_2021-04 AAB1010005
    // -------------------------------------------------------------------------

    const merged = itens.map((item) => {
      let precoItemComDesconto = item.precoPor; //- desconto;
      let precoTotalSemImpostos = precoItemComDesconto * item.quantidadeComprar;
      let precoTotalComImpostos = precoItemComDesconto * item.quantidadeComprar;

      if (item.impostoCalculado && item.quantidadeComprar > 0) {
        precoTotalComImpostos = item.precoPor * item.quantidadeComprar + item.ipi + item.st;
      }

      return {
        ...item,
        precoPorFormatado: precoItemComDesconto.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
        precoTotalSemImpostos,
        precoTotalComImpostos,
      };
    });
    return merged;
  }

  function getQuantidadeItemsPedido(itens: ItemPedido[]) {
    let qtde = 0;

    itens.forEach((item: ItemPedido) => {
      qtde += item.quantidadeComprar;
    });

    return qtde;
  }

  function getValorTotalPedidoSemImpostos(itens: ItemPedido[]) {
    let valor = 0;

    itens.forEach((item: ItemPedido) => {
      valor += item.precoTotalSemImpostos;
    });

    return valor;
  }

  function getValorTotalPedidoComImpostos(itens: ItemPedido[]) {
    let valor = 0;

    itens.forEach((item: ItemPedido) => {
      valor += item.precoTotalComImpostos;
    });

    return valor;
  }

  function getValorTotalDescontos(itens: ItemPedido[]) {
    let valor = 0;

    itens.forEach((item: ItemPedido) => {
      const percentualDesconto = item.percentualDesconto || 0;
      const desconto = (item.precoPor / 100) * percentualDesconto;

      valor += desconto * item.quantidadeComprar;
    });

    return valor;
  }

  function getIpiTotalPedido(itens: ItemPedido[]) {
    let ipiTotal = 0;

    itens.forEach((item: ItemPedido) => {
      if (item.quantidadeComprar > 0) {
        ipiTotal += item.ipi;
      }
    });

    return ipiTotal;
  }

  function getStTotalPedido(itens: ItemPedido[]) {
    let stTotal = 0;

    itens.forEach((item: ItemPedido) => {
      if (item.quantidadeComprar > 0) {
        stTotal += item.st;
      }
    });

    return stTotal;
  }

  function getCanShowImpostosCalculados(itens: ItemPedido[]) {
    return itens.every((item) => item.impostoCalculado === true);
  }

  function getFrete(itens: ItemPedido[]): { tipo: 'CIF' | 'FOB'; valor: number } {
    if (itens.length === 0) {
      return { tipo: 'CIF', valor: 0 };
    }

    const regioes = {
      norte: ['AC', 'AP', 'AM', 'PA', 'RO', 'RR', 'TO'],
      nordeste: ['AL', 'BA', 'CE', 'MA', 'PB', 'PE', 'PI', 'RN', 'SE'],
      centroOeste: ['GO', 'MT', 'MS'],
      sudeste: ['ES', 'MG', 'RJ', 'SP'],
      sul: ['PR', 'SC', 'RS'],
    };

    const valorTotalSemImpostos = getValorTotalPedidoSemImpostos(itens);

    // Se o pedido contiver produtos FOB, retornar FOB
    if (itens.some((item) => item.produto.tipoFrete === 'FOB')) {
      return { tipo: 'FOB', valor: 0 };
    }

    // Se o cliente for FOB, retornar FOB
    if (user?.revendedor?.tipoFrete === 'FOB') {
      return { tipo: 'FOB', valor: 0 };
    }

    // Se Valor Total do Pedido > R$ 2.000,00 (dois mil) retornar CIF
    if (valorTotalSemImpostos > 2000) {
      return { tipo: 'CIF', valor: 0 };
    }

    if (user?.revendedor?.uf) {
      const userUF = user?.revendedor?.uf.toUpperCase();

      // Se o cliente for da Regiões Sul e Sudeste, retornar R$ 50,00
      if (regioes.sul.includes(userUF) || regioes.sudeste.includes(userUF)) {
        return { tipo: 'CIF', valor: 50 };
      }

      // Se o cliente for da Regiões Centro Oeste, Norte ou Nordeste, retornar R$ 80,00
      if (regioes.centroOeste.includes(userUF) || regioes.norte.includes(userUF) || regioes.nordeste.includes(userUF)) {
        return { tipo: 'CIF', valor: 80 };
      }
    }

    return { tipo: 'CIF', valor: 0 };
  }

  function resetImpostoCalculado(itens: ItemPedido[]) {
    return itens.map((item) => {
      return {
        ...item,
        impostoCalculado: false,
      };
    });
  }

  async function handleChangeCondicaoPagamento(value: string) {
    setCondicaoPagamento(value);

    const loader = showLoader(
      'Por favor aguarde. Esta tarefa pode demorar vários segundos. Atualizando item(s) do pedido.'
    );

    try {
      const itens = items.map((item) => ({
        codigo: item.produto.codigo,
        quantidade: item.quantidadeComprar,
        percentualDesconto: item.percentualDesconto || 0,
      }));

      const response = await updateItems({
        condicaoPagamento: value,
        itens,
        calcularImpostos: 'false',
        calcularEntregas: 'false',
        calcularQuantidadeMes: 'false',
      });

      if (response.data) {
        let merged = response.data.map((data: ItemPedido) => {
          const currentItem = items.find((item) => item.produto.codigo === data.produto.codigo);

          if (currentItem) {
            // altera todas as propriedades do itemPedido, menos as referentes a quantidade sugerida e datas de entrega
            return {
              ...data,
              quantidadeSugerida: currentItem.quantidadeSugerida,
              quantidadeMes: currentItem.quantidadeMes,
              entrega1Data: currentItem.entrega1Data,
              entrega2Data: currentItem.entrega2Data,
              entrega1Quantidade: currentItem.entrega1Quantidade,
              entrega2Quantidade: currentItem.entrega2Quantidade,
              percentualDesconto: currentItem.percentualDesconto || 0,
            };
          }

          return data;
        });

        merged = resetImpostoCalculado(merged);

        merged = transformItems(merged);

        setItems(merged);
      }

      hideLoader(loader);
    } catch (error) {
      Swal.fire({
        icon: 'warning',
        title: 'Erro',
        text: 'Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'OK',
      });

      console.log(error);
    }
  }

  async function handleItemChangeQuantidade(itemPedido: ItemPedido, novaQuantidade: number) {
    if (itemPedido) {
      const loader = showLoader(
        'Por favor aguarde. Esta tarefa pode demorar vários segundos. Atualizando item do pedido.'
      );

      try {
        //Validação para quantidade de produtos Add - JL_09-2021
        itemPedido.quantidadeComprar = itemPedido.quantidadeComprar > 0 ? itemPedido.quantidadeComprar : novaQuantidade;

        const response = await updateItems({
          condicaoPagamento,
          itens: [
            {
              codigo: itemPedido.produto.codigo,
              quantidade: itemPedido.quantidadeComprar,
              percentualDesconto: itemPedido.percentualDesconto || 0,
            },
          ],
          calcularImpostos: 'false',
          calcularEntregas: 'true',
          calcularQuantidadeMes: 'false',
        });

        if (response.data) {
          // altera todas as propriedades do itemPedido, menos as referentes a quantidade sugerida
          itemPedido = Object.assign({}, itemPedido, response.data[0], {
            impostoCalculado: false,
            quantidadeComprar: novaQuantidade, //JL_HF_2021-04
            quantidadeSugerida: itemPedido.quantidadeSugerida,
            quantidadeMes: itemPedido.quantidadeMes,
          });

          const _items = resetImpostoCalculado(items);
          const index = items.findIndex((item) => item.produto.codigo === itemPedido.produto.codigo);

          _items[index] = itemPedido;

          const merged = await transformItems(_items);

          setItems(merged);
        }

        hideLoader(loader);
      } catch (error) {
        Swal.fire({
          icon: 'warning',
          title: 'Erro',
          text: 'Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.',
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: 'OK',
        });

        console.log(error);
      }
    }
  }

  function handleShowDetalheProduto(itemPedido: ItemPedido) {
    const ReactSwal = withReactContent(Swal);

    ReactSwal.fire({
      title: 'Detalhe do produto',
      html: <ProdutoDetail produto={itemPedido.produto} />,
      width: '800px',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  async function handleShowHistoricoItem(itemPedido: ItemPedido) {
    try {
      const loader = showLoader('Carregando histórico de compra do produto.');
      const response = await fetchHistoricoCompraProduto(itemPedido.produto.codigo);

      hideLoader(loader);

      if (response.data) {
        const ReactSwal = withReactContent(Swal);

        ReactSwal.fire({
          title: 'Histórico de compra do produto',
          html: <ProdutoHistory entries={response.data} quantidadeSugerida={itemPedido.quantidadeSugerida} />,
          width: '800px',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          showCloseButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'warning',
        title: 'Erro',
        text: 'Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'OK',
      });

      console.log(error);
    }
  }

  function handleShowDetalheItem(itemPedido: ItemPedido) {
    const ReactSwal = withReactContent(Swal);

    itemPedido.condicaoPagamento = `${condicaoPagamento} - ${
      condicoesPagamento.find((cp) => cp.codigo === condicaoPagamento)?.descricao
    }`;

    ReactSwal.fire({
      title: 'Detalhes do item do pedido',
      html: <ItemPedidoDetail itemPedido={itemPedido} />,
      width: '800px',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  async function handleAddDescontoItem(itemPedido: ItemPedido) {
    const ReactSwal = withReactContent(Swal);

    async function handleConfirm({ percentualDesconto }: { percentualDesconto: number }) {
      itemPedido = Object.assign({}, itemPedido, { percentualDesconto });

      const _items = resetImpostoCalculado(items);
      const index = items.findIndex((item) => item.produto.codigo === itemPedido.produto.codigo);

      _items[index] = itemPedido;

      const merged = await transformItems(_items);

      setItems(merged);

      ReactSwal.close();
    }

    ReactSwal.fire({
      title: 'Atenção',
      html: (
        <ItemPedidoDesconto
          itemPedido={itemPedido}
          onConfirm={({ percentualDesconto }) => handleConfirm({ percentualDesconto })}
          onCancel={() => ReactSwal.close()}
        />
      ),
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  async function removeCheckedItems() {
    const response = await Swal.fire({
      title: 'Atenção!',
      text: `Deseja remover ${checkedItems.length > 1 ? 'estes itens' : 'este item'} do pedido?`,
      icon: 'warning',
      showCancelButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    });

    if (response.isConfirmed) {
      const filtered = items.filter((v) => !checkedItems.includes(v.produto.codigo));

      let merged = resetImpostoCalculado(filtered);

      merged = await transformItems(merged);

      setItems(merged);
      setCheckedItems([]);
    }
  }

  async function handleRemoveRow(item: ItemPedido) {
    const response = await Swal.fire({
      title: 'Atenção!',
      text: 'Deseja remover este item do pedido?',
      icon: 'warning',
      showCancelButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    });

    if (response.isConfirmed) {
      const filtered = items.filter((v) => v.produto.codigo !== item.produto.codigo);
      let merged = resetImpostoCalculado(filtered);

      merged = await transformItems(merged);

      setItems(merged);
    }
  }

  function handleShowBuscaProduto() {
    const ReactSwal = withReactContent(Swal);

    async function handleSelect(produto: Produto) {
      ReactSwal.close();

      try {
        const exists = items.find((item) => item.produto.codigo === produto.codigo);

        if (exists) {
          Swal.fire({
            icon: 'warning',
            title: 'Produto duplicado',
            text: 'O produto selecionado já está na lista de pedidos!',
            allowEscapeKey: false,
            allowOutsideClick: false,
            confirmButtonText: 'OK',
          });

          return;
        }

        if (produto.codigo) {
          const loader = showLoader('Adicionando item ao pedido.');

          const response = await updateItems({
            condicaoPagamento,
            itens: [
              {
                codigo: produto.codigo,
                quantidade: 1,
                percentualDesconto: 0,
              },
            ],
            calcularImpostos: 'false',
            calcularEntregas: 'true',
            calcularQuantidadeMes: 'true',
          });

          if (response.data) {
            const itemPedido = response.data[0];
            const precoTotal = itemPedido.precoPor * 1;
            const precoTotalFormatado = precoTotal.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            });

            itemPedido.precoTotal = precoTotal;
            itemPedido.precoTotalFormatado = precoTotalFormatado;

            let merged = resetImpostoCalculado([...items, { ...itemPedido }]);

            merged = await transformItems(merged);

            setItems(merged);
          }

          hideLoader(loader);
        }
      } catch (error) {
        Swal.fire({
          icon: 'warning',
          title: 'Erro',
          text: 'Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.',
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: 'OK',
        });

        console.log(error);
      }
    }

    const cnpj: string = String(user?.revendedor.cnpj || '');

    ReactSwal.fire({
      title: 'Busca de produtos',
      html: <ProdutoSearchCampanha params={{ cnpj }} onSelect={(produto) => handleSelect(produto)} />,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: true,
      width: '960px',
    });
  }

  async function handleCalculateImpostos() {
    try {
      const loader = showLoader(
        'Por favor aguarde. Esta tarefa pode demorar vários segundos. Simulando cálculo dos impostos.'
      );

      const itens = items.map((item) => ({
        codigo: item.produto.codigo,
        quantidade: item.quantidadeComprar,
        percentualDesconto: item.percentualDesconto || 0,
      }));

      const response = await updateItems({
        condicaoPagamento,
        itens,
        calcularImpostos: 'true',
        calcularEntregas: 'false',
        calcularQuantidadeMes: 'false',
      });

      if (response.data) {
        let merged = response.data.map((data: ItemPedido) => {
          const currentItem = items.find((item) => item.produto.codigo === data.produto.codigo);

          if (currentItem) {
            return {
              ...data,
              impostoCalculado: true,
              quantidadeComprar: currentItem.quantidadeComprar, //JL
              quantidadeSugerida: currentItem.quantidadeSugerida,
              quantidadeMes: currentItem.quantidadeMes,
              entrega1Data: currentItem.entrega1Data,
              entrega2Data: currentItem.entrega2Data,
              entrega1Quantidade: currentItem.entrega1Quantidade,
              entrega2Quantidade: currentItem.entrega2Quantidade,
              percentualDesconto: currentItem.percentualDesconto || 0,
            };
          }

          return {
            ...data,
            impostoCalculado: true,
          };
        });

        merged = await transformItems(merged);

        setItems(merged);
        setIsCalculadoImposto(true);
      }

      hideLoader(loader);
    } catch (error) {
      Swal.fire({
        icon: 'warning',
        title: 'Erro',
        text: 'Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'OK',
      });

      console.log(error);
    }
  }

  function CheckTotalResgateSaldo() {
    if (Number(dateUltimoPedido) !== null && Number(dateUltimoPedido) < Number(dateAtual)) {
      Swal.fire({
        icon: 'info',
        text: `Seu último pedido de compra foi em ${dateUltimoPedidoMesAnoFormated}, para a troca na campanha Compre e Ganhe, é necessário ter um novo pedido no mesmo dia!`,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'OK',
      });
      return false;
    }

    if (!isCalculadoImposto) {
      Swal.fire({
        icon: 'info',
        text: 'Calcular Impostos, para resgarar produtos!',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'OK',
      });
      return false;
    }

    const loader = showLoader('Por favor aguarde. Carregando informações para resgatar produto!');

    const totalPagar = getValorTotalPedidoComImpostos(items);

    const { saldo }: any = userCliente!.saldo;

    hideLoader(loader);

    if (totalPagar > Number(saldo)) {
      Swal.fire({
        icon: 'info',
        text: 'Saldo Crédito deve ser maior que total a pagar',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'OK',
      });
      return false;
    }

    return true;
  }

  async function handleConfirmarPedido() {
    const check = CheckTotalResgateSaldo();

    if (!check) {
      return;
    }

    const ReactSwal = withReactContent(Swal);

    const condPag = `${condicaoPagamento} - ${
      condicoesPagamento.find((cp) => cp.codigo === condicaoPagamento)?.descricao
    }`;

    function handleConfirm(response: AxiosResponse) {
      ReactSwal.close();

      const pedidoSaldo =
        String(response.data.pedidoSaldo).trim().length > 0
          ? `<p><b>Pedido Saldo:</b> ${response.data.pedidoSaldo}</p>`
          : '';

      Swal.fire({
        title: 'Pedido enviado com sucesso!',
        html: `<div>
                  <p>Em breve você receberá um e-mail automático de confirmação com os detalhes para seu acompanhamento.</p>
                  <p><b>Pedido:</b> ${response.data.pedidoPai}</p>
                  ${pedidoSaldo}
                  <h3>OBRIGADO!</h3>
                </div>`,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'OK',
        onClose: () => {
          confirmLeave.current = true;
          setLoadSaldoMeta(true); //JL_2021-06-09
          history.push('/');
        },
      });
    }

    function handleCancel() {
      ReactSwal.close();
    }

    try {
      const loader = showLoader(
        'Por favor aguarde. Esta tarefa pode demorar vários segundos. Preparando confirmação do pedido.'
      );

      const itens = items.map((item) => ({
        codigo: item.produto.codigo,
        quantidade: item.quantidadeComprar,
        percentualDesconto: item.percentualDesconto || 0,
      }));

      const promTransportadoras = fetchTransportadoras();
      const promCalculoImpostos = updateItems({
        condicaoPagamento,
        itens,
        calcularImpostos: 'true',
        calcularEntregas: 'true',
        calcularQuantidadeMes: 'false',
      });

      const [resTransportadoras, resCalculoImpostos] = await Promise.all([promTransportadoras, promCalculoImpostos]);

      hideLoader(loader);

      if (resTransportadoras.data && resCalculoImpostos.data) {
        let merged = resCalculoImpostos.data.map((data: ItemPedido) => {
          const currentItem = items.find((item) => item.produto.codigo === data.produto.codigo);

          if (currentItem) {
            return {
              ...data,
              impostoCalculado: true,
              quantidadeComprar: currentItem.quantidadeComprar || 0,
              percentualDesconto: currentItem.percentualDesconto || 0,
              quantidadeSugerida: currentItem.quantidadeSugerida,
              quantidadeMes: currentItem.quantidadeMes,
              entrega1Data: currentItem.entrega1Data,
              entrega2Data: currentItem.entrega2Data,
              entrega1Quantidade: currentItem.entrega1Quantidade,
              entrega2Quantidade: currentItem.entrega2Quantidade,
            };
          }

          return data;
        });

        merged = await transformItems(merged);

        setItems(merged);

        ReactSwal.fire({
          title: 'Confirmação',
          html: (
            <PedidoResumo
              transportadoras={resTransportadoras.data.items}
              quantidadeItens={getQuantidadeItemsPedido(merged)}
              condicaoPagamento={condPag}
              valorTotalSemImpostos={getValorTotalPedidoSemImpostos(merged)}
              valorTotalComImpostos={getValorTotalPedidoComImpostos(merged)}
              valorTotalDescontos={getValorTotalDescontos(merged)}
              ipiTotal={getIpiTotalPedido(merged)}
              stTotal={getStTotalPedido(merged)}
              frete={getFrete(merged)}
              pedidoTipo={'BONIFICACAO'}
              itens={merged}
              user={user}
              onConfirm={(response) => handleConfirm(response)}
              onCancel={() => handleCancel()}
            />
          ),
          width: '600px',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'warning',
        title: 'Erro',
        text: 'Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.',
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'OK',
      });

      console.log(error);
    }
  }

  function showModalSaida(pathname: string) {
    const ReactSwal = withReactContent(Swal);

    if (confirmLeave.current) {
      return true;
    }

    function handleStay() {
      ReactSwal.close();
    }

    function handleLeave() {
      ReactSwal.close();

      confirmLeave.current = true;

      history.push({ pathname });
    }

    ReactSwal.fire({
      title: 'Você já está indo embora?',
      html: <ModalSaida onStay={() => handleStay()} onLeave={() => handleLeave()} />,
      width: '600px',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
    });

    return false;
  }

  return (
    <div className="container-fluid my-4">
      <Prompt message={({ pathname }) => showModalSaida(pathname)} />

      <div className="alert alert-info">
        <div className="row align-items-center">
          <div className="col-md">
            <strong>Condição de pagamentos:</strong>
          </div>
          <div className="col-md">
            <select
              disabled
              className="form-control"
              id="condicaoPagamento"
              value={condicaoPagamento}
              onChange={(e) => handleChangeCondicaoPagamento(e.target.value)}>
              {condicoesPagamento.map(
                (cp) =>
                  cp.codigo === '001' && (
                    <option key={cp.codigo} value={cp.codigo}>
                      {cp.codigo} - {cp.descricao}
                    </option>
                  )
              )}
            </select>
          </div>
        </div>
      </div>

      <div className="alert alert-info">
        <div className="d-block d-md-flex align-items-center justify-content-between">
          <div className="flex-fill">
            <div>
              Os impostos serão calculados na confirmação do pedido ou, caso prefira, clique no botão
              <span className="d-inline d-md-none"> abaixo</span>
              <span className="d-none d-md-inline"> ao lado</span> para fazer uma simulação.
            </div>
            <div>O valor do frete se incorpora à base de cálculo dos impostos.</div>
          </div>

          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary d-md-flex align-items-center mt-4 mt-md-0"
              onClick={() => handleCalculateImpostos()}>
              <IoIosCalculator className="mr-1" size={24} />
              Calcular impostos
            </button>
          </div>
        </div>
      </div>

      {Number(dateUltimoPedido) !== null && Number(dateUltimoPedido) < Number(dateAtual) && (
        <div className="alert alert-warning">
          <div className="d-block d-md-flex align-items-center justify-content-between">
            <div className="flex-fill">
              <div>
                Seu último pedido de compra foi em
                <strong> {dateUltimoPedidoMesAnoFormated} </strong>
                para a troca na campanha
                <strong> Compre e Ganhe,</strong> é necessário ter um novo pedido no mesmo dia.
              </div>
            </div>
          </div>
        </div>
      )}

      {checkedItems.length > 0 && (
        <button
          type="button"
          className="btn btn-danger d-inline-flex align-items-center mb-3"
          onClick={() => removeCheckedItems()}>
          <FiTrash style={{ marginRight: '5px' }} />
          Remover items selecionados
        </button>
      )}

      <div className="d-flex flex-column table-responsive">
        {items.length > 0 ? (
          <>
            {items.map((item) => (
              <RowPedido
                key={`${item.produto.codigo}-${item.quantidadeComprar}`}
                itemPedido={item}
                onCheck={() => setCheckedItems([...checkedItems, item.produto.codigo])}
                onUncheck={() => setCheckedItems(checkedItems.filter((ci) => ci !== item.produto.codigo))}
                onShowDetalheProduto={() => handleShowDetalheProduto(item)}
                onShowHistoricoItem={() => handleShowHistoricoItem(item)}
                onShowDetalheItem={() => handleShowDetalheItem(item)}
                onChangeQuantidade={(novaQuantidade) => handleItemChangeQuantidade(item, novaQuantidade)}
                onChangeDesconto={() => handleAddDescontoItem(item)}
                onRemove={() => handleRemoveRow(item)}
              />
            ))}
          </>
        ) : (
          <div className="alert alert-warning text-center">
            {somenteOfertas ? 'Não há itens disponíveis em promoção.' : 'Não há itens disponíveis para o pedido.'}
          </div>
        )}
      </div>

      {checkedItems.length > 0 && (
        <button
          type="button"
          className="btn btn-danger d-inline-flex align-items-center mt-3"
          onClick={() => removeCheckedItems()}>
          <FiTrash style={{ marginRight: '5px' }} />
          Remover items selecionados
        </button>
      )}

      <div className="d-block d-md-flex flex-fill justify-content-end my-4 offset-md-8">
        <table className="table table-bordered border-dark align-self-end">
          <tbody>
            <tr>
              <td className="p-2 pr-4">Total da mercadoria</td>
              <td className="p-2">
                {getValorTotalPedidoSemImpostos(items).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </td>
            </tr>
            <tr>
              <td className="p-2 pr-4">Total de IPI</td>
              <td className="p-2">
                {getCanShowImpostosCalculados(items)
                  ? getIpiTotalPedido(items).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                  : 'A calcular'}
              </td>
            </tr>
            <tr>
              <td className="p-2 pr-4">Total de ST</td>
              <td className="p-2">
                {getCanShowImpostosCalculados(items)
                  ? getStTotalPedido(items).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                  : 'A calcular'}
              </td>
            </tr>
            <tr>
              <td className="p-2 pr-4">
                <strong>Total a pagar</strong>
              </td>
              <td className="p-2">
                <strong>
                  {getCanShowImpostosCalculados(items) ? (
                    <>
                      {getValorTotalPedidoComImpostos(items).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </>
                  ) : (
                    <>
                      {getValorTotalPedidoSemImpostos(items).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </>
                  )}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="d-block d-md-flex flex-fill">
        <div className="d-block d-md-flex flex-fill mr-md-2 mb-4 mb-md-0">
          <button
            type="button"
            className="d-flex align-items-center justify-content-center btn btn-block btn-lg btn-outline-primary mt-0"
            onClick={() => handleShowBuscaProduto()}>
            <FiPlusCircle className="mr-2" />
            <span>Adicionar produto à lista</span>
          </button>
        </div>

        <div className="d-block d-flex flex-fill ml-md-2">
          <button
            type="button"
            className="d-flex align-items-center justify-content-center btn btn-block btn-lg btn-outline-success mt-0"
            onClick={() => handleConfirmarPedido()}>
            <FiSend className="mr-2" />
            <span>Resgatar produto</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pedido;
