import React, { useState, useRef } from 'react';
import { FiDownload, FiUpload } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import ENV from '../../ENV';
import { uploadPlanilha } from '../../services/api';
import { useAppContext } from '../../contexts/app';

const PedidoUpload = () => {
  const [planilhaDownloadUrl, setPlanilhaDownloadUrl] = useState('produto/planilhaSugeridos');
  const { showLoader, hideLoader } = useAppContext();

  const inputUploadRef = useRef<HTMLInputElement | null>(null);

  const history = useHistory();

  function handleDownloadPlanilha() {
    Swal.fire({
      title: 'Atenção',
      html:
        '<p>Ao preencher a planilha, lembre-se que apenas as colunas <strong>A</strong> e <strong>B</strong> (em verde) precisam ser informadas. As demais são apenas informações para ajudá-lo.</p>',
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: 'OK',
    });
  }

  function openFileDialog() {
    inputUploadRef.current?.click();
  }

  async function handleUploadPlanilha(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files[0]) {
      try {
        const loader = showLoader(
          'Por favor aguarde. Esta tarefa pode demorar vários segundos. Carregando informações da planilha.'
        );

        const response = await uploadPlanilha({
          planilha: e.target.files[0],
          calcularImpostos: 'false',
          calcularEntregas: 'true',
          calcularQuantidadeMes: 'true',
        });

        hideLoader(loader);

        if (inputUploadRef.current) {
          inputUploadRef.current.value = '';
        }

        Swal.fire({
          title: 'Planilha carregada com sucesso!',
          text: 'Você será redirecionado para a tela de pedido.',
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: 'OK',
          onClose: () => {
            /**JL-HF_2021-09-23 | ADD => carregaQtd para carregar qtd no upload planilha excel*/
            history.push('/pedido/planilha', { itemsPlanilha: response.data, carregaQtd: true });
          },
        });
      } catch (error) {
        Swal.fire({
          icon: 'warning',
          title: 'Erro',
          text: 'Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.',
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: 'OK',
        });

        console.log(error);
      }
    }
  }

  return (
    <div className="d-flex flex-fill align-items-center">
      <div className="col-8 offset-2 border border-blue p-4">
        <h3 className="text-center text-primary mb-3">Pedido por planilha (Excel)</h3>

        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header bg-green">
                <strong className="text-primary">Primeiro passo</strong>
              </div>
              <div className="card-body">
                <div className="form-check">
                  <label className="form-check-label d-flex align-items-center">
                    <input
                      type="radio"
                      className="form-check-input mt-0"
                      name="planilhaDownloadUrl"
                      value="produto/planilhaSugeridos"
                      checked={planilhaDownloadUrl === 'produto/planilhaSugeridos'}
                      onChange={(e) => setPlanilhaDownloadUrl(e.target.value)}
                    />
                    Incluir sugestão de compra (MPP)
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label d-flex align-items-center">
                    <input
                      type="radio"
                      className="form-check-input mt-0"
                      name="planilhaDownloadUrl"
                      value="produto/planilhaPromocoes"
                      checked={planilhaDownloadUrl === 'produto/planilhaPromocoes'}
                      onChange={(e) => setPlanilhaDownloadUrl(e.target.value)}
                    />
                    Incluir somente ofertas
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label d-flex align-items-center">
                    <input
                      type="radio"
                      className="form-check-input mt-0"
                      name="planilhaDownloadUrl"
                      value="produto/planilha"
                      checked={planilhaDownloadUrl === 'produto/planilha'}
                      onChange={(e) => setPlanilhaDownloadUrl(e.target.value)}
                    />
                    Planilha em branco
                  </label>
                </div>

                <a
                  href={`${ENV.API_URL}/${planilhaDownloadUrl}?calcularImpostos=false`}
                  className="btn btn-block btn-lg btn-outline-primary d-flex align-items-center justify-content-center mt-3"
                  onClick={() => handleDownloadPlanilha()}>
                  <FiDownload size={24} className="mr-3" />
                  Baixar planilha modelo
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-header bg-green">
                <strong className="text-primary">Segundo passo</strong>
              </div>
              <div className="card-body">
                <p>
                  Dependendo do número de itens na planilha, essa tarefa pode demorar alguns minutos. Por favor,
                  aguarde.
                </p>
                <br/>
                <input
                  type="file"
                  className="d-none"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  ref={inputUploadRef}
                  onChange={(e) => handleUploadPlanilha(e)}
                />

                <button
                  type="button"
                  className="btn btn-block btn-lg btn-outline-primary d-flex align-items-center justify-content-center"
                  onClick={() => openFileDialog()}>
                  <FiUpload size={24} className="mr-3" />
                  Enviar planilha preenchida
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PedidoUpload;
