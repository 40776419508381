import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import Swal from "sweetalert2";
import { AxiosError, AxiosResponse } from "axios";

import { User, UserCliente } from "../typings";
import api, {
  login as apiLogin,
  logout as apiLogout,
  fetchLoggedUser,
  fetchSaldoMetaCliente,
} from "../services/api";
import { IdTipoUsuario } from "../entities/TipoUsuario";

type AppContextData = {
  logged: boolean;
  user: User | null;
  userCliente: UserCliente | null;
  loading: boolean;
  showLoader(message: string): number;
  hideLoader(id: number): void;
  login({
    username,
    password,
    usertipo,
  }: {
    username: string;
    password: string;
    usertipo: number;
  }): Promise<AxiosResponse>; //JL-HF_2021-06-29
  logout({ ask }: { ask: boolean }): Promise<void>;
  setUser(user: User): void;
  setUserCliente(userCliente: UserCliente | null): void;
  //userClienteMeta: (cnpj: string) => void;
  setLoadSaldoMeta(load: boolean): void;
  setIsRepresentante(user: User): User;
};

const AppContext = createContext<AppContextData>({} as AppContextData);

export const AppProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [userCliente, setUserCliente] = useState<UserCliente | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadSaldoMeta, setLoadSaldoMeta] = useState(false);
  const loaderRef = useRef<number>();
  const isCampanhaActive = false; //JL-HF_2022-06

  function showLoader(message: string) {
    loaderRef.current = Date.now();

    Swal.fire({
      text: message,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onBeforeOpen: () => Swal.showLoading(),
    });

    return loaderRef.current;
  }

  function hideLoader(id: number) {
    if (loaderRef.current === id) {
      Swal.close();
    }
  }

  /**Verifica se usuario é representante | JL-HF_ 2023-06 */
  function setIsRepresentante(user: User) {
    user.isRepresentante =
      IdTipoUsuario.REPRESENTANTE === user?.tipo.id ||
      IdTipoUsuario.AGENTE === user?.tipo.id;

    return user;
  }

  async function login({
    username,
    password,
    usertipo,
  }: {
    username: string;
    password: string;
    usertipo: number;
  }) {
    try {
      username = `${username}|${usertipo}`; //JL-HF_2022-06

      const response = await apiLogin({ username, password, usertipo });

      if (response.data) {
        if (
          IdTipoUsuario[usertipo] !==
          String(response.data.tipo.nome).trim().toUpperCase()
        ) {
          /**JL-HF_ 2021-06-29 */
          await Swal.fire({
            icon: "warning",
            title: "Erro",
            text: "Tipo de acesso inválido, selecione corretamente",
            allowEscapeKey: false,
            allowOutsideClick: false,
            confirmButtonText: "OK",
          });

          const res = await apiLogout();

          return res;
        }
        /**END JL-HF_ 2021-06-29 */

        setUser(setIsRepresentante(response.data));
      }

      return response;
    } catch (error) {
      throw error;
    }
  }

  const logout = useCallback(async ({ ask = true }) => {
    async function doLogout() {
      setLoading(true);

      await apiLogout();

      setUser(null);
      setUserCliente(null);
      setLoading(false);
    }

    try {
      if (ask) {
        const response = await Swal.fire({
          title: "Sair",
          text: "Deseja sair do sistema?",
          showCancelButton: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (response.isConfirmed) {
          await doLogout();
        }
      } else {
        await doLogout();
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  /**
   * Caso a sessão do usuário expire, faz o logout do sistema.
   */
  useEffect(() => {
    api.interceptors.response.use(undefined, (error: AxiosError) => {
      if (
        error?.response?.status === 401 &&
        error.response?.config.url !== "/auth"
      ) {
        Swal.fire({
          title: "Acesso inválido",
          text: "Sua sessão expirou. Favor fazer login novamente.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
          onClose: () => {
            logout({ ask: false });
          },
        });
      }

      return Promise.reject(error);
    });
  }, [logout]);

  /**
   * Carregar Saldo e Meta Cliente - JL_2021-06
   */
  useEffect(() => {
    let isMounted = true;

    async function hanlerErro() {
      const response = await Swal.fire({
        title: "Info",
        text:
          "Falha ao carregar os seus dados de meta mês e saldo créditos. Por favor, entre em contato com equipe Ferrari!",
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });

      if (response.isConfirmed) {
        await apiLogout();

        if (isMounted) {
          logout({ ask: false });
        }
      }
    }

    async function userClienteMeta() {
      if (user?.revendedor !== undefined && isMounted) {
        setLoading(true);

        const loader = showLoader("Por favor aguarde... Carregando os dados.");

        await fetchSaldoMetaCliente(
          String(user?.revendedor.cnpj),
          String(user?.revendedor.codigo)
        )
          .then((resp) => {
            if (resp.data && isMounted) {
              //setUserCliente(resp.data);
              setUser(setIsRepresentante(resp.data)); //JL-HF_2023-06
            }
          })
          .catch(async (err) => {
            await hanlerErro();
          })
          .finally(() => {
            hideLoader(loader);
            setLoading(false);
            setLoadSaldoMeta(false);
          });
      }
    }

    isCampanhaActive && userClienteMeta();

    return () => {
      isMounted = false;
    };
  }, [user, loadSaldoMeta, logout, isCampanhaActive]);
  /**
   * END Carregar Saldo e Meta Cliente - JL_2021-06
   */

  /**
   * Verifica se há usuário autenticado.
   */
  useEffect(() => {
    setLoading(true);

    const loader = showLoader("Iniciando aplicação.");

    fetchLoggedUser()
      .then(async (response) => {
        if (response.data) {
          //setUser(response.data);
          setUser(setIsRepresentante(response.data)); //JL-HF_2023-06
        }
      })
      .catch(() => setUser(null))
      .finally(() => {
        hideLoader(loader);
        setLoading(false);
      });
  }, []);

  return (
    <AppContext.Provider
      value={{
        logged: Boolean(user),
        user,
        loading,
        showLoader,
        hideLoader,
        login,
        logout,
        setUser,
        userCliente,
        setUserCliente,
        setIsRepresentante,
        //userClienteMeta,
        setLoadSaldoMeta,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

export default AppContext;
