//JL-HF_2023-06
import React, { useEffect, useState } from "react";
// import { FaExpandArrowsAlt, FaSort } from "react-icons/fa";
// import { FiMail } from "react-icons/fi";

import SideBar from "../SideBar";

import { ColetaProps } from "../../../typings";

import Swal from "sweetalert2";
import { useAppContext } from "../../../contexts/app";
import { fetchControleColetaPorCodigoCliente } from "../../../services/api";

const ConroleColetas = () => {
  const [items, setItems] = useState<ColetaProps[]>([]);
  const { showLoader, hideLoader, user } = useAppContext();
  const [headTableColeta] = useState([
    "Nota/Serie",
    "Cliente/Loja",
    "Nome Cliente",
    "UF",
    "Transp.",
    "Nome Transportadora",
    "Telefone",
    "Dt.Faturamento",
    "Qtd.",
    "Número Coleta",
    "Dt.Sol.Coleta",
    "Dt.Prev.Coleta",
    "Dt.Coleta",
    "Hr.Coleta Chega",
    "Hr.Coleta Saída",
    "Contato",
  ]);

  useEffect(() => {
    async function fetchItems() {
      try {
        const loader = showLoader("Por favor aguarde. Carregando pedidos.");
        const response = await fetchControleColetaPorCodigoCliente({
          codigo: user?.revendedor.codigo,
          loja: user?.revendedor.loja,
        });

        if (response.data) {
          const _coletas = [...response.data];
          const filtered = _coletas.filter(
            (item: ColetaProps) => _coletas.length > 0
          );
          const sorted = filtered.sort((a, b) => {
            return new Date(a.coletaData) < new Date(b.coletaData) ? 1 : -1;
          });

          setItems(sorted);
        }

        hideLoader(loader);
      } catch (error) {
        Swal.fire({
          icon: "warning",
          title: "Erro",
          text:
            "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonText: "OK",
        });

        console.log(error);
      }
    }

    if (items.length === 0) {
      fetchItems();
    }
  }, [user, hideLoader, showLoader, items.length]);

  return (
    <div className="container-fluid my-4">
      <div className="row">
        <div className="col-md-3">
          <SideBar />
        </div>
        <div className="col-md-9 mt-4 mt-md-0">
          <h3>Controle de Coletas FOB</h3>

          <hr />

          {items.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-striped table-borderless">
                <thead className="thead-dark">
                  {/* <tr>
                    <th
                      style={{
                        maxWidth: "3%",
                        width: "3%",
                        textAlign: "center",
                      }}
                    >
                      Nota/Serie
                    </th>
                    <th
                      style={{
                        maxWidth: "9%",
                        width: "9%",
                        textAlign: "center",
                      }}
                    >
                      Cliente/Loja
                    </th>
                    <th
                      style={{
                        maxWidth: "13%",
                        width: "13%",
                        textAlign: "center",
                      }}
                    >
                      Nome Cliente
                    </th>
                    <th
                      style={{
                        maxWidth: "9%",
                        width: "9%",
                        textAlign: "center",
                      }}
                    >
                      UF
                    </th>
                    <th
                      style={{
                        maxWidth: "9%",
                        width: "9%",
                        textAlign: "center",
                      }}
                    >
                      Transportadora
                    </th>
                    <th
                      style={{
                        maxWidth: "35%",
                        width: "35%",
                        textAlign: "center",
                      }}
                    >
                      Dt.Faturamento
                    </th>
                    <th
                      style={{
                        maxWidth: "3%",
                        width: "3%",
                        textAlign: "center",
                      }}
                    >
                      Qtd
                    </th>
                    <th
                      style={{
                        maxWidth: "15%",
                        width: "15%",
                        textAlign: "center",
                      }}
                    >
                      Coleta
                    </th>
                    <th
                      style={{
                        maxWidth: "5%",
                        width: "5%",
                        textAlign: "center",
                      }}
                      title="CNPJ da Transportadora"
                    >
                      Contato
                    </th>
                    <th
                      style={{
                        maxWidth: "5%",
                        width: "5%",
                        textAlign: "center",
                      }}
                    ></th>
                  </tr> */}

                  <tr>
                    {headTableColeta.map((head: any) => (
                      <th key={head}>{head}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {items.map((coleta: ColetaProps) => (
                    <tr
                      key={coleta.numeroDocumento}
                      style={{
                        display:
                          Number(items.length) === 0 ? "none" : "table-row",
                        maxWidth: "1px",
                        width: "1px",
                      }}
                    >
                      <td
                        className="text-uppercase"
                        style={{
                          maxWidth: "2.1px",
                          width: "2.1px",
                          textAlign: "center",
                        }}
                      >
                        {coleta.numeroDocumento}-{coleta.serieDocumento}
                      </td>
                      <td
                        className="text-uppercase"
                        style={{
                          maxWidth: "2.4px",
                          width: "2.4px",
                          textAlign: "center",
                        }}
                      >
                        {coleta.clienteCodigo}-{coleta.clienteLoja}
                      </td>
                      <td
                        className="text-uppercase"
                        style={{
                          maxWidth: "4px",
                          width: "4px",
                          textAlign: "center",
                          wordWrap: "break-word",
                        }}
                      >
                        {coleta.clienteNomeFantasiaAbreviado}
                      </td>
                      <td
                        className="text-uppercase"
                        style={{
                          maxWidth: "2px",
                          width: "2px",
                          textAlign: "center",
                        }}
                      >
                        {coleta.ufDestino}
                      </td>
                      <td
                        className="text-uppercase"
                        style={{ maxWidth: "25%", width: "25%" }}
                      >
                        {coleta.transportadoraCodigo}
                      </td>
                      <td
                        className="text-uppercase"
                        style={{
                          maxWidth: "3px",
                          width: "3px",
                          textAlign: "left",
                        }}
                      >
                        {coleta.transportadoraNome}
                      </td>
                      <td
                        className="text-uppercase"
                        style={{
                          maxWidth: "2px",
                          width: "2px",
                          textAlign: "center",
                        }}
                      >
                        {`(${coleta.transportadoraDDD})`}
                        {coleta.transportadoraTelefone}
                      </td>
                      <td
                        className="text-uppercase"
                        style={{
                          maxWidth: "5.5px",
                          width: "5.5px",
                          textAlign: "center",
                        }}
                      >
                        {new Date(
                          coleta.notaDataAutorizacao
                        ).toLocaleDateString("pt-BR")}
                      </td>
                      <td
                        className="text-uppercase"
                        style={{
                          maxWidth: "2px",
                          width: "2px",
                          textAlign: "center",
                        }}
                      >
                        {coleta.volume}
                      </td>
                      <td>{coleta.coletaNumero}</td>
                      <td>
                        {new Date(coleta.coletaData).toLocaleDateString(
                          "pt-BR"
                        )}
                      </td>
                      <td>
                        {new Date(
                          coleta.coletaDataSolitacao
                        ).toLocaleDateString("pt-BR")}
                        {" 00:00"}
                        {/*coleta.coletaHoraSolitacao*/}
                      </td>
                      <td>
                        {new Date(coleta.coletaDataPrevisao).toLocaleDateString(
                          "pt-BR"
                        )}
                        {" 00:00 "}
                        {/*coleta.coletaHoraPrevisao*/}
                      </td>
                      <td className="text-center">{coleta.coletaHoraChega}</td>
                      <td className="text-center">{coleta.coletaHoraSaida}</td>

                      <td>{coleta.contatoNome}</td>

                      {/*<td
                        className="text-uppercase"
                        style={{ maxWidth: "1px", width: "1px" }}
                      >
                        {items.length > 0 && (
                          <div
                            style={{
                              display: "flex",
                              margin: 0,
                              padding: 0,
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "15px",
                            }}
                          >
                            <button
                              type="button"
                              title="Exibir detalhes do coleta"
                              className="btn btn-sm btn-outline-primary d-flex align-items-center"
                              onClick={() => {}}
                            >
                              <FaExpandArrowsAlt />
                            </button>

                            <button
                              type="button"
                              title={`Solicitar o envio de coleta ${
                                parseInt(coleta.clienteCodigo) >= 0
                                  ? "e nota fiscal"
                                  : ""
                              } por email`}
                              className="btn btn-sm btn-outline-primary d-flex align-items-center"
                              onClick={() => {}}
                            >
                              <FiMail />
                            </button>
                          </div>
                        )
                      </td>*/}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="alert alert-info">
              Nenhum cadastrado no controle de coleta registrado.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConroleColetas;
