import React from "react";
import { NavLink } from "react-router-dom";
import {
  FaShoppingCart,
  FaMoneyBillAlt,
  FaRegIdCard,
  FaRegBuilding,
  FaHome,
  FaBuffer,
  FaBoxes,
} from "react-icons/fa";
import { FiLock } from "react-icons/fi";

const SideBar = () => {
  return (
    <>
      <NavLink
        exact
        to="/minhaconta/pedidos"
        className="btn btn-block btn-lg btn-outline-primary d-flex align-items-center"
      >
        <FaShoppingCart className="mr-4" />
        Meus pedidos
      </NavLink>
      <NavLink //JL-HF_2022-06
        exact
        to="/minhaconta/orcamentos"
        className="btn btn-block btn-lg btn-outline-primary d-flex align-items-center"
      >
        <FaBuffer className="mr-4" />
        Meus orçamentos
      </NavLink>
      <NavLink
        exact
        to="/minhaconta/faturas"
        className="btn btn-block btn-lg btn-outline-primary d-flex align-items-center"
      >
        <FaMoneyBillAlt className="mr-4" />
        Faturas em aberto
      </NavLink>
      <NavLink
        exact
        to="/minhaconta/controlecoletas"
        className="btn btn-block btn-lg btn-outline-primary d-flex align-items-center"
      >
        <FaBoxes className="mr-4" />
        Controle de Coletas
      </NavLink>
      <NavLink
        exact
        to="/minhaconta/dadoscadastrais"
        className="btn btn-block btn-lg btn-outline-primary d-flex align-items-center"
      >
        <FaRegIdCard className="mr-4" />
        Meu cadastro
      </NavLink>
      <NavLink
        exact
        to="/minhaconta/enderecos"
        className="btn btn-block btn-lg btn-outline-primary d-flex align-items-center"
      >
        <FaRegBuilding className="mr-4" />
        Meus endereços
      </NavLink>
      <NavLink
        exact
        to="/minhaconta/alterarsenha"
        className="btn btn-block btn-lg btn-outline-primary d-flex align-items-center"
      >
        <FiLock className="mr-4" />
        Alterar senha
      </NavLink>
      <NavLink
        exact
        to="/"
        className="btn btn-block btn-lg btn-outline-primary d-flex align-items-center"
      >
        <FaHome className="mr-4" />
        Retornar ao início
      </NavLink>
    </>
  );
};

export default SideBar;
