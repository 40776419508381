import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Input from "../Input";
import { FiSearch } from "react-icons/fi";
import {
  fetchDisponibilidadeProduto,
  fetchProdutosPesquisa,
} from "../../services/api";
import { Produto } from "../../typings";
//import LoadingCustom from "../LoadingCustom";
//import ENV from "../../ENV";

interface ProdutoProps {
  codigo: string;
  nome: string;
}

interface ProdutoSearchState {
  searchText: string;
  grupo: string;
  produtos: Produto[];
  loading: boolean;
  message: string;
}

const ModalDisponibilidadeProduto = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  //const [produtos, setProdutos] = useState<ProdutoProps[]>([]);
  const [getProduto, setGetProduto] = useState<Produto>({} as Produto);
  const [urlFichaTecnica, setFichaTecnica] = useState("#");
  const [produto, setProduto] = useState<Produto>({
    titulo: "-----",
    codigo: "-----",
    modelo: "-----",
  } as Produto);
  const [qtd, setQtd] = useState(0);
  const [getQtd, setGetQtd] = useState("00");
  const [dateDisponibilidade, setDateDisponibilidade] = useState(
    "-- / -- / ----"
  );
  const [isLoading, setIsLoading] = useState(true);

  const [state, setState] = useState<ProdutoSearchState>({
    searchText: "",
    grupo: "",
    produtos: [],
    loading: false,
    message: "",
  });

  function LimparResult() {
    handleReset();
    setDateDisponibilidade("-- / -- / ----");
    setProduto({
      titulo: "-----",
      codigo: "-----",
      modelo: "-----",
    } as Produto);
    setFichaTecnica("#");
    setIsLoading(false);
    setGetQtd("00");
    setQtd(0);
  }

  function handleSelect(produto: Produto) {
    handleReset();
    setState({
      searchText: produto.codigo,
      grupo: "",
      produtos: [],
      loading: false,
      message: "",
    });

    setGetProduto(produto);
  }

  function messageResult() {
    setState((v) => ({
      ...v,
      message: `O termo <strong>${state.searchText}</strong> não retornou nenhum resultado.`,
    }));

    setTimeout(() => {
      setState((v) => ({ ...v, message: "" }));
    }, 3000);
  }

  async function handleFormSubmit(e: FormEvent) {
    e.preventDefault();

    const getQtd = String(qtd);
    console.log(getProduto?.codigo);
    if (getQtd.length === 0 || !getProduto.codigo) {
      setMessage("Os campos Quantidade e Código produto são obrigatórios!");

      setTimeout(() => {
        setMessage("");
      }, 6000);
      return;
    }

    LimparResult();

    const response = await fetchDisponibilidadeProduto({
      codigo: getProduto.codigo,
      quantidade: getQtd,
    });

    if (response?.date) {
      setTimeout(() => {
        setDateDisponibilidade(
          new Date(response?.date).toLocaleDateString("pt-BR")
        );
        setGetQtd(getQtd);
        setProduto(getProduto);
        setFichaTecnica(
          `https://b2b.ferrarinet.com.br/assets/${getProduto.fichaTecnica}`
        );
        //setFichaTecnica(`${ENV.API_URL}/assets/${getProduto.fichaTecnica}`);

        setIsLoading(true);
      }, 1200);
      return;
    }

    messageResult();

    setIsLoading(true);
  }

  const handleSearch = useCallback(
    async (e: FormEvent | null) => {
      if (e) {
        e.preventDefault();
      }

      try {
        setState((v) => ({ ...v, loading: true }));

        const response = await fetchProdutosPesquisa({
          searchText: state.searchText,
          grupo: state.grupo,
        });

        const items = response.data.items;
        let produtos = response.data.items;

        if (items.length === 0) {
          messageResult();
        }

        if (state.grupo!.length > 0 && items.length > 0) {
          produtos = items.filter(
            (item: any) => item?.grupo?.codigo === state.grupo
          );
        }

        setState((v) => ({ ...v, produtos })); //:  response.data.items }));
      } catch (error) {
        console.log(error);
        messageResult();
      } finally {
        setState((v) => ({ ...v, loading: false }));
      }
    },
    [state.grupo, state.searchText]
  );

  function handleReset() {
    setState({
      searchText: "",
      grupo: "",
      produtos: [],
      loading: false,
      message: "",
    });

    inputRef.current?.focus();
  }

  /*useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 600);
  });*/

  useEffect(() => {
    const timer = setTimeout(() => {
      if (state.searchText.length >= 3) {
        handleSearch(null);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [state.searchText, handleSearch, state.searchText.length]);

  const [message, setMessage] = useState("");

  return (
    <>
      <hr />
      {message.length > 0 && (
        <div className="alert alert-info">
          <p>{message}</p>
        </div>
      )}
      <div className="row col-md-12">
        <form
          onSubmit={handleFormSubmit}
          onReset={() => handleReset()}
          style={{
            fontSize: ".9rem",
          }}
          className="row pl-3 pt-4"
        >
          <div className="col-md-12 row">
            <label className="col-md-3 text-left pt-3">
              <b>Quantidade:</b>
            </label>
            <Input
              name="qtd"
              type="number"
              min={0}
              max={10000}
              value={qtd}
              className="col-md-6 form-control form-control-lg"
              placeholder="0"
              onChange={(e) => setQtd(e.target.value as any)}
            />
          </div>

          <label className="col-md-3 text-left pt-3">
            <b>Código produto:</b>
          </label>

          <div className="flex-grow-1 mr-1">
            <div className="input-group mb-3">
              <input
                type="text"
                ref={inputRef}
                autoFocus
                className="form-control form-control-lg"
                placeholder="Digite o código ou nome do produto"
                value={state.searchText}
                onChange={(e) =>
                  setState({ ...state, searchText: e.target.value })
                }
              />
              <div className="input-group-append">
                <button
                  style={{
                    cursor: qtd < 1 ? "not-allowed" : "pointer",
                  }}
                  type="submit"
                  disabled={
                    qtd < 1 ||
                    state.searchText.length < 4 ||
                    state.message.length > 1
                  }
                  className="btn btn-lg btn-primary"
                  title={
                    qtd < 1
                      ? "Digite a quantidade desejada do produto, para realizar consulta"
                      : "Consultar disponibilidade do produto"
                  }
                >
                  <FiSearch />
                </button>
              </div>
            </div>
            {state.message && (
              <div
                style={{
                  backgroundColor: "#fff",
                  position: "absolute",
                  maxWidth: "23vw",
                  width: "23vw",
                  zIndex: 1000,
                }}
                className="alert alert-warning text-center"
                dangerouslySetInnerHTML={{ __html: state.message }}
              ></div>
            )}
            <div
              style={{
                backgroundColor: "#fff",
                position: "absolute",
                zIndex: 1000,
                maxHeight: "25vh",
                overflow: "scroll",
                maxWidth: "23vw",
              }}
            >
              {state.produtos.map((produto: any) => (
                <button
                  type="button"
                  key={produto.codigo}
                  className="btn btn-light btn-block d-flex flex-row align-items-center justify-content-between mb-3"
                  style={{
                    maxHeight: "23vh",
                  }}
                  onClick={() => handleSelect(produto)}
                >
                  <div className="d-flex align-items-center pl-1">
                    <img
                      //src={`${ENV.API_URL}/assets/${produto.codigo}.jpg`}
                      src={`https://b2b.ferrarinet.com.br/assets/${produto.codigo}.jpg`}
                      width="80"
                      alt=""
                    />
                  </div>
                  <div className="flex-grow-1 p-2 text-left">
                    <h6 className="card-title mb-1 text-primary">
                      {produto.titulo}
                    </h6>
                    <div>
                      <small className="text-muted">
                        Modelo: {produto.modelo}
                      </small>
                    </div>
                    <div>
                      <small className="text-muted">
                        SKU: {produto.codigo}
                      </small>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </form>
      </div>

      <div
        className="col-md-12 text-left p-4"
        style={{
          border: "2px solid #eee",
          borderRadius: "4px",
          minHeight: "29vh",
          zIndex: 10,
        }}
      >
        {isLoading ? (
          <>
            <p>
              <b>Quantidade: </b>
              {getQtd}
            </p>
            <p>
              <b>Data disponibilidade: </b>
              {dateDisponibilidade}
            </p>
            <p>
              <b>Produto: </b>
            </p>
            <div className="d-flex flex-row align-items-center justify-content-between mb-3">
              <div className="d-flex align-items-center pl-1">
                <img
                  //src={`${ENV.API_URL}/assets/${produto.codigo}.jpg`}
                  src={`https://b2b.ferrarinet.com.br/assets/${produto.codigo}.jpg`}
                  width="80"
                  alt=""
                />
              </div>
              <div className="flex-grow-1 p-2 text-left">
                <small className="text-muted">Titulo: {produto.titulo}</small>
                <div>
                  <small className="text-muted">Modelo: {produto.modelo}</small>
                </div>
                <div>
                  <small className="text-muted">SKU: {produto.codigo}</small>
                </div>
              </div>
            </div>

            {produto.titulo !== "-----" ? (
              <a
                href={urlFichaTecnica}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ficha Técnica
              </a>
            ) : (
              <span
                className="text-multed"
                style={{
                  cursor: "not-allowed",
                }}
              >
                Ficha técnica
              </span>
            )}
          </>
        ) : (
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(255, 255, 255, 0.75)",
              zIndex: 1000,
              minHeight: "19vh",
              height: "19vh",
            }}
          >
            <div className="spinner-border text-info mt-5"></div>
            <div className="text-primary mt-4">
              Consultando a disponibilidade data entrega do produto.
            </div>
          </div>
        )}
      </div>

      <br />

      <hr />
    </>
  );
};

export default ModalDisponibilidadeProduto;
