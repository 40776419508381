import React from "react";
import ButtonContato from "../../../components/ButtonContato";
import {
  fetchAgendaRegistrosDeContatosPorCodigoCliente,
  sendAgendaRegistroContatoCliente,
} from "../../../services/api";
import { TipoUsuario } from "../../../entities/TipoUsuario";
import { Revendedor } from "../../../typings";

const ButtonContatoCliente: React.FC<{
  props: any;
  origin: string;
  nameButton: string;
  loja: string;
  cnpj: string;
  nome: string;
  codigo: string;
  userTipo: string;
  userCodigo: string;
  setClientes?: React.Dispatch<React.SetStateAction<Revendedor[]>>;
  setIsShowDropdownOpcoes?: React.Dispatch<React.SetStateAction<number>> | null;
}> = ({
  setIsShowDropdownOpcoes,
  setClientes,
  userTipo,
  userCodigo,
  nameButton,
  origin,
  codigo,
  props,
  nome,
  loja,
  cnpj,
}: any) => {
  async function handleFormSubmitProps({ data, files }: any) {
    await sendAgendaRegistroContatoCliente({
      data: {
        ...data,
        loja,
        codigo,
        codigoRepresentante: `${
          TipoUsuario[userTipo.toLowerCase()].perfil
        }${userCodigo}`,
      },
      files,
    });
    setClientes([]);
  }

  async function fetchAgendaRegistroContatosProps() {
    return await fetchAgendaRegistrosDeContatosPorCodigoCliente({
      codigo,
      loja,
    });
  }

  return (
    <ButtonContato
      grupo={null}
      cnpj={cnpj}
      nome={nome}
      loja={loja}
      props={props}
      codigo={codigo}
      origin={origin}
      nameButton={nameButton}
      action={fetchAgendaRegistroContatosProps}
      setIsShowDropdownOpcoes={setIsShowDropdownOpcoes}
      handleCreateRegistroContato={handleFormSubmitProps}
      //fetchAgendaRegistroContatosProps={fetchAgendaRegistroContatosProps}
    />
  );
};

export default ButtonContatoCliente;
